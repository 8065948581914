import axios, { AxiosResponse } from 'axios'
import * as routes from './routes'
import { JsonInvoice, JsonSupplierCollectionsContact } from '../models'
import { Report } from '../models/report'
import { PaymentMethods, ValidationStatuses } from '../models'

export interface GetParams {
    search?: string
    page?: string
    assignedToUser?: boolean
    userId?: string
}

/**
 * Addresses
 */
export interface CreateAddressParams {
    city: string
    countryId: number | string
    stateId: number | string
    street: string
    street2?: string
    zip: string
    manualValidationStatus?: ValidationStatuses
}
export const createAddress = (params: CreateAddressParams) => axios.post(
    routes.createAddress,
    params
)

/**
 * Agencies
 */
export interface CreateAgencyParams {
    address: {
        city: string;
        countryId: number,
        stateId: string;
        street: string;
        street2: string;
        zip: string;
    },
    branch: string;
    firstName: string
    lastName: string
    ein: string
    email: string
    phone_number: string
    name: string
    status: number;
    paymentMethod?: number | string
    softwareType?: number | string
}
export const createAgency = (params: CreateAgencyParams) => axios.post(
    routes.createAgency,
    params
)
export const getAgency = (agencyId: string) => axios.get(
    routes.agency.replace('{agencyId}', agencyId)
)

interface GetAgenciesParams extends GetParams {
    active?: boolean
    unknownSupplier?: boolean
    fields?: string[]
}

export const getAgencies = (params?: GetAgenciesParams) => axios.get(routes.agencies, { params })
export interface UpdateAgencyParams {
    branch?: string;
    firstName?: string
    lastName?: string
    ein?: string
    email?: string
    phone_number?: string
    name?: string
    status?: number;
    paymentMethod?: number | string
    softwareType?: number | string
}
export const updateAgency = (agencyId: string, params: UpdateAgencyParams) => axios.put(
    routes.updateAgency.replace('{agencyId}', agencyId),
    params
)
interface UpdateAgencyEmailsParams {
    emails: string[]
    primaryEmail: string
}

export const updateAgencyEmails = (agencyId: string, params:UpdateAgencyEmailsParams) => axios.put(
    routes.updateAgencyEmails.replace('{agencyId}', agencyId),
    params
)

export const uploadAgencyData = (agencyId: string, form: FormData) => axios.post(
    routes.uploadAgencyData.replace('{agencyId}', agencyId),
    form
)

export const uploadCtsData = (form: FormData) => axios.post(routes.uploadCtsData, form)
export const uploadVtsArcData = (form: FormData, agencyId: string) => axios.post(
    routes.uploadVtsArcData.replace('{agencyId}', agencyId),
    form
)

/**
 * Agency Addresses
 */
interface AgencyAddressToAttach {
    addressId: number
    isPayment?: boolean
    isPhysical?: boolean
}

export interface SyncAgencyAddressesParams {
    addressToAttach?: AgencyAddressToAttach
    addressIdToDetach?: number
}

export const syncAgencyAddresses = (
    agencyId: string,
    params: SyncAgencyAddressesParams
) => axios.post(
    routes.syncAgencyAddresses.replace('{agencyId}', agencyId),
    params
)
export interface UpdateAgencyAddressesParams {
    paymentAddressId?: number
    physicalAddressId?: number
}

export const updateAgencyAddresses = (
    agencyId: string,
    params: UpdateAgencyAddressesParams
) => axios.patch(
    routes.updateAgencyAddresses.replace('{agencyId}', agencyId),
    params
)

/**
 * Agency Statuses
 */
export const getAgencyStatuses = () => axios.get(routes.agencyStatuses)

/**
 * Agency ARCs
 */
export interface CreateAgencyArcParams {
    agencyId: number
    arc: string
}
export const createAgencyArc = (params: CreateAgencyArcParams) => axios.post(
    routes.createAgencyArc,
    params
)
export const getAgencyArcs = (agencyId: string) => axios.get(
    routes.getAgencyArcs.replace('{agencyId}', agencyId)
)
export interface UpdateAgencyArcParams {
    arc: string
}
export const updateAgencyArc = (agencyArcId: number, params: UpdateAgencyArcParams) => axios.put(
    routes.updateAgencyArc.replace('{agencyArcId}', agencyArcId.toString()),
    params
)
export const deleteAgencyArc = (agencyArcId: number) => axios.delete(
    routes.deleteAgencyArc.replace('{agencyArcId}', agencyArcId.toString())
)

/**
 * Agency Software Types
 */

export const getAgencySoftwareTypes = () => axios.get(
    routes.getAgencySoftwareTypes
)

/**
 * Agency Notes
 */
export interface CreateAgencyNoteParams {
    agencyId: number
    note: string
}
export const createAgencyNote = (params: CreateAgencyNoteParams) => axios.post(
    routes.createAgencyNote,
    params
)
export const getAgencyNotes = (agencyId: string) => axios.get(
    routes.getAgencyNotes.replace('{agencyId}', agencyId)
)
export const deleteAgencyNote = (agencyNoteId: number) => axios.delete(
    routes.deleteAgencyNote.replace('{agencyNoteId}', agencyNoteId.toString())
)

/**
 * Agency Payments
 */
export interface CreateAgencyPaymentParams {
    agencyId: number
    amount: string
    depositDate: string
    paymentMethodId: PaymentMethods
}
export const createAgencyPayment = (params: CreateAgencyPaymentParams) => axios.post(
    routes.createAgencyPayment,
    params
)

/**
 * Chains
 */
export const getChain = (chainId: string) => axios.get(
    routes.chain.replace('{chainId}', chainId)
)
export const getChains = (params?: GetParams) => axios.get(
    routes.chains,
    { params }
)
export interface UpdateChainParams {
    abbreviation?: string
    code?: string
    name?: string
}
export const updateChain = (chainId: string, params: UpdateAgencyParams) => axios.put(
    routes.updateChain.replace('{chainId}', chainId),
    params
)

export interface CreateChainParams {
    abbreviation: string
    code: string
    name: string
}
export const createChain = (params: CreateChainParams) => axios.post(
    routes.createChain,
    params
)

/**
 * Countries
 */
export const getCountry = (countryId: string) => axios.get(
    routes.country.replace('{countryId}', countryId)
)
export const getCountries = (params?: GetParams) => axios.get(
    routes.countries,
    { params }
)
export interface UpdateCountryParams {
    abbreviation?: string
    currency?: string
    exchangeRate?: number
    name?: string
}
export const updateCountry = (countryId: string, params: UpdateAgencyParams) => axios.put(
    routes.updateCountry.replace('{countryId}', countryId),
    params
)

/**
 * Invoices
 */
export const getInvoice = (invoiceId: string) => axios.get(
    routes.invoice.replace('{invoiceId}', invoiceId)
)
export const getInvoices = (params?: GetParams) => axios.get(
    routes.invoices,
    { params }
)

export interface CreateInvoiceParams {
    supplierId: number;
    invoiceType: 'standard' | 'collections';
    deliveryMethod: 'email' | 'postage' | 'preview'
    /** list of record ids to include in the invoice. if omitted, all billable records for the supplier are included */
    records?: number[];
}

export interface CreateInvoiceResponse {
    results: Array<{message: string, success: boolean}>;
    invoice: JsonInvoice;
}

export const createInvoice = (params: CreateInvoiceParams) => axios.post<CreateInvoiceResponse>(
    routes.createInvoice,
    params
)

export interface ReconcileInvoiceParams {
    checkNumber: string;
    completed: boolean;
    /** paymentAmount is only used to validate the payment method */
    paymentAmount: number;
    paymentMethod: number | null;
    processDate: string;
    records: {[recordId: string]: ReconcileInvoiceRecordParams};
}

export interface ReconcileInvoiceRecordParams {
    id: number;

    commission?: number;
    days?: number;
    paidCt?: number;
    paymentMethodId?: number | null;
    rate?: number;
    statusCode?: number;
}

export const updateInvoiceRecords = (invoiceId: string, params: ReconcileInvoiceParams) => axios.patch(
    routes.updateInvoiceRecords.replace('{invoiceId}', invoiceId),
    params
)

/**
 * Invoice Suppliers
 */
export interface GetInvoiceSupplierParams extends GetParams {
    checkInDateFrom: string
    checkInDateTo: string
    locale: string
    vendorCode: number
    billingType: string
    minimumPages: number
    maximumPages: number
    minimumCommission: number
    excludeInvoicedAfter: string
}

export const getInvoiceSuppliers = (params?: GetInvoiceSupplierParams) => axios.get(
    routes.invoiceSuppliers,
    { params }
)

/**
 * Invoice Pdfs
 */
export interface GenerateInvoicePdfsParams {
    checkInDateFrom: string
    checkInDateTo: string
    locale: string
    vendorCode: number
    billingType: string
    minimumPages: number
    maximumPages: number
    minimumCommission: number
    excludeInvoicedAfter: string
}

export const generateInvoicePdfs = (params?: GenerateInvoicePdfsParams) => axios.post(
    routes.generateInvoicePdfs,
    params
)

interface GetPaymentMethodParams extends GetParams {
    agencyPaymentMethods?: boolean
}

/**
 * Payment Methods
 */
export const getAgencyPaymentMethods = (params?: GetPaymentMethodParams) => getPaymentMethods({
    ...params,
    agencyPaymentMethods: true
})

export const getPaymentMethods = (params?: GetPaymentMethodParams) => axios.get(
    routes.paymentMethods,
    { params }
)

/**
 * Daily Payments
 */
export const getDailyPayments = ({ startDate, endDate }: { startDate: string; endDate: string }) => {
    return axios.get(routes.dailyPayments, {
        params: {
            startDate,
            endDate
        }
    })
}

/**
 * Quickbooks
 */
export const getQuickbooksTokenExpiration = () => axios.get(
    routes.quickbooksTokenExpiration
)
export const getQuickbooksAuthUrl = () => axios.get(routes.quickbooksAuthUrl)

/**
 * Records
 */
export interface CreateRecordParams {

}
export const createRecord = (params: CreateRecordParams) => axios.post(
    routes.createRecord,
    params
)
export const getRecord = (recordId: string) => axios.get(
    routes.record.replace('{recordId}', recordId)
)
export const getRecords = (params?: GetParams) => axios.get(routes.records, { params })
export const getUnassignedRecordCount = (params?: GetParams) => axios.get(
    routes.unassignedRecordCount,
    { params }
)
export const getRecordsWithInvalidSupplier = (params?: GetParams) => axios.get(
    routes.recordsWithInvalidSupplier,
    { params }
)
export const getRecordsWithUnknownSupplier = (params?: GetParams) => axios.get(
    routes.recordsWithUnknownSupplier,
    { params }
)

export interface UpdateRecordParams {
    paid_ct?: number
    phone_number?: string
    status?: number
    substatus? :number
}
export const updateRecord = (recordId: string, params: UpdateRecordParams) => axios.put(
    routes.updateRecord.replace('{recordId}', recordId),
    params
)
export interface BatchUpdateRecordParams {
    [fieldName: string]: {
        oldValue: string
        newValue: string
    }
}
export const batchUpdateRecords = (params: BatchUpdateRecordParams) => axios.patch(
    routes.batchUpdateRecords,
    params
)
interface GetPaidRecordsParams extends GetParams {
    dailyPayment: boolean
    date_paid: string
}
export const getPaidRecords = (params: GetPaidRecordsParams) => axios.get(
    routes.paidRecords,
    { params }
)
export const getRecordsAssignedToUser = (params?: GetParams) => {
    const updatedParams = {
        ...params,
        assignedToUser: true
    }
    return axios.get(routes.records, {
        params: updatedParams
    })
}
export const getRecordsByPhone = (phone: string) => axios.get(
    routes.getRecordsByPhone.replace('{phone}', phone)
)
export const getOpenForeignRecords = (params?: GetParams) => axios.get(
    routes.foreignConversionRecords,
    { params }
)
export const deleteRecord = (recordId: string) => axios.delete(
    routes.deleteRecord.replace('{recordId}', recordId)
)

/**
 * Unknown Supplier Records CSV Download
 */
export const downloadUnknownSupplierCsv = (params?: GetParams): Promise<AxiosResponse<Blob>> => {
    return axios.get<Blob>(routes.downloadUnknownSupplierCsv, { params, responseType: 'blob' })
}

/**
 * Record Notes
 */
export interface CreateRecordNoteParams {
    recordId: number
    note: string
}
export const createRecordNote = (params: CreateRecordNoteParams) => axios.post(
    routes.createRecordNote,
    params
)
export const getRecordNotes = (recordId: string) => axios.get(
    routes.getRecordNotes.replace('{recordId}', recordId)
)
export const deleteRecordNote = (recordNoteId: number) => axios.delete(
    routes.deleteRecordNote.replace('{recordNoteId}', recordNoteId.toString())
)

/**
 * Record Statuses
 */
export const getRecordStatuses = () => axios.get(routes.recordStatuses)

/**
 * Reports
 */
interface ReportParams {
    month: number
    year: number
}

export const getReports = async (params?: ReportParams): Promise<AxiosResponse<Report[]>> => {
    return await axios.get(routes.reports, { params })
}

export const generateReports = async (year: number, month: number): Promise<AxiosResponse<Report[]>> => {
    return await axios.post(routes.reports, { year, month })
}

export const publishReports = async (year: number, month: number): Promise<AxiosResponse<boolean>> => {
    return await axios.post(routes.publishReports, { year, month })
}

/**
 * Roles
 */
export const getRoles = (params?: GetParams) => axios.get(routes.roles, { params })

/**
 * States
 */
export const getStates = (params?: GetParams) => axios.get(
    routes.states,
    { params }
)

/**
 * Suppliers
 */
export interface CreateSupplierParams {
    address: CreateAddressParams
    chain?: string | number
    city?: string
    country?: string
    email?: string
    fax?: string
    name: string
    dba?: string
    paymentMethod?: number | string
    phone_number: string
    state?: string
    street?: string
    zip?: string
    status?: number
    substatus?: number | string
    vendorId?: number | string
}
export const createSupplier = (params: UpdateSupplierParams) => axios.post(
    routes.createSupplier,
    params
)
export const deleteSupplier = (supplierId: string) => axios.delete(
    routes.deleteSupplier.replace('{supplierId}', supplierId)
)
export const deleteSupplierRecords = (phone: string) => axios.delete(
    routes.deleteSupplierRecords.replace('{phone}', phone)
)
export const getSupplier = (supplierId: string) => axios.get(
    routes.supplier.replace('{supplierId}', supplierId)
)

export interface GetSupplierParams extends GetParams {
    closed?: boolean
    pendingPayment?: boolean
}

export const getSuppliers = (params?: GetSupplierParams) => axios.get(
    routes.suppliers,
    { params }
)
export interface UpdateSupplierParams {
    city?: string
    chain?: string | number
    country?: string
    email?: string
    fax?: string
    name?: string
    dba?: string
    paymentMethod?: number | string
    phone_number?: string
    reopenDate?: string
    state?: string
    street?: string
    zip?: string
    status?: number
    substatus?: number
    vendorId?: number | string
}
export const updateSupplier = (supplierId: string, params: UpdateSupplierParams) => axios.put(
    routes.updateSupplier.replace('{supplierId}', supplierId),
    params
)
export const uploadSupplierData = (form: FormData) => axios.post(
    routes.uploadSupplierData,
    form
)

interface UpdateSupplierPhoneNumbersParams {
    phoneNumbers: string[]
    primaryPhoneNumber: string
}

export const updateSupplierPhoneNumbers = (supplierId: string, params: UpdateSupplierPhoneNumbersParams) => axios.put(
    routes.updateSupplierPhoneNumbers.replace('{supplierId}', supplierId),
    params
)

export interface CreateCallbackParams {
    scheduledTime: string
    reason: string
    supplierId: number
    assignedTo: number
}

/**
 * Supplier Callbacks
 */
export const createSupplierCallback = (params: CreateCallbackParams) => axios.post(
    routes.createSupplierCallback,
    params
)

export const getSupplierCallbacks = (supplierId: string) => axios.get(
    routes.getSupplierCallbacks.replace('{supplierId}', supplierId)
)

export const deleteSupplierCallback = (callbackId: number) => axios.delete(
    routes.deleteSupplierCallback.replace('{callbackId}', callbackId.toString())
)

export const getCallbacksCalendarId = () => axios.get(routes.getCallbacksCalendarId)

/**
 * Supplier Statuses
 */
export const getSupplierStatuses = () => axios.get(routes.supplierStatuses)

/**
 * Supplier Addresses
 */
interface SupplierAddressToAttach {
    addressId: number
    isBilling?: boolean
    isPhysical?: boolean
}

export interface SyncSupplierAddressesParams {
    addressToAttach?: SupplierAddressToAttach
    addressIdToDetach?: number
}

export const syncSupplierAddresses = (
    supplierId: string,
    params: SyncSupplierAddressesParams
) => axios.post(
    routes.syncSupplierAddresses.replace('{supplierId}', supplierId),
    params
)
export interface UpdateSupplierAddressesParams {
    billingAddressId?: number
    physicalAddressId?: number
}

export const updateSupplierAddresses = (
    supplierId: string,
    params: UpdateSupplierAddressesParams
) => axios.patch(
    routes.updateSupplierAddresses.replace('{supplierId}', supplierId),
    params
)

/**
 * Supplier Balances
 */

export interface getSupplierBalancesParams extends GetParams {
    minimumBalance?: number
}

export const getSupplierBalances = (params?: getSupplierBalancesParams) => axios.get(
    routes.supplierBalances,
    { params }
)

/**
 * Supplier Invoices
 */

export const getSupplierInvoices = (supplierId: string) => axios.get(
    routes.getSupplierInvoices.replace('{supplierId}', supplierId)
)

/**
 * Supplier Collections
*/

export const getUnassignedSupplierCollectionsCount = (params?: GetParams) => axios.get(
    routes.unassignedSupplierCollectionsCount,
    { params }
)
export interface UpdateSupplierAssignedUserParams {
    assigned_user_id: string;
    search?: string;
    selected_suppliers?: Number[];
    reassign?: boolean;
}

export const updateSupplierAssignedUser = (params: UpdateSupplierAssignedUserParams) => axios.patch(
    routes.updateSupplierAssignedUser,
    params
)

/**
 * Supplier Balances CSV Download
 */
export const downloadSupplierBalancesCsv = (): Promise<AxiosResponse<Blob>> => {
    return axios.get<Blob>(routes.downloadSupplierBalancesCsv, { responseType: 'blob' })
}

/**
 * Supplier Contacts
 */
export interface SupplierCollectionsContactParams {
    title: string
    name: string
    phone?: string
    email?: string
}

export const getSupplierCollectionsContact = (supplierId: string) => axios.get(
    routes.getSupplierCollectionsContact.replace('{supplierId}', supplierId)
)

export const updateSupplierCollectionsContacts = (supplierId: number, contacts: Array<SupplierCollectionsContactParams>): Promise<AxiosResponse<{contacts: JsonSupplierCollectionsContact[]}>> => axios.put(
    routes.updateSupplierCollectionsContact.replace('{supplierId}', supplierId.toString()),
    { contacts }
)

/**
 * Supplier Notes
 */
export interface CreateSupplierNoteParams {
    supplierId: number
    note: string
}
export const createSupplierNote = (params: CreateSupplierNoteParams) => axios.post(
    routes.createSupplierNote,
    params
)
export const getSupplierNotes = (supplierId: string) => axios.get(
    routes.getSupplierNotes.replace('{supplierId}', supplierId)
)
export const deleteSupplierNote = (supplierNoteId: number) => axios.delete(
    routes.deleteSupplierNote.replace('{supplierNoteId}', supplierNoteId.toString())
)

/**
 * Supplier Recordings
 */
export const uploadSupplierRecording = (supplierId: number, formData: FormData) => {
    return axios.post(routes.uploadSupplierRecording.replace('{supplierId}', supplierId.toString()), formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
}
export const getSupplierRecording = (supplierId: number, fileName: string) => {
    return axios.get(routes.getSupplierRecording.replace('{supplierId}', supplierId.toString()).replace('{fileName}', fileName), {
        responseType: 'blob',
    })
}
export const listSupplierRecordings = (supplierId: number) => {
    return axios.get(routes.listSupplierRecordings.replace('{supplierId}', supplierId.toString()))
}
export const deleteSupplierRecording = (supplierId: number, fileName: string) => {
    return axios.delete(routes.deleteSupplierRecording.replace('{supplierId}', supplierId.toString()).replace('{fileName}', fileName))
}

/**
 * Users
 */
export const getUser = (userId: string) => axios.get(
    routes.user.replace('{userId}', userId)
)
export const getUsers = (params?: GetParams) => axios.get(routes.users, { params })

interface UserUpdateParams {
    roles: { [roleId: number]: boolean }
    isActive: boolean
}

export const updateUserStatus = (userId: string, updateParams: UserUpdateParams) => axios.post(
    routes.userStatus.replace('{userId}', userId),
    updateParams
)

export interface RegisterUserParams {
    name: string
    email: string
    password: string
    password_confirmation: string
}

export const registerUser = (params: RegisterUserParams) => axios.post(routes.register, params)

/**
 * Vendor Codes
 */
export const getVendorCodes = () => axios.get(routes.vendorCodes)
export const getVendorCode = (vendorId: string) => axios.get(
    routes.vendorCode.replace('{vendorId}', vendorId)
)

export interface CreateVendorCodeParams {
    code?: string
}
export const createVendorCode = (params: CreateVendorCodeParams) => axios.post(
    routes.createVendorCode,
    params
)

export interface UpdateVendorCodeParams {
    id?: number
    code?: string
}
export const updateVendorCode = (vendorId: string, params: UpdateVendorCodeParams) => axios.put(
    routes.updateVendorCode.replace('{vendorId}', vendorId),
    params
)
