import React from 'react'

import { InsufficientPermissions, PaginatedTable, PaginatedTableCallbacks } from '../common'
import { UsersTable } from './table'
import { getAuthUser } from '../../helpers'
import { JsonUser, User } from '../../models'
import { getUsers } from '../../api'
import defineAbilityFor from './defineAbility'

export const UsersLanding = () => {
    const ability = defineAbilityFor(getAuthUser())

    if (!ability.can('view', 'User')) {
        return <InsufficientPermissions />
    }

    return (
        <PaginatedTable<User, JsonUser>
            getData={getUsers}
            redirectLink={{
                to: '/register',
                text: 'Register New User',
            }}
            modelConstructor={(json: JsonUser) => new User(json)}
            searchPlaceholder='Search by Name or Email'
            Table={(users: User[], callbacks: PaginatedTableCallbacks) => (
                <UsersTable
                    callbacks={callbacks}
                    resources={{ users }} />
            )}
            title="Users" />
    )
}
