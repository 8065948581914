import { JsonModel, Model } from './model'
import { JsonUser, User } from './user'

export class SupplierNote extends Model {
    supplierId: string
    createdBy: string
    note: string
    invoiceId: number | null
    important: boolean

    constructor(json: JsonSupplierNote) {
        super(json)

        this.supplierId = json.supplierId
        this.createdBy = 'System'

        if (json.createdBy) {
            const user = new User(json.createdBy)
            this.createdBy = user.name
        }

        this.invoiceId = json.invoiceId
        this.note = json.note
        this.important = json.important
    }
}

export interface JsonSupplierNote extends JsonModel {
    supplierId: string
    createdBy: JsonUser | null
    note: string
    invoiceId: number | null
    important: boolean
}
