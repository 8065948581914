import { toast } from 'react-toastify'

interface Result {
    message: string
    success: boolean
}

export interface JsonResponseWithResultsData {
    results: Result[]
}

export interface JsonResponseWithResults<T extends JsonResponseWithResultsData> {
    data: T
}

export function toastJsonResults(json: JsonResponseWithResults<JsonResponseWithResultsData>) {
    const { results } = json.data

    results.forEach((result: Result) => {
        if (result.success) {
            toast.success(result.message)
        } else {
            toast.error(result.message, {
                autoClose: false
            })
        }
    })
}
