import React from 'react'

import { generateInvoicePdfs, getInvoiceSuppliers } from '../../../api'
import { InvoiceSuppliersTable } from './collectionTable';
import { PaginatedTable, PaginatedTableCallbacks, InsufficientPermissions } from '../../common'
import defineAbilityFor from '../defineAbility'
import { InvoiceBatchFilters } from './filters'
import { getAuthUser } from '../../../helpers';
import { Supplier, JsonSupplier } from '../../../models'

export const GenerateInvoicesLanding = () => {

    const ability = defineAbilityFor(getAuthUser())

    if (!ability.can('Create', 'InvoiceBatch')) {
        return <InsufficientPermissions />
    }

    return (
        <PaginatedTable<Supplier, JsonSupplier>
            getData={getInvoiceSuppliers}
            Filters={InvoiceBatchFilters}
            postLink={{
                request: generateInvoicePdfs,
                text: 'Generate Invoice PDFs',
            }}
            filterButton={{
                text: 'Filter Suppliers'
            }}
            disableAutoFilter={true}
            modelConstructor={(json: JsonSupplier) => new Supplier(json)}
            searchPlaceholder='Search by Name, Phone, Street Address, or City'
            Table={(suppliers: Supplier[], callbacks: PaginatedTableCallbacks) => (
                <InvoiceSuppliersTable
                    callbacks={callbacks}
                    resources={{suppliers}}
                />
            )}
            title='Suppliers'
        />
    )
}
