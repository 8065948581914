import React, { Dispatch, SetStateAction } from 'react'

import { getAuthUser } from '../../../helpers'
import {
    HiddenSpan,
    TooltipWrapper,
    TrDisplayHiddenSpanOnHover
} from '../../common'
import defineAbilityFor from './defineAbility'

interface TableProps {
    onPhoneNumbersChanged: (
        phoneNumbers: string[],
        primaryPhoneNumber: string,
        successCallback?: () => void
    ) => void

    phoneNumbers: string[]
    primaryPhoneNumber: string
    setPhoneNumberToDelete: Dispatch<SetStateAction<string>>
    setPhoneNumberToEdit: Dispatch<SetStateAction<string>>
}

export const SupplierPhoneNumberTable = (props: TableProps) => {
    const {
        onPhoneNumbersChanged,
        phoneNumbers,
        primaryPhoneNumber,
        setPhoneNumberToDelete,
        setPhoneNumberToEdit
    } = props

    const ability = defineAbilityFor(getAuthUser())

    const updatePrimary = (phoneNumber: string) => {
        onPhoneNumbersChanged(phoneNumbers, phoneNumber)
    }

    return (
        <table className='table table-sm'>
            <tbody>
                {[...phoneNumbers]
                    .sort()
                    .sort((a, b) => {
                        if (a === primaryPhoneNumber) {
                            return -1
                        }
                        if (b === primaryPhoneNumber) {
                            return 1
                        }
                        return 0
                    })
                    .map(phone => (
                        <TrDisplayHiddenSpanOnHover
                            className='d-flex'
                            key={`supplier_phone_${phone}`}
                            primary={phone === primaryPhoneNumber}>
                            <td className='d-flex align-items-center' style={{ width: '45%' }}>
                                {phone}
                            </td>
                            <td className='d-flex align-items-end flex-column ps-0 pe-0'
                                style={{ width: '55%' }}>
                                <span className='d-flex mb-2'>
                                    {phone === primaryPhoneNumber &&
                                        <span className='badge bg-success rounded-pill ms-2'>
                                            Primary
                                        </span>
                                    }
                                </span>
                                <HiddenSpan className='d-flex mb-2 mt-auto align-items-end'>
                                    <form onSubmit={(e) => {
                                        e.preventDefault()

                                        updatePrimary(phone)
                                    }}>
                                        <TooltipWrapper
                                            condition={phone === primaryPhoneNumber
                                                || !ability.can('Update', 'SupplierPhoneNumber')
                                            }
                                            element={
                                                <button
                                                    className='btn btn-outline-primary btn-sm'
                                                    disabled={phone === primaryPhoneNumber
                                                        || !ability.can('Update', 'SupplierPhoneNumber')
                                                    }
                                                    type='submit'>
                                                    Set as Primary
                                                </button>
                                            }
                                            message={phone === primaryPhoneNumber
                                                ? 'Phone is currently primary'
                                                : 'Insufficient permissions'
                                            } />
                                    </form>
                                    <TooltipWrapper
                                        condition={phone === primaryPhoneNumber
                                            || !ability.can('Delete', 'SupplierPhoneNumber')
                                        }
                                        element={
                                            <button
                                                className='btn btn-outline-danger btn-sm ms-1'
                                                disabled={phone === primaryPhoneNumber
                                                    || !ability.can('Delete', 'SupplierPhoneNumber')
                                                }
                                                onClick={() => setPhoneNumberToDelete(phone)}>
                                                Delete
                                            </button>
                                        }
                                        message={(phone === primaryPhoneNumber)
                                            ? 'Cannot delete primary phone'
                                            : 'Insufficient permissions'
                                        } />
                                    <TooltipWrapper
                                        condition={!ability.can('Update', 'SupplierPhoneNumber')}
                                        element={
                                            <button
                                                className='btn btn-primary btn-sm mt-auto ms-1'
                                                disabled={
                                                    !ability.can('Update', 'SupplierPhoneNumber')
                                                }
                                                onClick={() => setPhoneNumberToEdit(phone)}>
                                                Edit
                                            </button>
                                        }
                                        message='Insufficient permissions' />
                                </HiddenSpan>
                            </td>
                        </TrDisplayHiddenSpanOnHover>
                    ))}
            </tbody>
        </table>
    )
}
