import { JsonRecordChange, RecordChange } from './recordChange'

export class RecordRateChange extends RecordChange {
    constructor(json: JsonRecordRateChange) {
        super(json)

        this.oldValue = parseFloat(json.oldRate).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        })
        this.newValue = parseFloat(json.newRate).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD'
        })
        this.createdBy = json.createdBy
    }
}

export interface JsonRecordRateChange extends JsonRecordChange {
    oldRate: string
    newRate: string
    createdBy: string
}
