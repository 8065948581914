import React from 'react'
import { toast } from 'react-toastify'

import {
    createAddress,
    CreateAddressParams,
    SyncSupplierAddressesParams
} from '../../../api'
import { AddressForm } from '../../common'
import { Address } from '../../../models'

interface EditAddressProps {
    address: Address
    syncSupplierAddresses: (params: SyncSupplierAddressesParams) => void
}

export const EditSupplierAddress = (props: EditAddressProps) => {
    const { address, syncSupplierAddresses } = props

    const handleSubmit = (inputs: CreateAddressParams) => {
        createAddress(inputs)
            .then((response) => {
                syncSupplierAddresses({
                    addressToAttach: {
                        addressId: response.data.id,
                        isBilling: address.isBilling,
                        isPhysical: address.isPhysical
                    },
                    addressIdToDetach: address.id
                })
            })
            .catch(error => {
                if (error?.response?.data?.message) {
                    toast.error(error.response.data.message, { autoClose: false })
                }
            })
    }

    return (
        <AddressForm
            address={address}
            handleSubmit={handleSubmit} />
    )
}
