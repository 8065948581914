import React, { useState } from 'react'

interface AgencyEmailFormProps {
    handleSubmit: (email: string) => void
    email?: string
    title: string
}
export const AgencyEmailForm = (props: AgencyEmailFormProps) => {
    const { handleSubmit, title } = props

    const [email, setEmail] = useState(props.email ?? '')

    return (
        <form onSubmit={(e) => {
            e.preventDefault()

            handleSubmit(email)
        }}>
            <label htmlFor='emailInput'>{title}</label>
            <input
                className='form-control'
                defaultValue={email}
                id='emailInput'
                maxLength={254}
                onChange={(e) => setEmail(e.target.value)}
                required
                type='email' />
            <span className='d-flex justify-content-center mt-3'>
                <button
                    className='btn btn-primary'
                    type='submit'>
                    Submit
                </button>
            </span>
        </form>
    )
}
