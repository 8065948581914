import { JsonModel, Model } from './model'

export class SupplierSubstatus extends Model {
    public static WAITING_ON_RESPONSE = 1;
    public static WAITING_FOR_PAYMENT = 2;
    public static NEGOTIATING_PAYMENT = 3;
    public static PAYMENT_PLAN = 4;
    public static INVOICE_1 = 5;
    public static INVOICE_2 = 6;
    public static INVOICE_3 = 7;
    public static BILLABLE_COLLECTIONS = 8;
    public static INTERNATIONAL_COLLECTIONS = 9;

    code: number
    name: string

    constructor(json: JsonSupplierSubstatus) {
        super(json)

        this.code = json.code
        this.name = json.name
    }
}

export interface JsonSupplierSubstatus extends JsonModel {
    code: number
    name: string
}
