import React, {
    Dispatch,
    FormEvent,
    SetStateAction,
    useState,
    useEffect
} from 'react'
import { toast } from 'react-toastify'

import { getAuthUser } from '../../helpers';
import { updateAgency, UpdateAgencyParams } from '../../api'
import { Agency } from '../../models'
import { AgencyEditForm } from './editForm'
import defineAbilityFor from './defineAbility'
import { InsufficientPermissions } from '../common'
import { Loading } from '../common'

interface EditAgencyProps {
    agency: Agency
    setIsEditing: Dispatch<SetStateAction<boolean>>
    setAgency: Dispatch<SetStateAction<Agency | undefined>>
}

export const EditAgencyForm = (props: EditAgencyProps) => {
    const { agency, setIsEditing, setAgency } = props
    const [isLoading, setIsLoading] = useState(false)
    const [isMounted, setIsMounted] = useState(true)

    const ability = defineAbilityFor(getAuthUser())

    useEffect(() => {
        return () => {
            setIsMounted(false)
        }
    }, [])

    const handleSubmit = (e: FormEvent<HTMLFormElement>, inputs: UpdateAgencyParams) => {
        e.preventDefault()
        setIsLoading(true)

        updateAgency(agency.id.toString(), inputs)
            .then(response => {
                if (isMounted) {
                    if (response && response.data) {
                        setAgency(new Agency(response.data.data))
                        setIsEditing(false)

                        const { status, message } = response.data;
                        const toastOptions = { autoClose: 8000 };

                        if (status === 'recordsUnchanged' || status === 'statusChanged' || status === 'agencyUpdated') {
                            toast.success(message, toastOptions);
                        } else {
                            toast.error('Unexpected response status.');
                        }
                    } else {
                        toast.error('Unexpected response format.')
                    }
                }
            })
            .catch(error => {
                if (isMounted) {
                    const { response } = error
                    if (response.data.message) {
                        toast.error(response.data.message, { autoClose: false })
                    }

                    if (response.status === 400) {
                        toast.error(response.data, { autoClose: false })
                    }
                }
            })
            .finally(() => {
                if (isMounted) {
                    setIsLoading(false)
                }
            })
    }

    if (!ability.can('Edit', 'Agency')) {
        return <InsufficientPermissions />
    }

    return (
        <>
            {isLoading && <Loading />}
            <AgencyEditForm
                handleSubmit={handleSubmit}
                agency={agency} />
        </>
    )
}
