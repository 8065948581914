import React, { FormEvent, useState } from 'react'
import { toast } from 'react-toastify'

import { RecordsTable } from '..'
import { Record } from '../../../models'
import { TooltipWrapper } from '../..'
import defineAbilityFor from '../defineAbility'
import { getAuthUser } from '../../../helpers'
import { deleteSupplierRecords } from '../../../api/requests'

interface BatchDeleteRecordFormProps {
    phone: string
    records: Record[]
    resetForm: () => void
}

export const BatchDeleteRecordForm = (props: BatchDeleteRecordFormProps) => {
    const { phone, records, resetForm } = props

    const [submitting, setSubmitting] = useState(false)

    const ability = defineAbilityFor(getAuthUser())

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setSubmitting(true)

        deleteSupplierRecords(phone)
            .then(response => {
                setSubmitting(false)

                if (response.status === 200) {
                    toast.success('Records Deleted')

                    if (resetForm) {
                        resetForm()
                    }
                }
            })
            .catch(error => {
                setSubmitting(false)

                const { response } = error

                if (response?.status === 403) {
                    toast.error('Executive permissions required to delete records', { autoClose: false })
                }

                if (response?.status === 400) {
                    toast.error(response.data, { autoClose: false })
                }

                console.error(error)
            })
    }

    return (
        <form className='container' onSubmit={(e) => handleSubmit(e)}>
            <div className='row mb-5'>
                <div className='col-xs-12 col-md-6'>
                    <h1>Records to Delete</h1>
                </div>
                <div className='col-xs-12 col-md-6 d-flex flex-row-reverse'>
                    <TooltipWrapper
                        condition={!ability.can('Delete', 'Record')}
                        element={
                            <button
                                className='btn btn-danger'
                                disabled={!ability.can('Delete', 'Record') || submitting}
                                type='submit'>
                                Delete
                            </button>
                        }
                        message='Executive permission required to delete record' />
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <RecordsTable
                        callbacks={{setReload: () => resetForm()}}
                        resources={{ records }} />
                </div>
            </div>
        </form>
    )
}
