import { JsonModel, Model } from './model'

export const USA = 214

export class Country extends Model {
    id: number
    abbreviation: string
    billable: boolean
    currency: string
    exchange: number
    isForeign: boolean
    name: string

    constructor(json: JsonCountry) {
        super(json)

        this.id = json.id
        this.abbreviation = json.abbreviation
        this.billable = +json.billable === 1
        this.currency = json.currency
        this.exchange = json.exchange
        this.isForeign = this.id !== USA
        this.name = json.name
    }
}

export interface JsonCountry extends JsonModel {
    id: number
    abbreviation: string
    billable: number
    currency: string
    exchange: number
    name: string
}
