import React, { useEffect, useState } from 'react'

import { getPaymentMethods, getRecordStatuses } from '../../../api'
import {
    JsonPaymentMethod,
    JsonRecordStatus,
    PaymentMethod,
    RecordStatus,
} from '../../../models'

interface FilterProps {
    updateFilters: (
        filterName: string,
        newValue: string | null,
        shouldDelete?: boolean
    ) => void
}

export const DailyPaymentFilters: React.FC<FilterProps> = ({ updateFilters }) => {
    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])
    const [recordStatuses, setRecordStatuses] = useState<RecordStatus[]>([])

    useEffect(() => {
        getPaymentMethods().then((json) => {
            const methods = json.data.map(
                (method: JsonPaymentMethod) => new PaymentMethod(method)
            )
            setPaymentMethods(methods)
        })
    }, [])

    useEffect(() => {
        getRecordStatuses().then((json) => {
            const statuses = json.data
                .map((status: JsonRecordStatus) => new RecordStatus(status))
                .filter((status: RecordStatus) => RecordStatus.PAID_STATUSES.includes(status.code))
            setRecordStatuses(statuses)
        })
    }, [])

    return (
        <>
            <label htmlFor="paymentMethodFilter">Payment Method</label>
            <select
                className="form-select"
                id="paymentMethodFilter"
                onChange={(e) =>
                    updateFilters(
                        'payment_method_id',
                        e.target.value === '-1' ? '' : e.target.value
                    )
                }
            >
                <option value={-1}>All</option>
                <option value="none">No Payment Method</option>
                {paymentMethods.map((method: PaymentMethod) => (
                    <option key={method.id} value={method.id}>
                        {method.name}
                    </option>
                ))}
            </select>
            <label htmlFor="recordStatusFilter">Record Status</label>
            <select
                className="form-select"
                id="recordStatusFilter"
                onChange={(e) =>
                    updateFilters(
                        'record_status_code',
                        e.target.value ?? '',
                        e.target.value === '-1'
                    )
                }
            >
                <option value={-1}>All</option>
                {recordStatuses.map((status: RecordStatus) => (
                    <option key={status.code} value={status.code}>
                        {status.name}
                    </option>
                ))}
            </select>
        </>
    )
}
