import React, { FormEvent, useState } from 'react'
import { toast } from 'react-toastify'

import { createAgencyArc } from '../../../api'
import { AgencyArc } from '../../../models'

interface AddArcProps {
    agencyId: number
    existingArcs: AgencyArc[]
    setArcs: (arcs: AgencyArc[]) => void
}

export const AddArc = (props: AddArcProps) => {
    const { agencyId, setArcs, existingArcs } = props

    const [arc, setArc] = useState('')

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const params = {
            agencyId,
            arc
        }

        createAgencyArc(params)
            .then(json => {
                setArcs([
                    ...existingArcs,
                    new AgencyArc(json.data)
                ])
                toast.success('Agency ARC Added')
            })
            .catch(error => {
                const { response } = error

                if (response.data.message) {
                    toast.error(response.data.message, { autoClose: false })
                }
            })
    }

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            <label htmlFor='createArcInput'>ARC</label>
            <input
                className='form-control'
                id='createArcInput'
                minLength={8}
                maxLength={8}
                type='text'
                onChange={(e) => setArc(e.target.value)}
                required
                value={arc} />
            <div className='row mt-3'>
                <div className='col-12 d-flex justify-content-center'>
                    <button className='btn btn-primary' type='submit'>
                        Submit
                    </button>
                </div>
            </div>
        </form>
    )
}
