import React, { useEffect, useRef, useState } from 'react'

import { getCountries, getStates } from '../../api'
import { JsonState, State, JsonCountry, Country } from '../../models'

interface AdvancedSearchProps {
    setAdvancedSearch: (values: Record<string, any>) => void
}

export const AdvancedSearch: React.FC<AdvancedSearchProps> = ({ setAdvancedSearch }) => {
    const defaultState = {
        name: '',
        phone: '',
        street: '',
        city: '',
        zip: '',
        state: '',
        country: '',
    }

    const [localState, setLocalState] = useState(defaultState)

    const [states, setStates] = useState([])

    const [countries, setCountries] = useState([])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target
        setLocalState((prevState) => ({ ...prevState, [name]: value }))
    }

    type Timer = ReturnType<typeof setTimeout>
    const timeoutRef = useRef<Timer>()

    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }

        timeoutRef.current = setTimeout(() => {
            setAdvancedSearch(localState)
        }, 500)
    }, [JSON.stringify(localState)])

    useEffect(() => {
        getStates().then((json) =>
            setStates(json.data.map((state: JsonState) => new State(state)))
        )
        getCountries().then((json) =>
            setCountries(json.data.map((country: JsonCountry) => new Country(country)))
        )
    }, [])

    const handleClear = () => {
        setLocalState(defaultState)
        setAdvancedSearch({})
    }

    return (
        <>
            <div className="row mb-3">
                <div className="col-md-3">
                    <input
                        className="form-control"
                        name="name"
                        value={localState.name}
                        onChange={handleInputChange}
                        placeholder="Supplier Name"
                    />
                </div>
                <div className="col-md-3">
                    <input
                        className="form-control"
                        name="phone"
                        value={localState.phone}
                        onChange={handleInputChange}
                        placeholder="Phone #"
                    />
                </div>
                <div className="col-md-3">
                    <input
                        className="form-control"
                        name="street"
                        value={localState.street}
                        onChange={handleInputChange}
                        placeholder="Street"
                    />
                </div>
                <div className="col-md-3">
                    <input
                        className="form-control"
                        name="city"
                        value={localState.city}
                        onChange={handleInputChange}
                        placeholder="City"
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-3">
                    <input
                        className="form-control"
                        name="zip"
                        value={localState.zip}
                        onChange={handleInputChange}
                        placeholder="Zip Code"
                    />
                </div>
                <div className="col-md-3">
                    <select
                        className="form-select"
                        id="state"
                        value={localState.state}
                        onChange={(e) =>
                            setLocalState({
                                ...localState,
                                state: e.target.value,
                            })
                        }
                    >
                        <option value={''}>All State</option>
                        {states.map((state: State) => (
                            <option key={state.id} value={state.id}>
                                {state.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-3">
                    <select
                        className="form-select"
                        id="country"
                        value={localState.country}
                        onChange={(e) =>
                            setLocalState({
                                ...localState,
                                country: e.target.value,
                            })
                        }
                    >
                        <option value={''}>All Country</option>
                        {countries.map((country: Country) => (
                            <option key={country.id} value={country.id}>
                                {country.name}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-md-3">
                    <button type="button" className="btn btn-secondary" onClick={handleClear}>
                        Clear
                    </button>
                </div>
            </div>
        </>
    )
}
