import React, { FormEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { AgencyNote } from '../../models'
import { createAgencyNote, deleteAgencyNote } from '../../api'
import { getAuthUser } from '../../helpers'
import { NoteDiv, TooltipWrapper } from '../common'

import defineAbilityFor from './defineAbility'

interface NoteProps {
    agencyId: number
    agencyNotes: AgencyNote[]
}

export const AgencyNotes = (props: NoteProps) => {
    const { agencyId, agencyNotes } = props

    const [isAdding, setIsAdding] = useState(false)
    const [notes, setNotes] = useState(agencyNotes)
    const [note, setNote] = useState('')
    const [isImportant, setIsImportant] = useState(false)

    useEffect(() => {
        setNotes(agencyNotes)
    }, [agencyNotes])

    const handleDelete = (agencyNoteId: number) => {
        deleteAgencyNote(agencyNoteId)
            .then(json => {
                setNotes(notes.filter(note => note.id !== agencyNoteId))

                toast.success('Note Deleted')
            })
            .catch(error => toast.error(error.message, { autoClose: false }))
    }

    const handleCancel = () => {
        setNote('')
        setIsAdding(false)
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()

        const params = {
            agencyId,
            note,
            important: isImportant,
        }

        createAgencyNote(params)
            .then(json => {
                setNotes([
                    ...notes,
                    new AgencyNote(json.data)
                ])
                setNote('')
                setIsAdding(false)
                setIsImportant(false)

                toast.success('Note Created')
            })
            .catch(error => toast.error(error.message, { autoClose: false }))
    }

    const ability = defineAbilityFor(getAuthUser())

    const importantNotes = notes.filter((note) => note.important)

    return (
        <>
            <div className='d-flex justify-content-between mb-3'>
                <h3>Notes</h3>
            </div>
            <form className='mb-3' onSubmit={(e) => handleSubmit(e)}>
                <textarea
                    className='form-control mb-3'
                    onChange={(e) => setNote(e.target.value)}
                    onFocus={() => setIsAdding(true)}
                    placeholder='Add a note'
                    required
                    value={note} />
                {isAdding &&
                    <>
                        <button
                            className='btn btn-primary me-2'
                            type='submit'>
                            Submit
                        </button>
                        <button
                            className='btn btn-outline-secondary'
                            onClick={handleCancel}>
                            Cancel
                        </button>
                        <div className='form-check mb-3'>
                            <input
                                className='form-check-input'
                                type='checkbox'
                                checked={isImportant}
                                onChange={(e) => setIsImportant(e.target.checked)}
                                id='importantCheck' />
                            <label className='form-check-label' htmlFor='importantCheck'>
                                Mark as Important
                            </label>
                        </div>
                    </>
                }
            </form>
            {importantNotes.length > 0 && (
                <>
                    <h4 className='border-bottom'>Important Notes</h4>
                    {importantNotes.map((note) => (
                        <div className='card mb-3 border border-danger' key={'important_' + note.id}>
                            <div className='card-header'>
                                <strong className='me-4'>{note.createdBy}</strong>
                                {note.created_at_local.format('MMM D, YYYY [at] h:mm A')}
                                <TooltipWrapper
                                    condition={!ability.can('Delete', 'AgencyNote')}
                                    element={
                                        <button
                                            className='btn btn-sm btn-outline-danger float-end'
                                            disabled={!ability.can('Delete', 'AgencyNote')}
                                            onClick={() => handleDelete(note.id)}>
                                            Delete
                                        </button>
                                    }
                                    message={'Executive permissions required'} />
                            </div>
                            <NoteDiv className='card-body'>{note.note}</NoteDiv>
                        </div>
                    ))}
                </>
            )}

            {notes.length > 0 && (
                <>
                    <h4 className='border-bottom'>All Notes</h4>
                    {[...notes]
                        .sort((a, b) => {
                            if (b.created_at.isAfter(a.created_at)) {
                                return 1
                            }

                            if (a.created_at.isAfter(b.created_at)) {
                                return -1
                            }

                            return 0
                        })
                        .map(note => (
                            <div className={`card mb-3 ${note.important ? 'border border-danger' : ''}`} key={`note_card_${note.id}`}>
                                <div className='card-header'>
                                    <strong className='me-4'>
                                        {note.createdBy}
                                    </strong>
                                    {note.created_at_local.format('MMM D, YYYY [at] h:mm A')}
                                    <TooltipWrapper
                                        condition={!ability.can('Delete', 'AgencyNote')}
                                        element={
                                            <button
                                                className='btn btn-sm btn-outline-danger float-end'
                                                disabled={!ability.can('Delete', 'AgencyNote')}
                                                onClick={() => handleDelete(note.id)}>
                                                Delete
                                            </button>
                                        }
                                        message={'Executive permissions required'} />
                                </div>
                                <NoteDiv className='card-body'>
                                    {note.note}
                                </NoteDiv>
                            </div>
                        ))}
                </>
            )}
        </>
    )
}
