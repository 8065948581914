import { JsonModel, Model } from './model'

export class RecordSubstatus extends Model {
    public static REFUSED_TO_PAY = 1;
    public static NET_RATE = 2;
    public static DISCOUNTED_RATE = 3;
    public static ONLINE_BOOKING = 4;
    public static ONYX = 5;
    public static THIRD_PARTY_BOOKING = 6;
    public static EXPEDIA = 7;
    public static AGENCY = 8;
    public static CTS = 9;
    public static CORPORATE_PAID = 10;
    public static COMMTRAK = 11;
    public static REFUSED_TO_RESEARCH = 12;
    public static INCORRECT_REC_DETAILS = 13;
    public static EXCEEDS_WINDOW = 14;

    code: number
    name: string

    constructor(json: JsonRecordSubstatus) {
        super(json)

        this.code = json.code
        this.name = json.name
    }
}

export interface JsonRecordSubstatus extends JsonModel {
    code: number
    name: string
}
