import React from 'react'
import { Link } from 'react-router-dom'

import { Chain } from '../../models'
import { PaginatedTableContract } from '../common'

interface ChainsTableProps extends PaginatedTableContract {
    resources: {
        chains: Chain[]
    }
}

export const ChainsTable = (props: ChainsTableProps) => {
    const { resources } = props
    const { chains } = resources

    return (
        <table className='table'>
            <tbody>
                <tr>
                    <th>Name</th>
                    <th>Chain Code</th>
                    <th>Code</th>
                </tr>
                {chains.map(chain =>
                    <tr key={chain.id}>
                        <td>
                            <Link to={`/chains/${chain.id}`}>
                                {chain.name}
                            </Link>
                        </td>
                        <td>{chain.abbreviation}</td>
                        <td>{chain.code}</td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}
