import React from 'react'

import { PaginatedTable, PaginatedTableCallbacks } from '../common'
import { ChainsTable } from './collectionTable'
import { JsonChain, Chain } from '../../models'
import { getChains } from '../../api'
import { getAuthUser } from '../../helpers';
import defineAbilityFor from './defineAbility'
import { InsufficientPermissions } from '../common';

export const ChainsLanding = () => {
    const ability = defineAbilityFor(getAuthUser())

    if (!ability.can('View', 'Chain')) {
        return <InsufficientPermissions />
    }

    return (
        <PaginatedTable<Chain, JsonChain>
            getData={getChains}
            redirectLink={{
                to: '/chains/add',
                text: 'Create New Chain'
            }}
            modelConstructor={(json: JsonChain) => new Chain(json)}
            searchPlaceholder='Search by Name or Chain Code'
            Table={(chains: Chain[], callbacks: PaginatedTableCallbacks) => (
                <ChainsTable
                    callbacks={callbacks}
                    resources={{ chains }} />
            )}
            title='Chains' />
    )
}
