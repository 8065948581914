import React, { FormEvent, useEffect, useState } from 'react'

import {
    AgencyStatus,
    Country,
    State,
    USA,
    JsonAgencyStatus,
    JsonCountry,
    JsonState,
    JsonPaymentMethod,
    PaymentMethod
} from '../../models'
import {
    getStates,
    getCountries,
    getAgencyPaymentMethods,
    getAgencyStatuses,
    CreateAgencyParams,
    getAgencySoftwareTypes
} from '../../api'
import { getAuthUser } from '../../helpers'
import defineAbilityFor from './defineAbility'
import { AddressInputUpdates } from '../common';

interface CreateFormProps {
    handleSubmit: (
        e: FormEvent<HTMLFormElement>,
        inputs: CreateAgencyParams
    ) => void
    isSubmitting?: boolean
}

export const AgencyCreateForm = (props: CreateFormProps) => {
    const { handleSubmit, isSubmitting } = props

    const defaultInputs = {
        address: {
            city: '',
            countryId: USA,
            stateId: '',
            street: '',
            street2: '',
            zip: ''
        },
        branch: '',
        firstName: '',
        lastName: '',
        ein: '',
        email: '',
        phone_number: '',
        name: '',
        status: AgencyStatus.ACTIVE,
        paymentMethod: '',
        softwareType: ''
    }

    const [agencyStatuses, setAgencyStatuses] = useState([])
    const [inputs, setInputs] = useState(defaultInputs)
    const [paymentMethods, setPaymentMethods] = useState([])
    const [countries, setCountries] = useState<Country[]>([])
    const [country, setCountry] = useState<Country | undefined>()
    const [states, setStates] = useState([])
    const [softwareTypes, setSoftwareTypes] = useState([])

    const ability = defineAbilityFor(getAuthUser())

    useEffect(() => {
        getAgencyStatuses()
            .then(json => setAgencyStatuses(
                json.data.map((status: JsonAgencyStatus) => new AgencyStatus(status))
            ))

        getAgencyPaymentMethods().then(json => {
            setPaymentMethods(json.data.map(
                (paymentMethod: JsonPaymentMethod) => new PaymentMethod(paymentMethod))
            )
        })

        getCountries().then(json => {
            setCountries(json.data.map(
                (country: JsonCountry) => new Country(country))
            )
        })

        getStates().then(json => {
            setStates(json.data.map(
                (state: JsonState) => new State(state))
            )
        })

        getAgencySoftwareTypes().then(json => {
            setSoftwareTypes(json.data.map(
                (softwareType: any) => ({ id: softwareType.id, name: softwareType.name }))
            )
        })
    }, [])

    useEffect(() => {
        const countryId = inputs.address.countryId ?? USA

        if (!country) {
            setCountry(countries.find(country => country.id === countryId))
        }
    }, [countries.length])

    const updateInputs = (field: string, value: number | string) => {
        setInputs({
            ...inputs,
            [field]: value
        })
    }

    const updateAddressInputs = (addressUpdates: Array<AddressInputUpdates>) => {
        let updated = {
            ...inputs.address
        }

        addressUpdates.forEach(update => {
            updated = {
                ...updated,
                [update.field]: update.value
            }
        })

        setInputs({
            ...inputs,
            address: {
                ...updated
            }
        })
    }

    return (
        <form onSubmit={(e) => handleSubmit(e, inputs)}>
            <div className="row mb-4">
                <div className="col-12">
                    <label htmlFor="statusInput">Status</label>
                    <select
                        className="form-select"
                        disabled={!ability.can('Edit', 'AgencyStatus')}
                        id="statusInput"
                        onChange={(e) => updateInputs('status', +e.target.value)}
                        value={inputs.status}
                    >
                        {agencyStatuses.map((status: AgencyStatus) => (
                            <option key={status.code} value={status.code}>
                                {status.name}
                            </option>
                        ))}
                    </select>
                    <label htmlFor="nameInput">Name</label>
                    <input
                        className="form-control"
                        id="nameInput"
                        onChange={(e) => updateInputs('name', e.target.value)}
                        type="text"
                        required
                        value={inputs.name}
                    />
                    <label htmlFor="einInput">EIN</label>
                    <input
                        className="form-control"
                        id="einInput"
                        onChange={(e) => updateInputs('ein', e.target.value)}
                        maxLength={10}
                        type="text"
                        required
                        value={inputs.ein}
                    />
                    <label htmlFor="phoneInput">Phone</label>
                    <input
                        className="form-control"
                        id="phoneInput"
                        onChange={(e) => updateInputs('phone_number', e.target.value)}
                        type="text"
                        required
                        value={inputs.phone_number}
                    />
                    <label htmlFor="branchInput">Branch</label>
                    <input
                        className="form-control"
                        id="branchInput"
                        onChange={(e) => updateInputs('branch', e.target.value)}
                        maxLength={10}
                        type="text"
                        value={inputs.branch}
                    />
                    <label htmlFor="firstNameInput">First Name</label>
                    <input
                        className="form-control"
                        id="firstNameInput"
                        onChange={(e) => updateInputs('firstName', e.target.value)}
                        type="text"
                        value={inputs.firstName}
                    />
                    <label htmlFor="lastNameInput">Last Name</label>
                    <input
                        className="form-control"
                        id="lastNameInput"
                        onChange={(e) => updateInputs('lastName', e.target.value)}
                        type="text"
                        value={inputs.lastName}
                    />
                    <label htmlFor="streetInput">Street</label>
                    <input
                        className="form-control"
                        id="streetInput"
                        onChange={(e) =>
                            updateAddressInputs([
                                {
                                    field: 'street',
                                    value: e.target.value,
                                },
                            ])
                        }
                        type="text"
                        required
                        value={inputs.address.street}
                    />
                    <label htmlFor="street2Input">Street 2</label>
                    <input
                        className="form-control"
                        id="street2Input"
                        onChange={(e) =>
                            updateAddressInputs([
                                {
                                    field: 'street2',
                                    value: e.target.value,
                                },
                            ])
                        }
                        type="text"
                        value={inputs.address.street2}
                    />
                    <label htmlFor="faxInput">City</label>
                    <input
                        className="form-control"
                        id="cityInput"
                        onChange={(e) =>
                            updateAddressInputs([
                                {
                                    field: 'city',
                                    value: e.target.value,
                                },
                            ])
                        }
                        type="text"
                        required
                        value={inputs.address.city}
                    />
                    <label htmlFor="zipInput">Zip</label>
                    <input
                        className="form-control"
                        id="zipInput"
                        onChange={(e) =>
                            updateAddressInputs([
                                {
                                    field: 'zip',
                                    value: e.target.value,
                                },
                            ])
                        }
                        type="text"
                        required
                        value={inputs.address.zip}
                    />
                    <label htmlFor="countryInput">Country</label>
                    <select
                        className="form-select"
                        id="countryInput"
                        onChange={(e) => {
                            updateAddressInputs([
                                {
                                    field: 'countryId',
                                    value: e.target.value,
                                },
                                {
                                    field: 'stateId',
                                    value: '',
                                },
                            ])
                            setCountry(
                                countries.find(
                                    (country: Country) => country.id === +e.target.value
                                )
                            )
                        }}
                        value={inputs.address.countryId}
                    >
                        {countries.map((country: Country) => (
                            <option key={`${country.name}_${country.id}`} value={country.id}>
                                {country.name}
                            </option>
                        ))}
                    </select>
                    <label htmlFor="stateInput">State</label>
                    <select
                        className="form-select"
                        id="stateInput"
                        onChange={(e) =>
                            updateAddressInputs([
                                {
                                    field: 'stateId',
                                    value: e.target.value,
                                },
                            ])
                        }
                        value={inputs.address.stateId}
                    >
                        <option value="">(Empty)</option>
                        {states
                            .filter((state: State) => state.countryId === country?.id)
                            .map((state: State) => (
                                <option key={`${state.name}_${state.id}`} value={state.id}>
                                    {state.name}
                                </option>
                            ))}
                    </select>
                    <label htmlFor="emailInput">Email</label>
                    <input
                        className="form-control"
                        id="emailInput"
                        onChange={(e) => updateInputs('email', e.target.value)}
                        type="email"
                        required
                        value={inputs.email}
                    />
                    <label htmlFor="paymentMethodInput">Preferred Payment Method</label>
                    <select
                        className="form-select"
                        id="paymentMethodInput"
                        onChange={(e) => updateInputs('paymentMethod', e.target.value)}
                        value={inputs.paymentMethod}
                    >
                        <option value="">Unknown</option>
                        {paymentMethods.map((paymentMethod: PaymentMethod) => (
                            <option
                                key={`payment_method_${paymentMethod.id}`}
                                value={paymentMethod.id}
                            >
                                {paymentMethod.name}
                            </option>
                        ))}
                    </select>
                    <label htmlFor="softwareTypeInput">Booking Software</label>
                    <select
                        className="form-select"
                        id="softwareTypeInput"
                        onChange={(e) => updateInputs('softwareType', +e.target.value)}
                        value={inputs.softwareType}
                    >
                        <option value="">Unknown</option>
                        {softwareTypes.map((softwareType: any) => (
                            <option key={softwareType.id} value={softwareType.id}>
                                {softwareType.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-12 d-flex justify-content-center">
                    <button
                        className="btn btn-primary"
                        disabled={!!isSubmitting}
                        type="submit"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </form>
    )
}
