import { defineAbility } from '@casl/ability'

import { User } from '../../models'
import { Roles, userHasRole } from '../../helpers'

export default (user: User) => defineAbility((can) => {
    if (userHasRole(user, Roles.Executive)) {
        can('Create', 'Agency')
        can('Create', 'AgencyArc')
        can('Edit', 'AgencyBookingSoftware')
    }
    if (userHasRole(user, Roles.SrLead)) {
        can('Edit', 'Agency')
        can('Upload', 'AgencyData')
        can('Edit', 'AgencyStatus')
        can('Delete', 'AgencyNote')
    }

    if (userHasRole(user, Roles.Standard)) {
        can('View', 'Agency')
    }

    if (userHasRole(user, Roles.Accounting)) {
        can('Create', 'AgencyPayment')
    }
})
