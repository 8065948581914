import React, { FormEvent } from 'react'
import { toast } from 'react-toastify'

import { deleteAgencyArc } from '../../../api'
import { AgencyArc } from '../../../models'

interface DeleteArcProps {
    agencyArc: AgencyArc
    existingArcs: AgencyArc[]
    cancel: () => void
    setArcs: (arcs: AgencyArc[]) => void
}

export const DeleteArc = (props: DeleteArcProps) => {
    const { agencyArc, cancel, existingArcs, setArcs } = props

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        deleteAgencyArc(agencyArc.id)
            .then(json => {
                setArcs([
                    ...existingArcs.filter(arc => arc.id !== agencyArc.id)
                ])
                toast.success('Agency ARC Deleted')
            })
            .catch(error => {
                const { response } = error

                if (response.data.message) {
                    toast.error(response.data.message, { autoClose: false })
                }
            })
    }

    return (
        <form className='input-group' onSubmit={(e) => handleSubmit(e)}>
            <input
                className='form-control'
                readOnly={true}
                type='text'
                required
                value={agencyArc.arc} />
            <button className='btn btn-danger' type='submit'>
                Confirm Delete
            </button>
            <button className='btn btn-outline-secondary ms-4' onClick={() => cancel()}>
                Cancel
            </button>
        </form>
    )
}
