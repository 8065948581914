import React from 'react'

interface AddressProps {
    city: string
    country: string
    state: string
    street: string
    street2?: string
    zip: string
}

export const FormattedAddress = (props: AddressProps) => {
    const {
        city,
        country,
        state,
        street,
        street2,
        zip
    } = props

    if (!street && !city && !state && !zip) {
        return <div />
    }

    return (
        <div>
            {street} <br />
            {street2 && <>{street2} <br /></>}
            {city}, {state} {zip} <br />
            {country}
        </div>
    )
}
