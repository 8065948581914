import { defineAbility } from '@casl/ability'

import { User } from '../../../models'
import { Roles, userHasRole } from '../../../helpers'

export default (user: User) => defineAbility((can) => {
    if (userHasRole(user, Roles.Standard)) {
        can('Create', 'AgencyAddress') // combined create address and syncAddresses
        can('Edit', 'AgencyAddress') // combined create address and syncAddresses
        can('Delete', 'AgencyAddress')
        can('Update', 'AgencyAddress') // set as payment/physical
    }
})
