import React, { Dispatch, SetStateAction } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

import { deleteSupplier } from '../../api'
import { TooltipWrapper } from '../common'
import { Supplier } from '../../models'
import { getAuthUser } from '../../helpers';
import defineAbilityFor from './defineAbility';

interface DeleteSupplierProps {
    setIsDeleting: Dispatch<SetStateAction<boolean>>
    supplier: Supplier
}

export const DeleteSupplier = (props: DeleteSupplierProps) => {
    const { setIsDeleting, supplier } = props

    const ability = defineAbilityFor(getAuthUser())
    const navigate = useNavigate()

    const getRecordWarning = () => {
        const alertClass = supplier.recordCount > 0 ? 'alert-danger' : 'alert-success'

        return (
            <div className={`alert ${alertClass}`}>
                {supplier.recordCount} Records will be moved to the unknown supplier report
            </div>
        )
    }

    const handleDelete = () => {
        deleteSupplier(supplier.id.toString())
            .then((response) => {
                toast.success(`${supplier.name} Deleted`)
                navigate('/suppliers')
            })
            .catch((error) => {
                const { response } = error

                if (response?.data) {
                    toast.error(response.data, { autoClose: false })
                }
            })
    }

    return (
        <div className='container card'>
            <div className='card-body col-xs-12 row d-flex flex-row justify-content-between mb-5'>
                <div className='row flex-row'>
                    <h3 className='ps-0 d-inline-flex card-title w-50'>
                        {supplier.name}
                    </h3>
                    <div className='pe-0 d-inline-flex justify-content-end w-50 align-items-center'>
                        <TooltipWrapper
                            condition={!ability.can('Delete', 'Supplier')}
                            element={
                                <button
                                    className='btn btn-danger me-3'
                                    disabled={!ability.can('Delete', 'Supplier')}
                                    onClick={() => handleDelete()}>
                                    Confirm Delete
                                </button>
                            }
                            message='Executive permission required to delete supplier' />
                        <button
                            className='btn btn-secondary'
                            onClick={() => setIsDeleting(false)}>
                            Cancel
                        </button>
                    </div>
                </div>
                {getRecordWarning()}
            </div>
        </div>
    )
}
