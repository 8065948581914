import React, { useEffect, useState } from 'react'

import { getPaymentMethods, getRecordStatuses } from '../../api'
import { JsonPaymentMethod, JsonRecordStatus, PaymentMethod, RecordStatus } from '../../models'

interface FilterProps {
    updateFilters: (filterName: string, newValue: string, shouldDelete?: boolean) => void
}

export const PaymentFilters = (props: FilterProps) => {
    const { updateFilters } = props

    const [paymentMethods, setPaymentMethods] = useState([])
    const [recordStatuses, setRecordStatuses] = useState([])
    const [dateFrom, setDateFrom] = useState('')
    const [dateTo, setDateTo] = useState('')

    useEffect(() => {
        getPaymentMethods()
            .then(json => setPaymentMethods(
                json.data.map((method: JsonPaymentMethod) => new PaymentMethod(method))
            ))
    }, [])

    useEffect(() => {
        getRecordStatuses()
            .then(json => setRecordStatuses(
                json.data.map((status: JsonRecordStatus) => new RecordStatus(status))
            ))
    }, [])

    return (
        <>
            <label htmlFor='paymentMethodFilter'>Payment Method</label>
            <select
                className='form-select'
                id='paymentMethodFilter'
                onChange={(e) => updateFilters(
                    'payment_method_id',
                    e.target.value === '-1' ? '' : e.target.value
                )}>
                <option value={-1}>
                    All
                </option>
                <option value='none'>
                    No Payment Method
                </option>
                {paymentMethods.map((method: PaymentMethod) => (
                    <option key={method.id} value={method.id}>
                        {method.name}
                    </option>
                ))}
            </select>
            <label htmlFor='recordStatusFilter'>Record Status</label>
            <select
                className='form-select'
                id='recordStatusFilter'
                onChange={(e) => updateFilters(
                    'record_status_code',
                    e.target.value ?? '',
                    e.target.value === '-1'
                )}>
                <option value={-1}>
                    All
                </option>
                {recordStatuses.map((status: RecordStatus) => (
                    <option key={status.code} value={status.code}>
                        {status.name}
                    </option>
                ))}
            </select>
            <label className='mt-3' htmlFor='paymentDateFromFilter'>
                Payment Date From
            </label>
            <input
                className='form-control'
                id='paymentDateFromFilter'
                onChange={(e) => {
                    updateFilters(
                        'date_paid_from',
                        e.target.value ?? '',
                        e.target.value === ''
                    )

                    setDateFrom(e.target.value)
                }}
                type='date'
                max={dateTo} />
            <label htmlFor='paymentDateToFilter'>Payment Date To</label>
            <input
                className='form-control'
                id='paymentDateToFilter'
                onChange={(e) => {
                    updateFilters(
                        'date_paid_to',
                        e.target.value ?? '',
                        e.target.value === ''
                    )

                    setDateTo(e.target.value)
                }}
                type='date'
                min={dateFrom} />
        </>
    )
}
