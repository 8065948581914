import { JsonModel, Model } from './model'
import { JsonSupplierSubstatus, SupplierSubstatus } from './supplierSubstatus'

export class SupplierStatus extends Model {
    public static BILLABLE = 0
    public static NON_COMMISSIONABLE = 6
    public static BANKRUPTCY = 7
    public static TEMP_CLOSED = 22
    public static PERMANENTLY_CLOSED = 86
    public static BLACKLISTED = 87
    public static NEEDS_REVIEW = 88
    public static INVALID = 89
    public static COLLECTIONS = 92
    public static SENT_TO_ATTORNEY = 93

    code: number
    name: string
    substatuses?: SupplierSubstatus[]

    constructor(json: JsonSupplierStatus) {
        super(json)

        this.code = json.code
        this.name = json.name
        if (json.substatuses) {
            this.substatuses = json.substatuses.map((substatusJson: JsonSupplierSubstatus) => new SupplierSubstatus(substatusJson));
        }
    }
}

export interface JsonSupplierStatus extends JsonModel {
    code: number
    name: string
    substatuses?: JsonSupplierSubstatus[]
}
