import dayjs, { Dayjs } from 'dayjs'

export class Model {
    id: number
    created_at: Dayjs
    created_at_local: Dayjs
    updated_at: Dayjs
    updated_at_local: Dayjs

    constructor(json: JsonModel) {
        this.id = json.id
        this.created_at = dayjs.utc(json.created_at)
        this.created_at_local = dayjs(json.created_at)
        this.updated_at = dayjs.utc(json.updated_at)
        this.updated_at_local = dayjs(json.updated_at)
    }
}

export interface JsonModel {
    id: number
    created_at: string
    updated_at: string
}
