import React from 'react'

import { PaginatedTable, PaginatedTableCallbacks } from '../common'
import { PaymentFilters } from './filters'
import { PaymentsTable } from './table'
import { JsonRecord, Record } from '../../models'
import { getPaidRecords } from '../../api'
import { downloadPaymentsCsv } from '../../api/routes'
import { getAuthUser } from '../../helpers'
import { currencyFormat } from '../../helpers/currencyCalculation'
import defineAbilityFor from './defineAbility'
import { InsufficientPermissions } from '../common'

export const PaymentsReport = () => {
    const ability = defineAbilityFor(getAuthUser())

    if (!ability.can('View', 'PaymentReport')) {
        return <InsufficientPermissions />
    }

    return (
        <PaginatedTable<Record, JsonRecord>
            DownloadButton={({ params }) => (
                <a href={downloadPaymentsCsv + '?' + new URLSearchParams(params).toString()}
                    className="btn btn-primary">
                    Download CSV
                </a>
            )}
            getData={getPaidRecords}
            Filters={PaymentFilters}
            formatHeaderData={(total: string) => {
                return `Total: ${currencyFormat(+total)}`;
            }}
            modelConstructor={(json: JsonRecord) => new Record(json)}
            searchPlaceholder='Search by REC, Conf, Last Name, Phone, or Supplier Name'
            Table={(records: Record[], callbacks: PaginatedTableCallbacks) => (
                <PaymentsTable
                    callbacks={callbacks}
                    resources={{ records }} />
            )}
            title='Payments' />
    )
}
