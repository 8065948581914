import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getInvoice } from '../../../api'
import { Loading } from '../../common'
import { Invoice, PaymentMethod, JsonPaymentMethod, RecordStatus, JsonRecordStatus } from '../../../models'
import { InvoiceReconciliation } from './reconciliation'
import { getAuthUser } from '../../../helpers';
import defineAbilityFor from '../defineAbility'
import { InsufficientPermissions } from '../../common';
import { getPaymentMethods, getRecordStatuses} from '../../../api'

enum PageState {
    Default,
    Loading,
    Found,
    NotFound,
    Error
}

export const InvoiceReconciliationSearch = () => {
    const { invoiceId } = useParams()

    const [invoice, setInvoice] = useState<Invoice>()
    const [pageState, setPageState] = useState(PageState.Default)

    const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])
    const [recordStatuses, setRecordStatuses] = useState<RecordStatus[]>([])

    // is appended to the invoice id to create a key that ensures the Reconciliation component's state is reset whenever a new invoice is loaded
    const [resetNonce, setResetNonce] = useState(0);

    const ability = defineAbilityFor(getAuthUser())

    useEffect(() => {
        if (invoiceId && ability.can('View', 'Invoice')) {
            setPageState(PageState.Loading)

            Promise.all([
                getInvoice(invoiceId).then((response) => {
                    const newInvoice = new Invoice(response.data.invoice, response.data.records);
                    setInvoice(newInvoice)
                    setResetNonce(0);
                }),
                getPaymentMethods().then(
                    (response) => setPaymentMethods(response.data.map(
                        (method: JsonPaymentMethod) => new PaymentMethod(method)))
                ),
                getRecordStatuses().then(response => {
                    setRecordStatuses(response.data.map(
                        (status: JsonRecordStatus) => new RecordStatus(status))
                    )
                })
            ]).then(() => {
                setPageState(PageState.Found)
            }).catch(error => {
                setPageState(PageState.Error)

                const { response } = error

                if (response?.status === 404) {
                    setPageState(PageState.NotFound)
                }
            })
        }
    }, [invoiceId])

    if (!ability.can('View', 'Invoice')) {
        return <InsufficientPermissions />
    }

    const onInvoiceUpdated = (newInvoice: Invoice) => {
        setInvoice(newInvoice);
        setResetNonce(resetNonce + 1);
    };

    switch (pageState) {
        case PageState.Default:
        case PageState.NotFound:
            return (
                <div className='container'>
                    <h3>Invoice Not Found</h3>
                </div>
            )
        case PageState.Found:
            if (invoice) {
                return <InvoiceReconciliation key={`${invoice.id}-${resetNonce}`} invoice={invoice} onInvoiceUpdated={onInvoiceUpdated} paymentMethods={paymentMethods} recordStatuses={recordStatuses}/>
            }
        case PageState.Loading:
        default:
            return (
                <div className='container'>
                    <Loading />
                </div>
            )
    }
}
