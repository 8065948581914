import React, {
    Dispatch,
    FormEvent,
    SetStateAction
} from 'react'
import { toast } from 'react-toastify'

import { getAuthUser } from '../../helpers';
import { updateCountry, UpdateCountryParams } from '../../api'
import { Country } from '../../models'
import { CountryForm } from './form'
import defineAbilityFor from './defineAbility'
import { InsufficientPermissions } from '../common';

interface EditCountryProps {
    country: Country
    setIsEditing: Dispatch<SetStateAction<boolean>>
    setCountry: Dispatch<SetStateAction<Country | undefined>>
}

export const EditCountryForm = (props: EditCountryProps) => {
    const { country, setIsEditing, setCountry } = props

    const ability = defineAbilityFor(getAuthUser())

    const handleSubmit = (e: FormEvent<HTMLFormElement>, inputs: UpdateCountryParams) => {
        e.preventDefault()

        updateCountry(country.id.toString(), inputs)
            .then(json => {
                setCountry(new Country(json.data))
                setIsEditing(false)
                toast.success('Country Edited')
            })
            .catch(error => {
                const { response } = error

                if (response.data.message) {
                    toast.error(response.data.message, { autoClose: false })
                }

                if (response.status === 400) {
                    toast.error(response.data, { autoClose: false })
                }
            })
    }

    if (!ability.can('Edit', 'Country')) {
        return <InsufficientPermissions />
    }

    return (
        <CountryForm
            handleSubmit={handleSubmit}
            country={country} />
    )
}
