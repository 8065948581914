import React from 'react'

interface TooltipProps {
    condition: boolean
    element: JSX.Element
    message: string
}

export const TooltipWrapper = (props: TooltipProps) => {
    const { condition, element, message } = props

    if (condition) {
        return (
            <span data-bs-toggle='tooltip' title={message}>
                {element}
            </span>
        )
    }

    return element
}
