import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getAuthUser } from '../../helpers';
import { Loading, TooltipWrapper } from '../common'
import { Country } from '../../models'
import { getCountry } from '../../api'
import { EditCountryForm } from './edit'
import { CountryTable } from './resourceTable'
import defineAbilityFor from './defineAbility'
import { InsufficientPermissions } from '../common';

export const CountryCard = () => {
    const [country, setCountry] = useState<Country>()
    const [isEditing, setIsEditing] = useState(false)
    const params = useParams()
    const ability = defineAbilityFor(getAuthUser())

    useEffect(() => {
        if (params.countryId && ability.can('View', 'Country')) {
            getCountry(params.countryId)
                .then(response => {
                    if (response.status === 200) {
                        setCountry(new Country(response.data))
                    }
                })
        }
    }, [params.countryId])

    if (!ability.can('View', 'Country')) {
        return <InsufficientPermissions />
    }

    if (!(country instanceof Country)) {
        return <Loading />
    }

    return (
        <div className='container card col-xs-12 col-xl-6'>
            <div className='card-body col-xs-12 row d-flex flex-row justify-content-between mb-5'>
                <div className='row flex-row mb-5'>
                    <h3 className='ps-0 d-inline-flex card-title w-50'>
                        {country.name}
                    </h3>
                    <div className='pe-0 d-inline-flex justify-content-end w-50'>
                        <TooltipWrapper
                            condition={!ability.can('Edit', 'Country')}
                            element={
                                <button
                                    className='btn btn-primary'
                                    disabled={!ability.can('Edit', 'Country')}
                                    onClick={() => setIsEditing(!isEditing)}>
                                    Toggle Edit
                                </button>
                            }
                            message='Executive permission required to edit country' />
                    </div>
                </div>
                {!isEditing && <CountryTable country={country} />}
                {isEditing && (
                    <EditCountryForm
                        country={country}
                        setIsEditing={setIsEditing}
                        setCountry={setCountry} />
                )}
            </div>
        </div>
    )
}
