import React from 'react'
import { Link } from 'react-router-dom'
import { getAuthUser } from '../../helpers'
import defineAbilityFor from '../quickbooks/defineAbility'

export const Navbar = () => {
    const csrfToken = document.querySelector("meta[name='csrf-token']")?.getAttribute('content') ?? ''

    const buttonLinkStyle = {
        textDecoration: 'none',
        color: 'rgba(0, 0, 0, 0.55)'
    }

    const quickbooksAbility = defineAbilityFor(getAuthUser())

    return (
        <nav className='navbar navbar-expand-md navbar-light shadow-sm mb-5 navbar-bg'>
            <div className='container'>
                <a className='navbar-brand' href='/'>
                    Commtrak
                </a>
                <button
                    className='navbar-toggler'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#navbarSupportedContent'
                    aria-controls='navbarSupportedContent'
                    aria-expanded='false'
                    aria-label='Toggle navigation'>
                    <span className='navbar-toggler-icon'></span>
                </button>

                <div className='collapse navbar-collapse' id='navbarSupportedContent'>
                    <ul className='navbar-nav me-auto'>
                        <li className='nav-item dropdown'>
                            <a
                                className='nav-link dropdown-toggle'
                                href='#'
                                id='recordsNavbarDropdown'
                                role='button'
                                data-bs-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'>
                                Records
                            </a>
                            <div className='dropdown-menu' aria-labelledby='recordsNavbarDropdown'>
                                <Link className='nav-link' to='/records'>Records</Link>
                                <Link className='nav-link' to='/records/unknownSupplier'>Unknown Suppliers</Link>
                                <Link className='nav-link' to='/records/delete'>Delete Records</Link>
                                <Link className='nav-link' to='/records/foreignConversion'>Foreign Conversion</Link>
                                <Link className='nav-link' to='/records/invalidSupplier'>Invalid Suppliers</Link>
                                <Link className='nav-link' to='/records/assignedToMe'>Assigned to Me</Link>
                            </div>
                        </li>
                        <li className='nav-item dropdown'>
                            <a
                                className='nav-link dropdown-toggle'
                                href='#'
                                id='paymentsNavbarDropdown'
                                role='button'
                                data-bs-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'>
                                Payments
                            </a>
                            <div className='dropdown-menu' aria-labelledby='paymentsNavbarDropdown'>
                                <Link className='nav-link' to='payments'>Payments</Link>
                                <Link className='nav-link' to='/daily-payments'>Daily Payments</Link>
                            </div>
                        </li>
                        <li className='nav-item dropdown'>
                            <a
                                className='nav-link dropdown-toggle'
                                href='#'
                                id='invoicesNavbarDropdown'
                                role='button'
                                data-bs-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'>
                                Invoices
                            </a>
                            <div className='dropdown-menu' aria-labelledby='invoicesNavbarDropdown'>
                                <Link className='nav-link' to='/invoices'>Invoices</Link>
                                <Link className='nav-link' to='/invoices/generate'>Generate</Link>
                            </div>
                        </li>
                        <li className='nav-item dropdown'>
                            <a
                                className='nav-link dropdown-toggle'
                                href='#'
                                id='agenciesNavbarDropdown'
                                role='button'
                                data-bs-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'>
                                Agencies
                            </a>
                            <div className='dropdown-menu' aria-labelledby='agenciesNavbarDropdown'>
                                <Link className='nav-link' to='/agencies'>
                                    Agencies
                                </Link>
                                <Link className='nav-link' to='/agencies/dataUpload'>
                                    Data Upload
                                </Link>
                                <Link className='nav-link' to='/agencies/ctsUpload'>
                                    CTS Data Upload
                                </Link>
                                <Link className='nav-link' to='/agencies/vtsArcUpload'>
                                    VTS ARC Data Upload
                                </Link>
                                <Link className='nav-link' to='/agencies/unknownSupplier'>
                                    Unknown Supplier
                                </Link>
                            </div>
                        </li>
                        <li className='nav-item dropdown'>
                            <a
                                className='nav-link dropdown-toggle'
                                href='#'
                                id='suppliersNavbarDropdown'
                                role='button'
                                data-bs-toggle='dropdown'
                                aria-haspopup='true'
                                aria-expanded='false'>
                                Suppliers
                            </a>
                            <div className='dropdown-menu' aria-labelledby='suppliersNavbarDropdown'>
                                <Link className='nav-link' to='/suppliers'>
                                    Suppliers
                                </Link>
                                <Link className='nav-link' to='/suppliers/balances'>
                                    Supplier Balances
                                </Link>
                                <Link className='nav-link' to='/suppliers/collections'>
                                    Supplier Collections
                                </Link>
                                <Link className='nav-link' to='/suppliers/dataUpload'>
                                    Data Upload
                                </Link>
                                <Link className='nav-link' to='/suppliers/pendingPayment'>
                                    Pending Payment
                                </Link>
                            </div>
                        </li>
                        <li className='nav-item'>
                            <Link className='nav-link' to='/chains'>Chains</Link>
                        </li>
                        <li className='nav-item'>
                            <Link className='nav-link' to='/countries'>Countries</Link>
                        </li>
                        <li className='nav-item'>
                            <Link className='nav-link' to='/users'>Users</Link>
                        </li>
                        <li className='nav-item'>
                            <Link className='nav-link' to='/agency-reconciliation-reports'>Reports</Link>
                        </li>
                        <li className='nav-item'>
                            <Link className='nav-link' to='/vendors'>Vendors</Link>
                        </li>
                        {quickbooksAbility.can('View', 'QuickbooksOauth') && (
                            <li className='nav-item'>
                                <Link className='nav-link' to='/quickbooks'>Quickbooks</Link>
                            </li>
                        )}
                    </ul>

                    <ul className='navbar-nav ms-auto'>
                        <li className='nav-item'>
                            <form action='/logout' method='POST'>
                                <input type='hidden' name='_token' value={csrfToken} />
                                <button
                                    className='btn btn-link'
                                    type='submit'
                                    style={buttonLinkStyle}>
                                    Logout
                                </button>
                            </form>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}
