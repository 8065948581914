import { defineAbility } from '@casl/ability'

import { User } from '../../models'
import { Roles, userHasRole } from '../../helpers'

export default (user: User) => defineAbility((can) => {
    if (userHasRole(user, Roles.Lead)) {
        can('Create', 'Chain')
    }

    if (userHasRole(user, Roles.Executive)) {
        can('Edit', 'Chain')
    }

    if (userHasRole(user, Roles.Standard)) {
        can('View', 'Chain')
    }
})
