import { JsonModel, Model } from './model'

export class SupplierCallback extends Model {
    scheduledTime: string
    reason: string
    assignedTo: number
    createdBy: number
    supplierId: string
    createdAt: string
    updatedAt: string

    constructor(json: JsonSupplierCallback) {
        super(json)

        this.scheduledTime = json.scheduled_time
        this.reason = json.reason
        this.supplierId = json.supplier_id.toString()
        this.createdAt = json.created_at
        this.updatedAt = json.updated_at
        this.assignedTo = json.assigned_to
        this.createdBy = json.created_by
    }
}

export interface JsonSupplierCallback extends JsonModel {
    scheduled_time: string
    reason: string
    assigned_to: number
    created_by: number
    supplier_id: number
    created_at: string
    updated_at: string
}
