import React from 'react'
import { useNavigate } from 'react-router-dom'

import { User } from '../../models'
import { PaginatedTableContract } from '../common'

interface UsersTableProps extends PaginatedTableContract {
    resources: {
        users: User[]
    }
}

export const UsersTable = (props: UsersTableProps) => {
    const { resources } = props
    const { users } = resources

    const navigate = useNavigate()

    // Filter out inactive users
    const activeUsers = users.filter(user => user.is_active)

    return (
        <table className='table table-hover'>
            <tbody>
                <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Records Assigned</th>
                    <th>Suppliers Assigned</th>
                </tr>
                {activeUsers.map(user =>
                    <tr key={user.id} onClick={() => navigate(`/users/${user.id}`)}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.assignedRecordCount}</td>
                        <td>{user.assignedSupplierCount}</td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}
