import React, { FormEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { AnyMongoAbility } from '@casl/ability'

import { TooltipWrapper } from '../../common'
import {
    createAgencyPayment,
    getAgencyPaymentMethods,
    getQuickbooksTokenExpiration,
    JsonResponseWithResults,
    JsonResponseWithResultsData,
    toastJsonResults
} from '../../../api'
import { AgencyPaymentsTable } from './table'
import {
    AgencyPayment,
    AgencyStatus,
    PaymentMethod,
    PaymentMethods,
    JsonAgencyPayment
} from '../../../models'

interface AgencyPaymentProps {
    ability: AnyMongoAbility
    agencyId: number
    agencyPayments: AgencyPayment[]
    agencyStatus: number
    preferredPaymentMethod?: PaymentMethods
}

export const AgencyPayments = (props: AgencyPaymentProps) => {
    const { ability, agencyId, agencyPayments, agencyStatus, preferredPaymentMethod } = props

    const [isAdding, setIsAdding] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [amount, setAmount] = useState('')
    const [depositDate, setDepositDate] = useState('')
    const [payments, setPayments] = useState(agencyPayments)
    const [tokenExpiration, setTokenExpiration] = useState()
    const [paymentMethods, setPaymentMethods] = useState([])
    const [paymentMethodId, setPaymentMethodId] = useState(preferredPaymentMethod ?? PaymentMethods.Check)

    useEffect(() => {
        getAgencyPaymentMethods()
            .then((response) => {
                setPaymentMethods(response.data)
            })
            .catch((error) => {
                toast.error(error.message, {
                    autoClose: false
                })
            })

        getQuickbooksTokenExpiration()
            .then((response) => {
                setTokenExpiration(response.data)
            })
            .catch((error) => {
                toast.error(error.message, {
                    autoClose: false
                })
            })
    }, [])

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsSubmitting(true)

        const params = {
            agencyId,
            amount,
            depositDate,
            paymentMethodId
        }

        interface PaymentsJsonResponseData extends JsonResponseWithResultsData {
            payments: JsonAgencyPayment[]
        }

        createAgencyPayment(params)
            .then((json: JsonResponseWithResults<PaymentsJsonResponseData>) => {
                toastJsonResults(json)

                const { payments } = json.data

                setPayments(payments.map(
                    (payment: JsonAgencyPayment) => new AgencyPayment(payment)
                ))

                setAmount('')
                setDepositDate('')
                setIsAdding(false)
            })
            .catch(error => {
                const message = error.response.data?.message ?? error.message
                toast.error(message, { autoClose: false })
            })
            .finally(() => setIsSubmitting(false))
    }

    return (
        <div className='container card mb-3'>
            <div className='card-body row d-flex flex-row justify-content-between mb-5'>
                <div className='row flex-row mb-5'>
                    <h3 className='ps-0 d-inline-flex card-title w-50'>
                        Payments
                    </h3>
                    <div className='pe-0 d-inline-flex justify-content-end w-50'>
                        {isAdding &&
                            <button
                                className='btn btn-outline-secondary'
                                onClick={() => setIsAdding(false)}>
                                Cancel
                            </button>
                        }
                        {!isAdding &&
                            <TooltipWrapper
                                condition={!ability.can('Create', 'AgencyPayment')
                                    || agencyStatus !== AgencyStatus.ACTIVE
                                }
                                element={
                                    <button
                                        className='btn btn-primary'
                                        disabled={!ability.can('Create', 'AgencyPayment')
                                             || agencyStatus !== AgencyStatus.ACTIVE
                                        }
                                        onClick={() => setIsAdding(true)}>
                                        Add
                                    </button>
                                }
                                message={!ability.can('Create', 'AgencyPayment')
                                    ? 'Accounting permissions required'
                                    : 'Agency status must be active to submit payment'
                                } />
                        }
                    </div>
                </div>
                {!isAdding &&
                    <AgencyPaymentsTable payments={payments} />
                }
                {isAdding && (
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <label htmlFor='paymentAmount'>Payment Amount</label>
                        <div className='input-group'>
                            <span className='input-group-text'>$</span>
                            <input
                                className='form-control'
                                id='paymentAmount'
                                min={0.01}
                                onChange={(e) => setAmount(e.target.value)}
                                placeholder='Payment Amount'
                                step={0.01}
                                required
                                type='number'
                                value={amount} />
                        </div>
                        <label htmlFor="paymentMethodInput">Payment Method</label>
                        <select
                            className="form-select"
                            id="paymentMethodInput"
                            onChange={(e) => setPaymentMethodId(+e.target.value)}
                            value={paymentMethodId}
                        >
                        {paymentMethods.map((paymentMethod: PaymentMethod) => (
                            <option
                                key={`payment_method_${paymentMethod.id}`}
                                value={paymentMethod.id}
                            >
                                {paymentMethod.name}
                            </option>
                        ))}
                        </select>
                        {paymentMethodId === PaymentMethods.ACH && (
                            <>
                            <label htmlFor='depositDate'>Deposit Date</label>
                            <input
                                className='form-control'
                                id='depositDate'
                                onChange={(e) => setDepositDate(e.target.value)}
                                placeholder='Deposit Date'
                                required
                                type='date'
                                value={depositDate} />
                            </>
                        )}
                        <span className='d-flex justify-content-center mt-3'>
                            <TooltipWrapper
                                condition={!tokenExpiration}
                                element={
                                    <button
                                        className='btn btn-primary'
                                        disabled={isSubmitting || !tokenExpiration}
                                        type='submit'>
                                        Submit
                                    </button>
                                }
                                message='Authenticate Quickbooks to submit payment' />
                        </span>
                    </form>
                )}
            </div>
        </div>
    )
}
