import React from 'react'
import ReactDOM from 'react-dom'

import { Router } from './router'

export const App = () => {
    return (
        <Router />
    )
}

if (document.getElementById('react-root')) {
    ReactDOM.render(App(), document.getElementById('react-root'))
}
