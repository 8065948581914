import { JsonModel, Model } from './model'
import { JsonPaymentMethod, PaymentMethod } from './paymentMethod'
import { JsonAgencyStatus, AgencyStatus } from './'
import { Address, JsonAddress } from './address'
import { AgencySoftwareType, JsonAgencySoftwareType } from './'

import { AgencyPayment, JsonAgencyPayment } from './agencyPayment'

export const DIRECT_TRAVEL = 92;
export const VISION_VOYAGES = 21160;

export class Agency extends Model {
    addresses: Address[]
    name: string
    branch: string
    firstName: string
    lastName: string
    ein: string
    emails: string[]
    path: string
    phone: string
    status: AgencyStatus
    recordCount: number
    paymentAddress: Address
    payments?: AgencyPayment[]
    softwareType: AgencySoftwareType | null
    preferredPaymentMethod: PaymentMethod | null
    primaryEmail: string | null
    physicalAddress: Address
    unknownSupplierRecordCount?: number

    constructor(json: JsonAgency) {
        super(json)

        this.addresses = json.addresses.map(address => new Address(address))
        this.name = json.name
        this.branch = json.branch
        this.firstName = json.firstName
        this.lastName = json.lastName
        this.ein = json.ein
        this.emails = json.emails
        this.path = json.path
        this.phone = json.phone
        this.status = new AgencyStatus(json.status)
        this.recordCount = json.recordCount
        this.paymentAddress = new Address(json.paymentAddress)
        if (json.payments) {
            this.payments = json.payments.map(payment => new AgencyPayment(payment))
        }
        this.softwareType = json.softwareType
            ? new AgencySoftwareType(json.softwareType)
            : null
        this.preferredPaymentMethod = json.preferredPaymentMethod
            ? new PaymentMethod(json.preferredPaymentMethod)
            : null
        this.primaryEmail = json.primaryEmail
        this.physicalAddress = new Address(json.physicalAddress)

        if (json.unknownSupplierRecordCount !== null) {
            this.unknownSupplierRecordCount = json.unknownSupplierRecordCount
        }
    }
}

export interface JsonAgency extends JsonModel {
    addresses: JsonAddress[]
    name: string
    branch: string
    firstName: string
    lastName: string
    ein: string
    emails: string[]
    path: string
    paymentAddress: JsonAddress
    payments?: JsonAgencyPayment[]
    phone: string
    physicalAddress: JsonAddress
    preferredPaymentMethod: JsonPaymentMethod | null
    primaryEmail: string | null
    recordCount: number
    softwareType: JsonAgencySoftwareType | null
    status: JsonAgencyStatus
    unknownSupplierRecordCount: number | null
}
