import { JsonModel, Model } from './model'

export class AgencySoftwareType extends Model {

    agencyId: string
    id: number
    name: string

    constructor(json: JsonAgencySoftwareType) {
        super(json)

        this.agencyId = json.agencyId
        this.id = json.id
        this.name = json.name
    }
}

export interface JsonAgencySoftwareType extends JsonModel {
    agencyId: string
    id: number
    name: string
}
