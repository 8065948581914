import React, { useState } from 'react'
import { AnyMongoAbility } from '@casl/ability'

import { AgencyArc } from '../../../models'
import { TooltipWrapper } from '../../common'
import { DeleteArc } from './delete'
import { EditArc } from './edit'

interface ArcRowProps {
    ability: AnyMongoAbility
    agencyArc: AgencyArc
    existingArcs: AgencyArc[]
    setArcs: (arcs: AgencyArc[]) => void
    showDelete: boolean
}

export const ArcRow = (props: ArcRowProps) => {
    const { ability, agencyArc, existingArcs, setArcs, showDelete } = props

    const [isEditing, setIsEditing] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    return (
        <tr className='d-flex'>
        {isEditing &&
            <td className='col-12'>
                <EditArc
                    agencyArc={agencyArc}
                    existingArcs={existingArcs}
                    setArcs={setArcs}
                    setIsEditing={setIsEditing} />
            </td>
        }
        {isDeleting &&
            <td className='col-12'>
                <DeleteArc
                    agencyArc={agencyArc}
                    cancel={() => setIsDeleting(false)}
                    existingArcs={existingArcs}
                    setArcs={setArcs} />
            </td>
        }
        {(!isEditing && !isDeleting) &&
            <>
            <td className='col-10'>{agencyArc.arc}</td>
            {showDelete &&
                <td className='text-end col-2'>
                    <TooltipWrapper
                        condition={!ability.can('Delete', 'Arc')}
                        element={
                            <button
                                className='btn btn-danger'
                                disabled={!ability.can('Delete', 'Arc')}
                                onClick={() => setIsDeleting(true)}>
                                Delete
                            </button>
                        }
                        message={'Lead permissions required'} />
                </td>
            }
            {!showDelete &&
                <td className='text-end col-2'>
                    <TooltipWrapper
                        condition={!ability.can('Edit', 'Arc')}
                        element={
                            <button
                                className='btn btn-outline-primary'
                                disabled={!ability.can('Edit', 'Arc')}
                                onClick={() => setIsEditing(true)}>
                                Edit
                            </button>
                        }
                        message={'Lead permissions required'} />
                </td>
            }
            </>
        }
        </tr>
    )
}
