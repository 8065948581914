import React, { Dispatch, SetStateAction } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'

import { deleteRecord } from '../../../api'
import { TooltipWrapper } from '../../common'
import { Record } from '../../../models'
import { getAuthUser } from '../../../helpers';
import defineAbilityFor from '../defineAbility';

interface DeleteRecordProps {
    setIsDeleting: Dispatch<SetStateAction<boolean>>
    record: Record
}

export const DeleteRecord = (props: DeleteRecordProps) => {
    const { setIsDeleting, record } = props

    const ability = defineAbilityFor(getAuthUser())
    const navigate = useNavigate()

    return (
        <div className='container card'>
            <div className='card-body col-xs-12 row d-flex flex-row justify-content-between mb-5'>
                <div className='row flex-row'>
                    <h3 className='ps-0 d-inline-flex card-title w-50'>
                        {record.id}
                    </h3>
                    <div className='pe-0 d-inline-flex justify-content-end w-50 align-items-center'>
                        <TooltipWrapper
                            condition={!ability.can('Delete', 'Record')}
                            element={
                                <button
                                    className='btn btn-danger me-3'
                                    disabled={!ability.can('Delete', 'Record')}
                                    onClick={() => {
                                        deleteRecord(record.id.toString())
                                            .then(response => {
                                                toast.success(`${record.id} Deleted`)
                                                navigate('/records')
                                            })
                                            .catch(error => {
                                                const { response } = error

                                                if (response?.status === 400) {
                                                    toast.error(response.data, { autoClose: false })
                                                }
                                            })
                                    }}>
                                    Confirm Delete
                                </button>
                            }
                            message='Executive permission required to delete record' />
                        <button
                            className='btn btn-outline-secondary'
                            onClick={() => setIsDeleting(false)}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
