import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'

import { getDailyPayments, getPaidRecords } from '../../../api'
import easternTimezonedDayjs from '../../../helpers/dayjsEastern'
import { currencyFormat } from '../../../helpers/currencyCalculation'
import { InsufficientPermissions, Loading, PaginatedTable, PaginatedTableCallbacks } from '../../common'
import { JsonRecord, Record } from '../../../models'
import { DailyPaymentFilters } from './filters'
import { PaymentsTable } from '..'
import { getAuthUser } from '../../../helpers'
import defineAbilityFor from '../defineAbility'

interface PaymentTypes {
    amount: string
    records: number
}

interface PaymentData {
    paymentDate: string
    totalRecords: number
    totalAmount: string
    paymentTypes: {[type: string]: PaymentTypes}
}
enum ViewType {
    DailyPayments = 'dailyPayments',
    Records = 'records',
}

export const DailyPaymentsReport = () => {
    const [paymentData, setPaymentData] = useState<PaymentData[]>([])
    const [loading, setLoading] = useState(false)
    const [selectedDate, setSelectedDate] = useState<string>()
    const [currentView, setCurrentView] = useState<ViewType>(ViewType.DailyPayments)
    const [startDate, setStartDate] = useState(
        easternTimezonedDayjs().subtract(7, 'day').format('YYYY-MM-DD')
    )
    const [endDate, setEndDate] = useState(easternTimezonedDayjs().format('YYYY-MM-DD'))

    const handleShowPayments = () => {
        setSelectedDate(undefined)
        setCurrentView(ViewType.DailyPayments)
    }

    const fetchData = async (canceled: { current: boolean }) => {
        try {
            setLoading(true)
            const response = await getDailyPayments({ startDate, endDate })
            if (!canceled.current) {
                if (response.data.status === 'success') {
                    setPaymentData(response.data.data)
                } else {
                    console.error('Error: API returned an unsuccessful status')
                }
            }
        } catch (error) {
            if (!canceled.current) {
                console.error('Error fetching data:', error)
            }
        } finally {
            if (!canceled.current) {
                setLoading(false)
            }
        }
    }

    useEffect(() => {
        const canceled = { current: false }
        setCurrentView(ViewType.DailyPayments)
        fetchData(canceled)
        return () => {
            canceled.current = true
        }
    }, [startDate, endDate])

    const renderTable = () => {
        switch (currentView) {
            case ViewType.DailyPayments:
                return (
                    <table className="table table-striped" style={{ tableLayout: 'fixed' }}>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Records Paid</th>
                                {Object.keys(paymentData[0]?.paymentTypes || {}).map(
                                    (type) => (
                                        <th key={type}>{type}</th>
                                    )
                                )}
                                <th>Total Amount Paid</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paymentData.map((payment, index) => (
                                <tr key={index}>
                                    <td>{payment.paymentDate}</td>
                                    <td>{payment.totalRecords}</td>
                                    {Object.keys(payment.paymentTypes).map((type) => (
                                        <td key={type}>
                                            {payment.paymentTypes[type].records} /{' '}
                                            {currencyFormat(parseFloat(payment.paymentTypes[type].amount))}
                                        </td>
                                    ))}
                                    <td>
                                        {currencyFormat(parseFloat(payment.totalAmount))}
                                    </td>
                                    <td>
                                        <button
                                            className="btn btn-primary"
                                            onClick={() => {
                                                setSelectedDate(payment.paymentDate)
                                                setCurrentView(ViewType.Records)
                                            }}
                                        >
                                            View Records
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )
            case ViewType.Records:
                return (
                    <>
                        <div className="d-flex justify-content-left">
                            <button
                                className="btn btn-primary mb-3"
                                onClick={handleShowPayments}
                            >
                                Back to Daily Payments
                            </button>
                        </div>
                        <PaginatedTable<Record, JsonRecord>
                            getData={(params) => getPaidRecords({
                                ...params,
                                dailyPayment: true,
                                date_paid: selectedDate
                            })}
                            Filters={(props) => (
                                <DailyPaymentFilters {...props} />
                            )}
                            modelConstructor={(json: JsonRecord) => new Record(json)}
                            Table={(records: Record[], callbacks: PaginatedTableCallbacks) => (
                                <PaymentsTable callbacks={callbacks} resources={{ records }} />
                            )}
                            title={`Records Paid on ${dayjs.utc(selectedDate).format(
                                'MMM-D-YYYY'
                            )}`}
                        />
                    </>
                )
            default:
                return null
        }
    }

    const renderContent = () => {
        if (loading) {
            return <Loading />
        }
        return renderTable()
    }

    const ability = defineAbilityFor(getAuthUser())

    if (!ability.can('View', 'DailyPayments')) {
        return <InsufficientPermissions />
    }

    return (
        <div className="container card">
            <div className="card-body">
                {currentView === 'dailyPayments' && (
                    <div className="row mb-3 align-items-end">
                        <div className="col-6 col-sm-3">
                            <label className="me-2" htmlFor="dateSelector">
                                Start Date:
                            </label>
                            <input
                                type="date"
                                id="dateSelector"
                                className="form-control"
                                value={startDate}
                                onChange={(event) => setStartDate(event.target.value)}
                            />
                        </div>
                        <div className="col-6 col-sm-3">
                            <label className="me-2" htmlFor="endDateSelector">
                                End Date:
                            </label>
                            <input
                                type="date"
                                id="endDateSelector"
                                className="form-control"
                                value={endDate}
                                onChange={(event) => setEndDate(event.target.value)}
                            />
                        </div>
                    </div>
                )}
                {renderContent()}
            </div>
        </div>
    )
}
