import React from 'react'

import { InsufficientPermissions, PaginatedTable, PaginatedTableCallbacks } from '../common'
import { AgenciesTable } from './'
import { JsonAgency, Agency } from '../../models'
import { getAgencies } from '../../api'
import { getAuthUser } from '../../helpers';
import defineAbilityFor from './defineAbility'

export const AgenciesLanding = () => {
    const ability = defineAbilityFor(getAuthUser())

    if (!ability.can('View', 'Agency')) {
        return <InsufficientPermissions />
    }

    return (
        <PaginatedTable<Agency, JsonAgency>
            getData={getAgencies}
            redirectLink={{
                to: '/agencies/add',
                text: 'Create New Agency'
            }}
            modelConstructor={(json: JsonAgency) => new Agency(json)}
            searchPlaceholder='Search by Name, ARC, EIN, Phone Number, or City'
            Table={(agencies: Agency[], callbacks: PaginatedTableCallbacks) => (
                <AgenciesTable
                    callbacks={callbacks}
                    resources={{ agencies }} />
            )}
            title='Agencies' />
    )
}
