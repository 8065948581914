import React, { useEffect, useState } from 'react'

import { isLocalPhone } from '../../../helpers'

interface SupplierPhoneNumberFormProps {
    handleSubmit: (phone: string) => void
    phone?: string
    title: string
}

export const SupplierPhoneNumberForm = (props: SupplierPhoneNumberFormProps) => {
    const { handleSubmit, title } = props

    const [phone, setPhone] = useState(props.phone ?? '')
    const [isValidPhone, setIsValidPhone] = useState(true)

    useEffect(() => {
        setIsValidPhone(phone ? isLocalPhone(phone) : true)
    }, [phone])

    return (
        <form onSubmit={(e) => {
            e.preventDefault()

            handleSubmit(phone)
        }}>
            <label htmlFor='phoneInput'>{title}</label>
            <input
                className='form-control'
                defaultValue={phone}
                id='phoneInput'
                onChange={(e) => setPhone(e.target.value)}
                required
                type='text' />
            {!isValidPhone &&
                <div className='invalid-feedback d-block'>
                    Enter local phone number
                </div>
            }
            <span className='d-flex justify-content-center mt-3'>
                <button
                    className='btn btn-primary'
                    type='submit'>
                    Submit
                </button>
            </span>
        </form>
    )
}
