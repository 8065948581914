import { JsonRecordChange, RecordChange } from './recordChange'

export class RecordStatusChange extends RecordChange {
    constructor(json: JsonRecordStatusChange) {
        super(json)

        this.oldValue = json.oldStatus
        this.newValue = json.newStatus
        this.createdBy = json.createdBy
    }
}

export interface JsonRecordStatusChange extends JsonRecordChange {
    oldStatus: string
    newStatus: string
    createdBy: string
}
