import React, { FormEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { RecordNote, RecordStatus } from '../../models'
import { createRecordNote, deleteRecordNote } from '../../api'
import { getAuthUser } from '../../helpers'
import { NoteDiv, TooltipWrapper } from '../common'

import defineAbilityFor from './defineAbility'

interface NoteProps {
    recordId: number
    recordStatus: number
    recordNotes: RecordNote[]
}

export const RecordNotes = (props: NoteProps) => {
    const { recordId, recordStatus, recordNotes } = props

    const [isAdding, setIsAdding] = useState(false)
    const [notes, setNotes] = useState(recordNotes)
    const [note, setNote] = useState('')

    useEffect(() => {
        setNotes(recordNotes)
    }, [recordNotes])

    const handleCancel = () => {
        setNote('')
        setIsAdding(false)
    }

    const handleDelete = (recordNoteId: number) => {
        deleteRecordNote(recordNoteId)
            .then(json => {
                setNotes(notes.filter(note => note.id !== recordNoteId))

                toast.success('Note Deleted')
            })
            .catch(error => toast.error(error.message, { autoClose: false }))
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()

        const params = {
            recordId,
            note
        }

        createRecordNote(params)
            .then(json => {
                setNotes([
                    ...notes,
                    new RecordNote(json.data)
                ])
                setNote('')
                setIsAdding(false)

                toast.success('Note Created')
            })
            .catch(error => toast.error(error.message))
    }

    const ability = defineAbilityFor(getAuthUser())

    return (
        <>
            <div className='d-flex justify-content-between mb-3 mt-3'>
                <h3>Notes</h3>
            </div>
            <form className='mb-3' onSubmit={(e) => handleSubmit(e)}>
            <TooltipWrapper
                condition={![RecordStatus.UNKNOWN_DEPOSIT, RecordStatus.OVERPAYMENT].includes(recordStatus)}
                element={
                    <textarea
                        className='form-control mb-3'
                        disabled={![RecordStatus.UNKNOWN_DEPOSIT, RecordStatus.OVERPAYMENT].includes(recordStatus)}
                        onChange={(e) => setNote(e.target.value)}
                        onFocus={() => setIsAdding(true)}
                        placeholder='Add a note'
                        required
                        value={note} />
                }
                message={'Notes can only be added when record is in Unknown Deposit or Overpayment status'} />
                {isAdding &&
                    <>
                        <button
                            className='btn btn-primary me-2'
                            type='submit'>
                            Submit
                        </button>
                        <button
                            className='btn btn-outline-secondary'
                            onClick={handleCancel}>
                            Cancel
                        </button>
                    </>
                }
            </form>
            {notes
                .sort((a, b) => {
                    if (b.created_at.isAfter(a.created_at)) {
                        return 1
                    }

                    if (a.created_at.isAfter(b.created_at)) {
                        return -1
                    }

                    return 0
                })
                .map(note => (
                    <div className='card mb-3' key={`note_card_${note.id}`}>
                        <div className='card-header'>
                            <strong className='me-4'>
                                {note.createdBy}
                            </strong>
                            {note.created_at_local.format('MMM D, YYYY [at] h:mm A')}
                            <TooltipWrapper
                                condition={!ability.can('Delete', 'RecordNote')}
                                element={
                                    <button
                                        className='btn btn-sm btn-outline-danger float-end'
                                        disabled={!ability.can('Delete', 'RecordNote')}
                                        onClick={() => handleDelete(note.id)}>
                                        Delete
                                    </button>
                                }
                                message={'Executive permissions required'} />
                        </div>
                        <NoteDiv className='card-body'>
                            {note.note}
                        </NoteDiv>
                    </div>
                ))}
        </>
    )
}
