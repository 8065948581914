import React from 'react'
import { Link } from 'react-router-dom'

import { Record } from '../../models'
import { PaginatedTableContract } from '../common'

interface RecordsTableProps extends PaginatedTableContract {
    resources: {
        records: Record[]
    }
}

export const RecordsTable = (props: RecordsTableProps) => {
    const { resources } = props
    const { records } = resources

    return (
        <table className='table'>
            <tbody>
                <tr>
                    <th>Record ID</th>
                    <th>Status</th>
                    <th>Agency</th>
                    <th>Phone</th>
                    <th>Guest First Name</th>
                    <th>Guest Last Name</th>
                    <th>Confirmation Number</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                </tr>
                {records.map(record =>
                    <tr key={record.id}>
                        <td><Link to={`/records/${record.id}`}>{record.id}</Link></td>
                        <td>{record.status.name}</td>
                        <td><Link to={`/agencies/${record.agency.id}`} className='text-nowrap'>{record.agency.name}</Link></td>
                        <td>{record.phone}</td>
                        <td>{record.guestFirstName}</td>
                        <td>{record.guestLastName}</td>
                        <td>{record.confirmationNumber}</td>
                        <td>{record.startDate.format('M/D/YYYY')}</td>
                        <td>{record.endDate.format('M/D/YYYY')}</td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}
