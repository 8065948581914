import React, { useState, FormEvent } from 'react'

import { getAuthUser } from '../../helpers'
import { CreateChainParams } from '../../api/requests'
import defineAbilityFor from './defineAbility'
import { InsufficientPermissions } from 'components/common'

interface CreateChainFormProps {
    handleSubmit: (
        e: FormEvent<HTMLFormElement>,
        inputs: CreateChainParams
    ) => void
    isSubmitting?: boolean
}

export const ChainCreateForm = (props: CreateChainFormProps) => {
    const { handleSubmit, isSubmitting } = props
    const ability = defineAbilityFor(getAuthUser())

    const defaultInputs = {
        abbreviation: '',
        code: '',
        name: ''
    }
    const [inputs, setInputs] = useState(defaultInputs)

    const updateInputs = (field: string, value: number | string) => {
        setInputs({
            ...inputs,
            [field]: value
        })
    }

    if (!ability.can('Create', 'Chain')) {
        return <InsufficientPermissions />
    }

    return (
        <form onSubmit={(e) => handleSubmit(e, inputs)}>
            <div className='row mb-4'>
                <div className='col-12'>
                    <label htmlFor='nameInput'>Name</label>
                    <input
                        className='form-control'
                        id='nameInput'
                        onChange={(e) => updateInputs(
                            'name',
                            e.target.value
                        )}
                        required
                        type='text'
                        maxLength={35}
                        value={inputs.name} />
                    <label htmlFor='abbreviationInput'>Chain Code</label>
                    <input
                        className='form-control'
                        id='abbreviationInput'
                        onChange={(e) => updateInputs(
                            'abbreviation',
                            e.target.value
                        )}
                        required
                        maxLength={2}
                        type='text'
                        value={inputs.abbreviation} />
                    <label htmlFor='codeInput'>Code</label>
                    <input
                        className='form-control'
                        id='codeInput'
                        onChange={(e) => updateInputs(
                            'code',
                            e.target.value
                        )}
                        required
                        maxLength={10}
                        type='text'
                        value={inputs.code} />
                </div>
            </div>
            <div className='row mb-5'>
                <div className='col-12 d-flex justify-content-center'>
                    <button className='btn btn-primary' type='submit' disabled={!!isSubmitting}>
                        Submit
                    </button>
                </div>
            </div>
        </form>
    )
}
