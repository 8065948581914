import React from 'react'
import { Link } from 'react-router-dom'

export const NotFound = () => {
    return (
        <div className='container'>
            <div className='col-xs-12'>
                <div className='row'>
                    <h1 className='alert alert-danger text-center'>
                        404: Page Not Found
                    </h1>
                    <div className='row'>
                        <Link className='text-center' to="/">
                            Back to Home
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
