import React, { FormEvent, useState } from 'react'

import { Country } from '../../models'
import { UpdateCountryParams } from '../../api'

interface FormProps {
    handleSubmit: (
        e: FormEvent<HTMLFormElement>,
        inputs: UpdateCountryParams
    ) => void
    country?: Country
}

export const CountryForm = (props: FormProps) => {
    const { handleSubmit, country } = props

    const defaultInputs = {
        abbreviation: country?.abbreviation,
        currency: country?.currency,
        exchangeRate: country?.exchange,
        name: country?.name
    }

    const [inputs, setInputs] = useState(defaultInputs)

    const updateInputs = (field: string, value: number | string) => {
        setInputs({
            ...inputs,
            [field]: value
        })
    }

    return (
        <form onSubmit={(e) => handleSubmit(e, inputs)}>
            <div className='row mb-4'>
                <div className='col-12'>
                    <label htmlFor='nameInput'>Name</label>
                    <input
                        className='form-control'
                        id='nameInput'
                        onChange={(e) => updateInputs(
                            'name',
                            e.target.value
                        )}
                        required
                        type='text'
                        value={inputs.name} />
                    <label htmlFor='abbreviationInput'>ISO Code</label>
                    <input
                        className='form-control'
                        id='abbreviationInput'
                        onChange={(e) => updateInputs(
                            'abbreviation',
                            e.target.value
                        )}
                        required
                        maxLength={2}
                        type='text'
                        value={inputs.abbreviation} />
                    <label htmlFor='currencyInput'>Currency</label>
                    <input
                        className='form-control'
                        id='currencyInput'
                        onChange={(e) => updateInputs(
                            'currency',
                            e.target.value
                        )}
                        maxLength={3}
                        required
                        type='text'
                        value={inputs.currency} />
                    <label htmlFor='exchangeInput'>Exchange Rate</label>
                    <input
                        className='form-control'
                        id='exchangeInput'
                        onChange={(e) => updateInputs(
                            'exchangeRate',
                            e.target.value
                        )}
                        required
                        type='number'
                        value={inputs.exchangeRate} />
                </div>
            </div>
            <div className='row mb-5'>
                <div className='col-12 d-flex justify-content-center'>
                    <button className='btn btn-primary' type='submit'>
                        Submit
                    </button>
                </div>
            </div>
        </form>
    )
}
