import { Role, User } from '../models'

export enum Roles {
    Standard = 'standard',
    Executive = 'executive',
    Lead = 'lead',
    Api = 'api',
    Contributor = 'contributor',
    Senior = 'senior',
    Accounting = 'accounting',
    SrLead = 'srlead',
    CollectionAgent = 'collectionagent'
}

export const userHasRole = (user: User, expectedRole: Roles) => {
    return user.roles.filter((role: Role) => role.name === expectedRole).length !== 0
}
