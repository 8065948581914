import React, { FormEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { InsufficientPermissions } from '../common';
import { getAuthUser } from '../../helpers';
import { createAgency, CreateAgencyParams } from '../../api'
import { AgencyCreateForm } from './createForm'
import defineAbilityFor from './defineAbility';

export const AddAgencyForm = () => {
    const navigate = useNavigate()
    const ability = defineAbilityFor(getAuthUser())

    const handleSubmit = (
        e: FormEvent<HTMLFormElement>,
        inputs: CreateAgencyParams
    ) => {
        e.preventDefault()

        createAgency(inputs)
            .then(json => {
                toast.success('Agency Created')
                navigate(`/agencies/${json.data.id}`)
            })
            .catch(error => {
                const { response } = error

                if (response.data.message) {
                    toast.error(response.data.message, { autoClose: false })
                }

                if (response.status === 400) {
                    toast.error(response.data, { autoClose: false })
                }
            })
    }

    if (!ability.can('Create', 'Agency')) {
        return <InsufficientPermissions />
    }

    return (
        <div className='container card col-xs-12 col-xl-6'>
            <div className='card-body col-xs-12 row d-flex flex-row justify-content-between mb-5'>
                <div className='row flex-row mb-5'>
                    <h3 className='ps-0 d-inline-flex card-title w-50'>
                        Create New Agency
                    </h3>
                </div>
                <AgencyCreateForm handleSubmit={handleSubmit} />
            </div>
        </div>
    )
}
