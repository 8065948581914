/**
 * Addresses
 */
export const createAddress = '/api/addresses'

/**
 * Agencies
 */
export const agency = '/api/agencies/{agencyId}'
export const agencies = '/api/agencies'
export const createAgency = '/api/agencies'
export const updateAgency = '/api/agencies/{agencyId}'
export const updateAgencyEmails = '/api/agencies/{agencyId}/emails'
export const uploadAgencyData = '/api/agencies/{agencyId}/upload'
export const uploadCtsData = '/api/agencies/ctsUpload'
export const uploadVtsArcData = '/api/agencies/{agencyId}/vtsArcUpload'

/**
 * Agency Addresses
 */
export const syncAgencyAddresses = '/api/agencies/{agencyId}/addresses'
export const updateAgencyAddresses = '/api/agencies/{agencyId}/addresses'

/**
 * Agency ARCs
 */
export const getAgencyArcs = '/api/agencyArcs/{agencyId}'
export const createAgencyArc = '/api/agencyArcs'
export const deleteAgencyArc = '/api/agencyArcs/{agencyArcId}'
export const updateAgencyArc = '/api/agencyArcs/{agencyArcId}'

/**
 * Agency Software Types
 */
export const getAgencySoftwareTypes = '/api/agencySoftwareTypes'

/**
 * Agency Notes
 */
export const getAgencyNotes = '/api/agencyNotes/{agencyId}'
export const createAgencyNote = '/api/agencyNotes'
export const deleteAgencyNote = '/api/agencyNotes/{agencyNoteId}'

/**
 * Agency Payments
 */
export const createAgencyPayment = '/api/agencyPayments'

/**
 * Agency Statuses
 */
export const agencyStatuses = '/api/agencyStatuses'

/**
 * Chains
 */
export const chain = '/api/chains/{chainId}'
export const chains = '/api/chains'
export const createChain = '/api/chains/add'
export const updateChain = '/api/chains/{chainId}'

/**
 * Countries
 */
export const country = '/api/countries/{countryId}'
export const countries = '/api/countries'
export const updateCountry = '/api/countries/{countryId}'

/**
 * Invoices
 */
export const invoice = '/api/invoices/{invoiceId}'
export const invoices = '/api/invoices'
export const createInvoice = '/api/invoices'
export const downloadInvoice = '/api/invoices/{invoiceId}/download'
export const generateInvoicePdfs = '/api/invoices/generate/pdfs'
export const updateInvoiceRecords = '/api/invoices/{invoiceId}/records'

/**
 * Invoice Suppliers
 */
export const invoiceSuppliers = '/api/invoiceSuppliers'

/**
 * Payment Methods
 */
export const paymentMethods = '/api/paymentMethods'

/**
 * Payments
 */
export const dailyPayments = '/api/dailyPayments'
export const downloadPaymentsCsv = '/api/records/payments/downloadCsv'

/**
 * Quickbooks
 */
export const quickbooksTokenExpiration = '/api/quickbooks/tokenExpiration'
export const quickbooksAuthUrl = '/api/quickbooks/authUrl'

/**
 * Records
 */
export const createRecord = '/api/records'
export const deleteRecord = '/api/records/{recordId}'
export const record = '/api/records/{recordId}'
export const records = '/api/records'
export const recordsWithInvalidSupplier = '/api/records/invalidSupplier'
export const updateRecord = '/api/records/{recordId}'
export const unassignedRecordCount = '/api/records/unassignedCount'
export const batchUpdateRecords = '/api/records'
export const paidRecords = '/api/records/payments'
export const getRecordsByPhone = '/api/records/byPhone/{phone}'
export const foreignConversionRecords = '/api/records/foreignConversion'
export const downloadRecordsCsv = '/api/records/downloadCsv'

/**
 * Unknown Supplier Records
 */
export const recordsWithUnknownSupplier = '/api/records/unknownSupplier'
export const downloadUnknownSupplierCsv = '/api/records/unknownSupplier/download'

/**
 * Record Notes
 */
export const getRecordNotes = '/api/recordNotes/{recordId}'
export const createRecordNote = '/api/recordNotes'
export const deleteRecordNote = '/api/recordNotes/{recordNoteId}'

/**
 * Record Statuses
 */
export const recordStatuses = '/api/recordStatuses'

/**
 * Reports
 */
export const reports = '/api/reports'
export const publishReports = '/api/reports/publish'
export const downloadAgencyReconciliationReport = '/api/reports'

/**
 * Roles
 */
export const roles = '/api/roles'

/**
 * States
 */
export const states = '/api/states'

/**
 * Suppliers
 */
export const createSupplier = '/api/suppliers'
export const deleteSupplier = '/api/suppliers/{supplierId}'
export const deleteSupplierRecords = '/api/suppliers/{phone}/records'
export const supplier = '/api/suppliers/{supplierId}'
export const suppliers = '/api/suppliers'
export const updateSupplier = '/api/suppliers/{supplierId}'
export const uploadSupplierData = '/api/suppliers/upload'
export const updateSupplierPhoneNumbers = '/api/suppliers/{supplierId}/phoneNumbers'

/**
 * Supplier Addresses
 */
export const syncSupplierAddresses = '/api/suppliers/{supplierId}/addresses'
export const updateSupplierAddresses = '/api/suppliers/{supplierId}/addresses'

/**
 * Supplier Balances
 */
export const downloadSupplierBalancesCsv = '/api/supplierBalances/download'
export const supplierBalances = '/api/supplierBalances'

/**
 * Supplier Invoices
 */

export const getSupplierInvoices = '/api/supplierInvoices/{supplierId}'

/**
 * Supplier Collections
 */
export const unassignedSupplierCollectionsCount = '/api/suppliers/collections/count'
export const updateSupplierAssignedUser = '/api/suppliers/collections'

/**
 * Supplier Callbacks
 */
export const createSupplierCallback = '/api/supplierCallbacks'
export const getSupplierCallbacks = '/api/supplierCallbacks/{supplierId}'
export const getCallbacksCalendarId = '/api/supplierCallbacks'
export const deleteSupplierCallback = '/api/supplierCallbacks/{callbackId}'

/**
 * Supplier Contacts
 */
export const getSupplierCollectionsContact = '/api/suppliers/{supplierId}/collections/contacts'
export const updateSupplierCollectionsContact = '/api/suppliers/{supplierId}/collections/contacts'

/**
 * Supplier Recordings
 */
export const uploadSupplierRecording = '/api/supplier/{supplierId}/supplierRecording'
export const getSupplierRecording = '/api/supplier/{supplierId}/supplierRecording/{fileName}'
export const listSupplierRecordings = '/api/supplier/{supplierId}/supplierRecording'
export const deleteSupplierRecording = '/api/supplier/{supplierId}/supplierRecording/{fileName}'

/**
 * Supplier Notes
 */
export const getSupplierNotes = '/api/supplierNotes/{supplierId}'
export const createSupplierNote = '/api/supplierNotes'
export const deleteSupplierNote = '/api/supplierNotes/{supplierNoteId}'

/**
 * Supplier Statuses
 */
export const supplierStatuses = '/api/supplierStatuses'

/**
 * Users
 */
export const user = '/api/users/{userId}'
export const users = '/api/users'
export const register = '/api/register'
export const userStatus = '/api/users/{userId}/userStatus'

/**
 * Vendor Codes
 */
export const vendorCode = '/api/vendorCodes/{vendorId}'
export const vendorCodes = '/api/vendorCodes'
export const createVendorCode = '/api/vendors'
export const updateVendorCode = '/api/vendors/{vendorId}'
