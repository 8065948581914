export class SupplierCollectionsContact {
    title: string
    name: string
    phone?: string
    email?: string

    constructor(json: JsonSupplierCollectionsContact) {
        this.title = json.title
        this.name = json.name
        this.phone = json.phone
        this.email = json.email
    }

    id() {
        return `${this.title}-${this.name}-${this.phone}-${this.email}`;
    }
}

export interface JsonSupplierCollectionsContact {
    title: string
    name: string
    phone?: string
    email?: string
}
