import React, { FormEvent, useState } from 'react'
import { toast } from 'react-toastify'

import { InsufficientPermissions, Loading } from '../common'
import { getAuthUser } from '../../helpers'
import { uploadCtsData } from '../../api'
import { Summary } from './dataUpload'

import defineAbilityFor from './defineAbility'

export const CtsDataUpload = () => {
    const [file, setFile] = useState<File>()
    const [fileKey, setFileKey] = useState(Date.now())
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [summaries, setSummaries] = useState<Summary[]>([])

    const ability = defineAbilityFor(getAuthUser())

    if (!ability.can('Upload', 'AgencyData')) {
        return <InsufficientPermissions />
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsSubmitting(true)

        if (file) {
            const form = new FormData()
            form.append('file', file)

            uploadCtsData(form)
                .then(response => {
                    toast.success('Upload Successful')
                    setIsSubmitting(false)
                    setFile(undefined)
                    setFileKey(Date.now())
                    setSummaries(response.data.summary)
                })
                .catch(error => {
                    const { response } = error

                    if (response.data.message) {
                        toast.error(response.data.message, { autoClose: false })
                    }
                    setIsSubmitting(false)
                })
        }
    }

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-6'>
                    <h3>CTS Data Upload</h3>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <input
                            className='form-control mb-3'
                            key={fileKey}
                            onChange={(e) => {
                                const { files } = e.target

                                if (files) {
                                    setFile(files[0])
                                }
                            }}
                            type='file'
                            required />
                        <button
                            className='btn btn-primary'
                            disabled={isSubmitting}
                            type='submit'>
                            <span className='pe-2'>Upload</span>
                            {isSubmitting && <Loading sm={true} />}
                        </button>
                    </form>
                </div>
                {summaries.map(summary =>
                    <div className='col-6' key={`arc_summary_${summary.agency}`}>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Upload Summary</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th>Agency</th>
                                    <td>{summary.agency}</td>
                                </tr>
                                <tr>
                                    <th>Total Records in File</th>
                                    <td>{summary.totalRecords}</td>
                                </tr>
                                <tr>
                                    <th>New Records Created</th>
                                    <td>{summary.newRecords}</td>
                                </tr>
                                <tr>
                                    <th>Duplicates</th>
                                    <td>{summary.duplicateCount}</td>
                                </tr>
                                <tr>
                                    <th>Too Old</th>
                                    <td>{summary.tooOldCount}</td>
                                </tr>
                                <tr>
                                    <th>Missing Guest Name</th>
                                    <td>{summary.missingGuestNameCount}</td>
                                </tr>
                                <tr>
                                    <th>Rate Too Low</th>
                                    <td>{summary.rateTooLowCount}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    )
}
