import React, { FormEvent, useEffect, useState } from 'react'

import easternTimezonedDayjs from '../../helpers/dayjsEastern'
import { calculateCommissionByDays, calculateNights } from '../../helpers/currencyCalculation'
import {
    CreateRecordParams,
    getPaymentMethods,
    getRecordStatuses,
    UpdateRecordParams
} from '../../api'
import {
    JsonPaymentMethod,
    PaymentMethod,
    JsonRecordStatus,
    Record,
    RecordStatus
} from '../../models'

interface FormProps {
    handleSubmit: (
        e: FormEvent<HTMLFormElement>,
        inputs: CreateRecordParams | UpdateRecordParams
    ) => void
    record?: Record
    isSubmitting: boolean
}

export const RecordForm = (props: FormProps) => {
    const { handleSubmit, record, isSubmitting } = props

    const [paymentMethods, setPaymentMethods] = useState([])
    const [recordStatuses, setRecordStatuses] = useState<RecordStatus[]>([])

    const defaultInputs = {
        check_number: record?.checkNumber ?? '',
        commission: (record?.commission ?? 0),
        confirmation_number: record?.confirmationNumber ?? '',
        date_processed: record?.dateProcessed?.format('YYYY-MM-DD')
            ?? easternTimezonedDayjs().format('YYYY-MM-DD'),
        arc: record?.arc ?? '',
        end_date: record?.endDate.format('YYYY-MM-DD') ?? '',
        exchange_rate: '',
        forval: record?.forval ?? 0,
        guest_first_name: record?.guestFirstName ?? '',
        guest_last_name: record?.guestLastName ?? '',
        paid_ct: record?.paidCt ?? 0,
        payment_method_id: record?.paymentMethod?.id ?? '',
        phone_number: record?.phone ?? '',
        rate: (record?.rate ?? 0).toFixed(2),
        start_date: record?.startDate.format('YYYY-MM-DD') ?? '',
        status: record?.status.code ?? RecordStatus.NOT_RESOLVED,
        substatus: record?.substatus?.code ?? '',
    }

    const [inputs, setInputs] = useState(defaultInputs)

    useEffect(() => {
        getPaymentMethods()
            .then(json => setPaymentMethods(
                json.data.map((method: JsonPaymentMethod) => new PaymentMethod(method))
            ))

        getRecordStatuses()
            .then(json => setRecordStatuses(
                json.data.map((status: JsonRecordStatus) => new RecordStatus(status))
            ))
    }, [])

    const updateInputs = (field: string, value: number | string) => {
        let newInputs = {
            ...inputs,
            [field]: value,
        }

        if (field === 'status') {
            const selectedStatus = recordStatuses.find(s => s.code === Number(value))
            const hasSubstatusesForSelectedStatus = !!selectedStatus?.substatuses?.length

            if (!hasSubstatusesForSelectedStatus) {
                newInputs = { ...newInputs, substatus: '' }
            }
        }

        if (['rate', 'start_date', 'end_date'].includes(field)) {
            const nights = calculateNights(newInputs.start_date, newInputs.end_date)
            const rawCommission = calculateCommissionByDays(Number(newInputs.rate), nights)
            newInputs.commission = Math.round((rawCommission + Number.EPSILON) * 100) / 100
        }
        setInputs(newInputs)
    }

    const hasSubstatuses = !!recordStatuses.find(s => s.code === Number(inputs.status))?.substatuses?.length

    return (
        <form onSubmit={(e) => handleSubmit(e, inputs)}>
            <div className='row mb-4'>
                <div className='col-12'>
                    <label htmlFor='statusInput'>Status</label>
                    <select
                        className='form-select'
                        id='statusInput'
                        onChange={(e) => updateInputs('status', +e.target.value)}
                        value={inputs.status}>
                        {recordStatuses.map((status: RecordStatus) => (
                            <option key={status.code} value={status.code}>
                                {status.name}
                            </option>
                        ))}
                    </select>
                    {hasSubstatuses && (
                        <>
                            <label htmlFor='substatusInput'>Substatus</label>
                            <select
                                className='form-select'
                                id='substatusInput'
                                onChange={(e) => updateInputs('substatus', +e.target.value)}
                                value={inputs.substatus}>
                                <option value=''>None</option>
                                {recordStatuses.find(s => s.code === Number(inputs.status))?.substatuses.map(substatus => (
                                    <option key={substatus.code} value={substatus.code}>
                                        {substatus.name}
                                    </option>
                                ))}
                            </select>
                        </>
                    )}
                    <label htmlFor='guestFirstNameInput'>
                        Guest First Name
                    </label>
                    <input
                        className='form-control'
                        id='guestFirstNameInput'
                        onChange={(e) => updateInputs(
                            'guest_first_name',
                            e.target.value
                        )}
                        type='text'
                        required
                        value={inputs.guest_first_name} />
                    <label htmlFor='guestLastNameInput'>
                        Guest Last Name
                    </label>
                    <input
                        className='form-control'
                        id='guestLastNameInput'
                        onChange={(e) => updateInputs(
                            'guest_last_name',
                            e.target.value
                        )}
                        type='text'
                        required
                        value={inputs.guest_last_name} />
                    <label htmlFor='confirmationNumberInput'>
                        Confirmation Number
                    </label>
                    <input
                        className='form-control'
                        id='confirmationNumberInput'
                        onChange={(e) => updateInputs(
                            'confirmation_number',
                            e.target.value
                        )}
                        type='text'
                        value={inputs.confirmation_number} />
                    <label htmlFor='arcInput'>
                        ARC
                    </label>
                    <input
                        className='form-control'
                        id='arcInput'
                        onChange={(e) => updateInputs(
                            'arc',
                            e.target.value
                        )}
                        type='text'
                        required
                        value={inputs.arc} />
                    <label htmlFor='phoneInput'>
                        Phone
                    </label>
                    <input
                        className='form-control'
                        id='phoneInput'
                        onChange={(e) => updateInputs(
                            'phone_number',
                            e.target.value
                        )}
                        type='text'
                        required
                        value={inputs.phone_number} />
                    <label htmlFor='startDateInput'>Check In</label>
                    <input
                        className='form-control'
                        id='startDateInput'
                        onChange={(e) => updateInputs(
                            'start_date',
                            e.target.value
                        )}
                        type='date'
                        required
                        value={inputs.start_date} />
                    <label htmlFor='endDateInput'>Check Out</label>
                    <input
                        className='form-control'
                        id='endDateInput'
                        onChange={(e) => updateInputs(
                            'end_date',
                            e.target.value
                        )}
                        type='date'
                        required
                        value={inputs.end_date} />
                    <label htmlFor='rateInput'>Rate</label>
                    <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input
                            className='form-control'
                            id='rateInput'
                            onChange={(e) => updateInputs('rate', e.target.value)}
                            onBlur={(e) => updateInputs('rate', e.target.valueAsNumber.toFixed(2))}
                            min={0}
                            step='0.01'
                            type='number'
                            required
                            value={inputs.rate} />
                    </div>
                    <label htmlFor='commissionInput'>Commission</label>
                    <div className="input-group mb-3">
                        <span className="input-group-text">$</span>
                        <input
                            className='form-control'
                            id='commissionInput'
                            onChange={(e) => updateInputs('commission', e.target.value)}
                            onBlur={(e) => updateInputs('commission', e.target.valueAsNumber.toFixed(2))}
                            min={0}
                            step='0.01'
                            type='number'
                            required
                            value={inputs.commission} />
                    </div>
                    {RecordStatus.PROCESSED_STATUSES.includes(inputs.status) &&
                        <>
                            <label htmlFor='processDateInput'>Date Processed</label>
                            <input
                                className='form-control'
                                id='processDateInput'
                                onChange={(e) => updateInputs(
                                    'date_processed',
                                    e.target.value
                                )}
                                type='date'
                                required
                                value={inputs.date_processed} />
                        </>
                    }
                    {RecordStatus.PAID_STATUSES.includes(inputs.status) &&
                        <>
                            {record?.isForeign &&
                                <>
                                    <label htmlFor='forval'>Foreign Value</label>
                                    <div className="input-group mb-3">
                                        <span className="input-group-text">$</span>
                                        <input
                                            className='form-control'
                                            id='forval'
                                            onChange={(e) => updateInputs(
                                                'forval',
                                                e.target.value
                                            )}
                                            min={0}
                                            step='0.01'
                                            type='number'
                                            value={inputs.forval} />
                                    </div>
                                </>
                            }
                            <label htmlFor='paidCtInput'>Paid CT</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text">$</span>
                                <input
                                    className='form-control'
                                    id='paidCtInput'
                                    onChange={(e) => updateInputs('paid_ct', e.target.value)}
                                    min={0}
                                    step='0.01'
                                    type='number'
                                    required
                                    value={inputs.paid_ct} />
                            </div>
                            <label htmlFor='paymentMethodInput'>Payment Method</label>
                            <select
                                className='form-select'
                                id='paymentMethodInput'
                                onChange={(e) => updateInputs('payment_method_id', e.target.value)}
                                value={inputs.payment_method_id}>
                                <option value=''>(empty)</option>
                                {paymentMethods.map((method: PaymentMethod) => (
                                    <option key={method.id} value={method.id}>
                                        {method.name}
                                    </option>
                                ))}
                            </select>
                            {PaymentMethod.CHECK_METHODS.includes(+inputs.payment_method_id) &&
                                <>
                                    <label htmlFor='checkNumber'>Check Number</label>
                                    <input
                                        className='form-control'
                                        id='checkNumber'
                                        onChange={(e) => updateInputs(
                                            'check_number',
                                            e.target.value
                                        )}
                                        type='text'
                                        value={inputs.check_number ?? ''} />
                                </>
                            }
                        </>
                    }
                </div>
            </div>
            <div className='row mb-5'>
                <div className='col-12 d-flex justify-content-center'>
                    <button className='btn btn-primary' type='submit' disabled={isSubmitting}>
                        Submit
                    </button>
                </div>
            </div>
        </form>
    )
}
