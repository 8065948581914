import React, { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs';
import { toast } from 'react-toastify'

import { getQuickbooksTokenExpiration, getQuickbooksAuthUrl } from '../../api/requests'
import { Loading } from '../common'
import { getAuthUser } from '../../helpers';
import defineAbilityFor from './defineAbility'
import { InsufficientPermissions } from '../common';

export const QuickbooksLanding = () => {
    const [fetchingAuthUrl, setFetchingAuthUrl] = useState(false)
    const [fetchingTokenExpiration, setFetchingTokenExpiration] = useState(false)
    const [tokenExpiration, setTokenExpiration] = useState<Dayjs>()
    const [authUrl, setAuthUrl] = useState()
    const ability = defineAbilityFor(getAuthUser())

    if (!ability.can('View', 'QuickbooksOauth')) {
        return <InsufficientPermissions />
    }

    useEffect(() => {
        setFetchingAuthUrl(true)
        getQuickbooksAuthUrl()
            .then((response) => {
                setAuthUrl(response.data)
            })
            .catch((error) => {
                toast.error(error.message, {
                    autoClose: false
                })
            })
            .finally(() => setFetchingAuthUrl(false))

        setFetchingTokenExpiration(true)
        getQuickbooksTokenExpiration()
            .then((response) => {
                setTokenExpiration(dayjs(response.data))
            })
            .catch((error) => {
                toast.error(error.message, {
                    autoClose: false
                })
            })
            .finally(() => setFetchingTokenExpiration(false))
    }, [])

    const tokenIsValid = tokenExpiration && tokenExpiration.isAfter(dayjs())

    return (
        <div className='container'>
            <div className='row'>
                <div className='col'>
                    {(fetchingAuthUrl || fetchingTokenExpiration) && <Loading />}
                    {tokenIsValid && (
                        <p>
                            Wait until {tokenExpiration.format('MMM D, YYYY')} to refresh token
                        </p>
                    )}
                    {!tokenIsValid && authUrl && !fetchingTokenExpiration && (
                        <a href={authUrl}>
                            <button className='btn btn-primary'>
                                Connect to Quickbooks
                            </button>
                        </a>
                    )}
                </div>
            </div>
        </div>
    )
}
