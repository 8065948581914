import { JsonModel, Model } from './model'
import { JsonUser, User } from './user'

export class RecordNote extends Model {
    recordId: string
    createdBy: string
    note: string

    constructor(json: JsonRecordNote) {
        super(json)

        this.recordId = json.recordId
        this.createdBy = 'System'

        if (json.createdBy) {
            const user = new User(json.createdBy)
            this.createdBy = user.name
        }

        this.note = json.note
    }
}

export interface JsonRecordNote extends JsonModel {
    recordId: string
    createdBy: JsonUser | null
    note: string
}
