import { JsonModel, Model } from './model'

export class State extends Model {
    id: number
    abbreviation: string
    countryId: number
    name: string

    constructor(json: JsonState) {
        super(json)

        this.id = json.id
        this.abbreviation = json.abbreviation
        this.countryId = json.countryId
        this.name = json.name
    }
}

export interface JsonState extends JsonModel {
    id: number
    abbreviation: string
    countryId: number
    name: string
}
