import React, { useEffect, useState } from 'react'

import { getSupplierStatuses } from '../../api'
import { JsonSupplierStatus, SupplierStatus, SupplierSubstatus } from '../../models'

interface FilterProps {
    updateFilters: (filterName: string, newValue: string, shouldDelete?: boolean, dependentFilterToClear?: string) => void
}

export const SupplierFilters = (props: FilterProps) => {
    const { updateFilters } = props

    const [supplierStatuses, setSupplierStatuses] = useState<SupplierStatus[]>([])
    const [selectedParentStatus, setSelectedParentStatus] = useState<SupplierStatus>()

    useEffect(() => {
        getSupplierStatuses()
            .then(json => setSupplierStatuses(
                json.data.map((status: JsonSupplierStatus) => new SupplierStatus(status))
            ))
    }, [])

    return (
        <>
        <label htmlFor='supplierStatusFilter'>Supplier Status</label>
        <select
            className='form-select'
            id='supplierStatusFilter'
            onChange={(e) => {
            updateFilters(
                'status',
                e.target.value ?? '',
                e.target.value === '-1',
                'supplier_substatus_code'
            )
            setSelectedParentStatus(
                supplierStatuses.find(status => status.code === +e.target.value)
            )
            }}>
        <option value={-1}>
            All
        </option>
        {supplierStatuses.map((status: SupplierStatus) => (
            <option key={status.code} value={status.code}>
                {status.name}
            </option>
        ))}
        </select>
        {selectedParentStatus?.substatuses && selectedParentStatus?.substatuses.length > 0 &&
            <>
            <label htmlFor='supplierSubstatusFilter'>Supplier Substatus</label>
            <select
                className='form-select'
                id='supplierSubstatusFilter'
                onChange={(e) => {
                    updateFilters(
                        'supplier_substatus_code',
                        e.target.value ?? '',
                        e.target.value === '-1'
                    )
                }}>
                <option value={-1}>
                    All
                </option>
                {selectedParentStatus.substatuses.map((substatus: SupplierSubstatus) => (
                    <option key={substatus.code} value={substatus.code}>
                        {substatus.name}
                    </option>
                ))}
            </select>
            </>
        }
        </>
    )
}
