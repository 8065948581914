import React, { useEffect, useState } from 'react'

import { AgencyArc } from '../../../models'
import { getAuthUser } from '../../../helpers'
import { TooltipWrapper } from '../../common'
import { AddArc, ArcRow } from './'
import defineAbilityFor from './defineAbility'

interface AgencyArcProps {
    agencyArcs: AgencyArc[]
    agencyId: number
}

export const AgencyArcs = (props: AgencyArcProps) => {
    const { agencyArcs, agencyId } = props

    const [isAdding, setIsAdding] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [arcs, setArcs] = useState(agencyArcs)

    useEffect(() => {
        setArcs(agencyArcs)
    }, [agencyArcs])

    useEffect(() => {
        setIsAdding(false)
        setIsDeleting(false)
    }, [JSON.stringify(arcs)])

    const ability = defineAbilityFor(getAuthUser())

    return (
        <div className='container card'>
            <div className='card-body row d-flex flex-row justify-content-between mb-5'>
                <div className='row flex-row mb-5'>
                    <h3 className='ps-0 d-inline-flex card-title w-50'>
                        ARCs
                    </h3>
                    <div className='pe-0 d-inline-flex justify-content-end w-50'>
                    {(!isAdding && !isDeleting) &&
                        <TooltipWrapper
                            condition={!ability.can('Delete', 'Arc')}
                            element={
                                <button
                                    className='btn btn-danger'
                                    disabled={!ability.can('Delete', 'Arc')}
                                    onClick={() => setIsDeleting(!isDeleting)}>
                                    Delete
                                </button>
                            }
                            message={'Lead permissions required'} />
                    }
                    {(!isAdding && !isDeleting) &&
                        <TooltipWrapper
                            condition={!ability.can('Create', 'Arc')}
                            element={
                                <button
                                    className='btn btn-primary ms-5'
                                    disabled={!ability.can('Create', 'Arc')}
                                    onClick={() => setIsAdding(!isAdding)}>
                                    Add
                                </button>
                            }
                            message={'Lead permissions required'} />
                    }
                    {(isAdding || isDeleting) &&
                        <button
                            className='btn btn-outline-secondary'
                            disabled={!ability.can('Create', 'Arc')}
                            onClick={() => {
                                setIsAdding(false)
                                setIsDeleting(false)
                            }}>
                            Cancel
                        </button>
                    }
                    </div>
                </div>
            {!isAdding &&
                <table className='table table-sm'>
                    <tbody>
                    {arcs.sort((a, b) => a.id - b.id).map(agencyArc => (
                        <ArcRow
                            ability={ability}
                            agencyArc={agencyArc}
                            existingArcs={arcs}
                            key={`arcRow_${agencyArc.id}`}
                            setArcs={setArcs}
                            showDelete={isDeleting} />
                    ))}
                    </tbody>
                </table>
            }
            {isAdding &&
                <AddArc
                    agencyId={agencyId}
                    existingArcs={arcs}
                    setArcs={setArcs} />
            }
            </div>
        </div>
    )
}
