import React from 'react'

interface LoadingProps {
    sm?: boolean
}

export const Loading = (props: LoadingProps) => {
    const { sm } = props

    let className = 'spinner-border'

    if (sm) {
        className += ' spinner-border-sm'
    }

    return (
        <div className={className} role='status'>
            <span className='visually-hidden'>Loading...</span>
        </div>
    )
}
