import React from 'react'

import { Agency } from '../../models'

interface AgencyTableProps {
    agency: Agency
}

export const AgencyTable = (props: AgencyTableProps) => {
    const { agency } = props

    return (
        <table className='table'>
            <tbody>
                <tr>
                    <th>Status</th>
                    <td>{agency.status.name}</td>
                </tr>
                <tr>
                    <th>Phone</th>
                    <td>{agency.phone}</td>
                </tr>
                <tr>
                    <th>EIN</th>
                    <td>{agency.ein}</td>
                </tr>
                <tr>
                    <th>Branch</th>
                    <td>{agency.branch}</td>
                </tr>
                <tr>
                    <th>First Name</th>
                    <td>{agency.firstName}</td>
                </tr>
                <tr>
                    <th>Last Name</th>
                    <td>{agency.lastName}</td>
                </tr>
                <tr>
                    <th>Records</th>
                    <td>{agency.recordCount}</td>
                </tr>
                <tr>
                    <th>Preferred Payment Method</th>
                    <td>{agency?.preferredPaymentMethod?.name ?? 'Unknown'}</td>
                </tr>
                <tr>
                    <th>Booking Software</th>
                    <td>{agency?.softwareType?.name}</td>
                </tr>
                <tr>
                    <th>Folder</th>
                    <td>{agency.path}</td>
                </tr>
            </tbody>
        </table>
    )
}
