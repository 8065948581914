import dayjs, { Dayjs } from 'dayjs'

import { JsonModel, Model } from './model'
import { RecordStatus } from './recordStatus'
import { JsonSupplier, Supplier } from './supplier'

export class Invoice extends Model {
    id: number
    completed: boolean
    records: InvoiceRecord[];
    reviewed: boolean
    supplier: Supplier;
    total: number

    constructor(json: JsonInvoice, jsonRecords?: JsonInvoiceRecord[]) {
        super(json)

        this.id = json.id
        this.completed = json.completed
        this.records = jsonRecords? jsonRecords.map(record => new InvoiceRecord(record)): [];
        this.reviewed = json.reviewed;
        this.supplier = new Supplier(json.supplier);
        this.total = json.total;
    }
}

/** DB row for records containing only columns needed for invoices */
export class InvoiceRecord {
    arc: string;
    agencyId: number;
    agencyName: string;
    commission: number;
    confirmationNumber: string;
    days: number;
    guestFirstName: string;
    guestLastName: string;
    id: number;
    paidCt: number;
    paymentMethodId?: number;
    paymentMethodName?: string;
    rate: number;
    startDate: Dayjs;
    statusCode: number;
    statusName: string;
    substatusCode?: number;
    substatusName?: string;

    isBillable: boolean
    isProcessed: boolean

    constructor(json: JsonInvoiceRecord) {
        this.arc = json.arc;
        this.agencyId = json.agencyId;
        this.agencyName = json.agencyName;
        this.commission = +json.commission;
        this.confirmationNumber = json.confirmationNumber ?? '';
        this.days = json.days;
        this.guestFirstName = json.guestFirstName;
        this.guestLastName = json.guestLastName;
        this.id = json.id;
        this.paidCt = json.paidCt != undefined? parseFloat(json.paidCt): 0;
        this.paymentMethodId = json.paymentMethodId;
        this.paymentMethodName = json.paymentMethodName;
        this.rate = +json.rate;
        this.startDate = dayjs(json.startDate);
        this.statusCode = json.statusCode;
        this.statusName = json.statusName;
        this.substatusCode = json.substatusCode;
        this.substatusName = json.substatusName;

        this.isBillable = RecordStatus.BILLABLE_STATUSES.includes(this.statusCode)
        this.isProcessed = RecordStatus.PROCESSED_STATUSES.includes(this.statusCode);
    }
}

export interface JsonInvoiceResponse {
    invoice: JsonInvoice
    /**
     * An invoice's associated records are returned from /api/invoices/{invoiceId}
     * but omitted from /api/invoices to improve performance
     */
    records: JsonInvoiceRecord[];
}

export interface JsonInvoice extends JsonModel {
    completed: boolean
    reviewed: boolean
    supplier: JsonSupplier;
    total: number
}

export interface JsonInvoiceRecord {
    arc: string;
    agencyId: number;
    agencyName: string;
    commission: string;
    confirmationNumber: string;
    days: number
    guestFirstName: string;
    guestLastName: string;
    id: number;
    paidCt: string | null;
    paymentMethodId: number;
    paymentMethodName: string;
    rate: string;
    startDate: string;
    statusCode: number;
    statusName: string;
    substatusCode: number;
    substatusName: string;
}
