import React from 'react'
import { Link } from 'react-router-dom'

import { CheckmarkIcon, XIcon } from '../../common'
import {PaymentMethod, RecordStatus, RecordSubstatus} from '../../../models';
import {currencyFormat} from '../../../helpers/currencyCalculation'

import {RecordInput, RecordInputMap, RecordEvent} from './reconciliation';

export interface ReconciliationTableProps {
    records: RecordInputMap;
    paymentMethods: PaymentMethod[],
    recordStatuses: RecordStatus[],
    /** called when a record input field is edited */
    onRecordUpdated: (event: RecordEvent) => void;
};

export const ReconciliationTable = (props: ReconciliationTableProps) => {
    const sortRecords = (a: RecordInput, b: RecordInput) => {
        const agencyA = a.record.agencyName;
        const agencyB = b.record.agencyName;

        const lastNameA = a.record.guestLastName;
        const lastNameB = b.record.guestLastName;

        return agencyA < agencyB? -1:
            agencyA > agencyB? 1:
            lastNameA < lastNameB? -1:
            lastNameA > lastNameB? 1:
            0;
    };
    const unreconciledRecords = Object.values(props.records).filter((r) => !r.reconciled);
    unreconciledRecords.sort(sortRecords);

    const reconciledRecords = Object.values(props.records).filter((r) => r.reconciled);
    reconciledRecords.sort(sortRecords);

    const selectedRecords = Object.values(props.records).filter((r) => r.selected);
    const editedRecords = Object.values(props.records).filter((r) => Object.keys(r.edited).length > 0);

    return (
        <div className="reconciliation-table-container">
            <div className="row table-metrics mt-2 align-items-center">
                <div className='col-5 col-md-7 col-lg-8 col-xl-9'>
                    <div className="edited-records">
                        <span>Records modified: <span className="value">{editedRecords.length}</span></span>
                    </div>
                    <div className="selected-records">
                        <span>Records selected: <span className="value">{selectedRecords.length}</span></span>
                    </div>
                </div>
                <div className="col-7 col-md-5 col-lg-4 col-xl-3">
                    <div className="unreconciled-records d-flex justify-content-between">
                        <span className="button-label">Unreconciled: <span className="value">{unreconciledRecords.length}</span></span>
                        <button
                            className='btn btn-outline-success'
                            onClick={(e) => props.onRecordUpdated({type: 'reconcile', reconciled: true})}
                        >
                            Reconcile All
                        </button>
                    </div>
                    <div className="reconciledRecords d-flex justify-content-between">
                        <span className="button-label">Reconciled: <span className="value">{reconciledRecords.length}</span></span>
                        <button
                            className='btn btn-outline-danger'
                            onClick={(e) => props.onRecordUpdated({type: 'reconcile', reconciled: false})}
                        >
                            Cancel All
                        </button>
                    </div>
                </div>
            </div>
            <table className="invoice-reconciliation-table table table-sm table-responsive-md" style={{ minWidth: '100%' }}>
                <thead>
                    <tr>
                        <th>
                            <input
                                className="form-check-input"
                                onChange={(e) => props.onRecordUpdated({type: 'select', selected: e.target.checked})}
                                type="checkbox"
                                checked={selectedRecords.length > 0}
                            />
                        </th>
                        <th>Record</th>
                        <th>Agency</th>
                        <th>Status</th>
                        <th>Confirmation Number</th>
                        <th>Name</th>
                        <th>Check In</th>
                        <th className="col-1">Nights</th>
                        <th className="col-2">Nightly Rate</th>
                        <th className="col-1">Commission Due</th>
                        <th className="col-2">Paid CT</th>
                        <th className="col-2">Payment Method</th>
                        <th className="col-2">Status</th>
                        <th className="col-2">Substatus</th>
                        <th>Reconcile</th>
                    </tr>
                </thead>
                <tbody>
                    {unreconciledRecords.concat(reconciledRecords).map((recordInput) => {
                        const values = {
                            days: recordInput.edited.days ?? recordInput.record.days.toString(),
                            rate: recordInput.edited.rate ?? recordInput.record.rate.toFixed(2),
                            commission: recordInput.edited.commission != undefined? parseFloat(recordInput.edited.commission): recordInput.record.commission,

                            paidCt: recordInput.edited.paidCt ?? recordInput.record.paidCt.toFixed(2),
                            paymentMethodId: recordInput.edited.paymentMethodId ?? (recordInput.record.paymentMethodId != undefined? recordInput.record.paymentMethodId.toString(): ''), // default value to PaymentMethods.Check?
                            statusCode: recordInput.edited.statusCode ?? recordInput.record.statusCode.toString(),
                            substatusCode: recordInput.edited.substatusCode ?? (recordInput.record.substatusCode != undefined? recordInput.record.substatusCode.toString(): ''),
                        };
                        const substatuses = props.recordStatuses.find((status) => status.code === parseInt(values.statusCode))?.substatuses;

                        // used to render strikethrough on reconciled records
                        const commissionable = !RecordStatus.PROCESSED_STATUSES.includes(parseInt(values.statusCode))
         || (RecordStatus.PROCESSED_STATUSES.includes(parseInt(values.statusCode)) && RecordStatus.PAID_STATUSES.includes(parseInt(values.statusCode)));
                        const inputDisabled = recordInput.reconciled;

                        return <tr key={recordInput.record.id} className={(reconciledRecords.length > 0 && unreconciledRecords.length > 0 && recordInput.record.id === reconciledRecords[0].record.id)? 'first-unreconciled':''}>
                            <td>
                                {!recordInput.reconciled &&
                                <input
                                    checked={recordInput.selected}
                                    className='form-check-input'
                                    onChange={(e) => props.onRecordUpdated({type: 'select', recordId: recordInput.record.id.toString(), selected: e.target.checked})}
                                    type='checkbox'
                                />}
                            </td>
                            <td>
                                <Link to={`/records/${recordInput.record.id}`} target='_blank'>
                                    {recordInput.record.id}
                                </Link>
                            </td>
                            <td>{recordInput.record.agencyName}</td>
                            <td>{recordInput.record.statusName}</td>
                            <td>{recordInput.record.confirmationNumber}</td>
                            <td>{recordInput.record.guestLastName}, {recordInput.record.guestFirstName}</td>
                            <td>{recordInput.record.startDate.format('M/D/YYYY')}</td>
                            <td className={recordInput.edited.days != undefined? 'edited': ''}>
                                <input
                                    className="form-control"
                                    style={{ minWidth: '60px' }}
                                    disabled={inputDisabled}
                                    type="number"
                                    min={0}
                                    value={values.days}
                                    onChange={(e) => props.onRecordUpdated({type: 'edit', recordId: recordInput.record.id.toString(), property: 'days', value: e.target.value})}
                                    onBlur={(e) => {
                                        if(e.target.value === '') {
                                            props.onRecordUpdated({type: 'edit', recordId: recordInput.record.id.toString(), property: 'days', value: (0).toString()});
                                        }
                                    }}/>
                            </td>
                            <td className={recordInput.edited.rate != undefined? 'edited': ''}>
                                <input
                                    className="form-control"
                                    style={{ minWidth: '100px' }}
                                    disabled={inputDisabled}
                                    type="number"
                                    step={0.01}
                                    min={0}
                                    value={values.rate}
                                    onChange={(e) => props.onRecordUpdated({type: 'edit', recordId: recordInput.record.id.toString(), property: 'rate', value: e.target.value})}
                                    onBlur={(e) => {
                                        if(e.target.value === '') {
                                            props.onRecordUpdated({type: 'edit', recordId: recordInput.record.id.toString(), property: 'rate', value: (0).toFixed(2)});
                                        }
                                        else if(e.target.value !== parseFloat(values.rate).toFixed(2)) {
                                            props.onRecordUpdated({type: 'edit', recordId: recordInput.record.id.toString(), property: 'rate', value: parseFloat(e.target.value).toFixed(2)});
                                        }
                                    }}/>
                            </td>
                            <td className={recordInput.edited.commission != undefined? 'edited': ''}>{((commissionable) && currencyFormat(values.commission)
        ) || <s>{currencyFormat(values.commission)}</s>}</td>
                            <td className={recordInput.edited.paidCt != undefined? 'edited': ''}>
                                <input
                                    className="form-control"
                                    style={{ minWidth: '100px' }}
                                    disabled={inputDisabled}
                                    type='number'
                                    step={0.01}
                                    min={0}
                                    value={values.paidCt}
                                    onChange={(e) => props.onRecordUpdated({type: 'edit', recordId: recordInput.record.id.toString(), property: 'paidCt', value: e.target.value})}
                                    onBlur={(e) => {
                                        if(e.target.value === '') {
                                            props.onRecordUpdated({type: 'edit', recordId: recordInput.record.id.toString(), property: 'paidCt', value: (0).toFixed(2)});
                                        }
                                        else if(e.target.value !== parseFloat(values.paidCt).toFixed(2)) {
                                            props.onRecordUpdated({type: 'edit', recordId: recordInput.record.id.toString(), property: 'paidCt', value: parseFloat(e.target.value).toFixed(2)});
                                        }
                                    }}/>
                            </td>
                            <td className={recordInput.edited.paymentMethodId != undefined? 'edited': ''}>
                                <select
                                    className='form-select'
                                    style={{ minWidth: '100px' }}
                                    disabled={inputDisabled}
                                    value={values.paymentMethodId}
                                    onChange={(e) => props.onRecordUpdated({type: 'edit', recordId: recordInput.record.id.toString(), property: 'paymentMethodId', value: e.target.value})}>
                                    <option value='' disabled={parseFloat(values.paidCt) !== 0}>N/A</option>
                                    {props.paymentMethods.map((method: PaymentMethod) => (
                                        <option key={method.id} value={method.id} disabled={parseFloat(values.paidCt) === 0}>
                                            {method.name}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td className={recordInput.edited.statusCode != undefined? 'edited': ''}>
                                <select
                                    className='form-select'
                                    style={{ minWidth: '100px' }}
                                    disabled={inputDisabled}
                                    value={values.statusCode}
                                    onChange={(e) => props.onRecordUpdated({type: 'edit', recordId: recordInput.record.id.toString(), property: 'statusCode', value: e.target.value})}>
                                    {props.recordStatuses.map((status: RecordStatus) => (
                                        <option key={status.code} value={status.code}>
                                            {status.name}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td className={recordInput.edited.substatusCode != undefined? 'edited': ''}>
                                <select
                                    className='form-select'
                                    style={{ minWidth: '100px' }}
                                    disabled={inputDisabled}
                                    value={values.substatusCode}
                                    onChange={(e) => props.onRecordUpdated({type: 'edit', recordId: recordInput.record.id.toString(), property: 'substatusCode', value: e.target.value})}>
                                        <option value=''>None</option>
                                    {substatuses && substatuses.map((substatus: RecordSubstatus) => (
                                        <option key={substatus.code} value={substatus.code}>
                                            {substatus.name}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td>
                                {!recordInput.reconciled &&
                                    <button
                                        className="btn btn-outline-success"
                                        onClick={() => {
                                            props.onRecordUpdated({type: 'reconcile', recordId: recordInput.record.id.toString(), reconciled: true})
                                            props.onRecordUpdated({type: 'select', recordId: recordInput.record.id.toString(), selected: false})
                                        }}>
                                        <CheckmarkIcon />
                                    </button>
                                }
                                {recordInput.reconciled &&
                                    // && (recordInput.editable || userHasRole(user, Roles.Executive)) &&
                                    <button
                                        className='btn btn-outline-danger'
                                        onClick={() => props.onRecordUpdated({type: 'reconcile', recordId: recordInput.record.id.toString(), reconciled: false})}
>
                                        <XIcon />
                                    </button>
                                }
                            </td>
                        </tr>;
                    })}
                </tbody>
            </table>
            <h4 className="mb-5 d-flex justify-content-end align-items-end">
                Total records: {Object.keys(props.records).length}
            </h4>
        </div>
    )
}
