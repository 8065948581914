import { defineAbility } from '@casl/ability'

import { User } from '../../../models'
import { Roles, userHasRole } from '../../../helpers'

export default (user: User) => defineAbility((can) => {
    if (userHasRole(user, Roles.Accounting) || userHasRole(user, Roles.SrLead)) {
        can('Create', 'AgencyEmail')
        can('Edit', 'AgencyEmail')
        can('Delete', 'AgencyEmail')
        can('Update', 'AgencyEmail')
    }
})
