import { defineAbility } from '@casl/ability'

import { User } from '../../models'
import { Roles, userHasRole } from '../../helpers'

export const defineAbilityFor = (user: User) => defineAbility((can) => {
    if (userHasRole(user, Roles.Executive)) {
        can('queue', 'Reports')
        can('publish', 'Reports')
        can('download', 'Reports')
    }

    if (userHasRole(user, Roles.Standard)) {
        can('view', 'Reports')
    }
})
