import dayjs, { Dayjs } from 'dayjs'

import { JsonModel, Model } from './model'
import { JsonAgency, Agency } from './agency'
import { JsonPaymentMethod, PaymentMethod } from './paymentMethod'
import { JsonSupplier, Supplier } from './supplier'
import { JsonRecordStatus, RecordStatus } from './recordStatus'
import { JsonRecordRateChange, RecordRateChange } from './recordRateChange'
import { JsonRecordStatusChange, RecordStatusChange } from './recordStatusChange'
import { RecordChange } from './recordChange'
import { JsonRecordSubstatus, RecordSubstatus } from './recordSubstatus'

export class Record extends Model {
    agency: Agency
    arc: string
    changes: RecordChange[]
    checkNumber: string | null
    commission: number
    confirmationNumber: string
    dateProcessed: Dayjs | null
    days: number
    forval: number
    guestFirstName: string
    guestLastName: string
    isForeign: boolean
    isBillable: boolean
    isPaid: boolean
    isProcessed: boolean
    startDate: Dayjs
    endDate: Dayjs
    paidCt: number
    paymentMethod: PaymentMethod | null
    phone: string
    rate: number
    status: RecordStatus
    substatus: RecordSubstatus | null
    supplier: Supplier | null
    supplierName: string
    entry: Dayjs
    assignedUser: string

    constructor(json: JsonRecord) {
        super(json)

        this.agency = new Agency(json.agency)
        this.arc = json.arc
        this.assignedUser = json.assignedUser
        this.changes = [
            ...json.rateChanges.map(
                change => new RecordRateChange(change)
            ),
            ...json.statusChanges.map(
                change => new RecordStatusChange(change)
            )
        ]
        this.checkNumber = json.checkNumber
        this.commission = +json.commission
        this.confirmationNumber = json.confirmationNumber ?? ''
        this.dateProcessed = json.dateProcessed
            ? dayjs(json.dateProcessed)
            : null
        this.days = json.days
        this.forval = parseFloat(json.forval)
        this.guestFirstName = json.guestFirstName
        this.guestLastName = json.guestLastName
        this.isProcessed = json.isProcessed
        this.startDate = dayjs(json.startDate)
        this.endDate = dayjs(json.endDate)
        this.paidCt = parseFloat(json.paidCt)
        this.paymentMethod = json.paymentMethod
            ? new PaymentMethod(json.paymentMethod)
            : null
        this.phone = json.phone
        this.rate = +json.rate
        this.status = new RecordStatus(json.status)
        this.substatus = json.substatus ? new RecordSubstatus(json.substatus) : null;
        this.isBillable = RecordStatus.BILLABLE_STATUSES.includes(this.status.code)
        this.isPaid = RecordStatus.PAID_STATUSES.includes(this.status.code)
        this.supplierName = json.supplierName
        this.entry = dayjs(json.entry)
        this.supplier = json.supplier
            ? new Supplier(json.supplier)
            : null

        this.isForeign = this.supplier?.physicalAddress.country?.isForeign || false
    }
}

// BUG: Records do not have created_at/updated_at columns
export interface JsonRecord extends JsonModel {
    assignedUser: string
    agency: JsonAgency
    arc: string
    checkNumber: string | null
    commission: number | string
    confirmationNumber: string | null
    dateProcessed: string | null
    days: number
    forval: string
    guestFirstName: string
    guestLastName: string
    isProcessed: boolean
    paymentMethod: JsonPaymentMethod | null
    startDate: string
    endDate: string
    paidCt: string
    phone: string
    rate: number | string
    rateChanges: JsonRecordRateChange[]
    status: JsonRecordStatus
    substatus: JsonRecordSubstatus
    statusChanges: JsonRecordStatusChange[]
    supplier: JsonSupplier | null
    supplierName: string
    entry: string
}

export const standardCommission: number = 0.1
