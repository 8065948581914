import React, { FormEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { createVendorCode, CreateVendorCodeParams } from '../../api'
import { InsufficientPermissions } from '../common';
import defineAbilityFor from './defineAbility';
import { VendorForm } from './form'
import { getAuthUser } from '../../helpers';

export const AddVendorForm = () => {
    const navigate = useNavigate()
    const ability = defineAbilityFor(getAuthUser())

    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleSubmit = (
        e: FormEvent<HTMLFormElement>,
        inputs: CreateVendorCodeParams
    ) => {
        e.preventDefault()
        setIsSubmitting(true)

        createVendorCode(inputs)
            .then(json => {
                setIsSubmitting(false)
                toast.success('Vendor Created')
                navigate(`/vendors`)
            })
            .catch(error => {
                setIsSubmitting(false)

                const { response } = error

                if (response.data.message) {
                    toast.error(response.data.message, { autoClose: false })
                }

                if (response.status === 400) {
                    toast.error(response.data, { autoClose: false })
                }
            })
    }

    if (!ability.can('Create', 'Vendor')) {
        return <InsufficientPermissions />
    }

    return (
        <div className='container card col-xs-12 col-xl-6'>
            <div className='card-body col-xs-12 row d-flex flex-row justify-content-between mb-5'>
                <div className='row flex-row mb-5'>
                    <h3 className='ps-0 d-inline-flex card-title w-50'>
                        Create New Vendor
                    </h3>
                </div>
                <VendorForm
                    handleSubmit={handleSubmit}
                    isSubmitting={isSubmitting} />
            </div>
        </div>
    )
}
