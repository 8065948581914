import React, { useState, useMemo } from 'react'
import { toast } from 'react-toastify'
import { AxiosResponse, AxiosError } from 'axios'

import { getAuthUser } from '../../helpers'
import defineAbilityFor from '../invoices/defineAbility'
import { Loading, TooltipWrapper } from '../common'
import {
    createInvoice,
    CreateInvoiceParams,
    CreateInvoiceResponse,
    toastJsonResults
} from '../../api'

import { Supplier } from '../../models'

export interface CreateInvoiceFormProps {
    supplier: Supplier;
    userId: number;
    records?: number[];
    /** If not null or an empty string, the submit button is disabled and this value is used as the tooltip text */
    disableSubmit?: string;
    /** fires after the form is submitted and confirmed */
    onSubmit?: () => void;
    /** fires after the create request has resolved */
    onSuccess?: (response: AxiosResponse<CreateInvoiceResponse>) => void;
    /** fires after the create request has rejected */
    onError?: (error: AxiosError) => void;
}

interface SelectOption<T extends string> {
    value: T;
    name: string;
    disabledMessage?: string;
}


export const CreateInvoiceForm = (props: CreateInvoiceFormProps) => {

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const ability = defineAbilityFor(getAuthUser())

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const invoiceType = (event.currentTarget.elements.namedItem('invoiceType') as HTMLInputElement).value;
        const deliveryMethod = (event.currentTarget.elements.namedItem('deliveryMethod') as HTMLInputElement).value;
        const message = deliveryMethod === 'preview'
            ? `Create a ${invoiceType} preview?`
            : `Create a ${invoiceType} invoice and deliver by ${deliveryMethod}?`

        if(!window.confirm(message)) {
            return false;
        }

        setIsSubmitting(true);
        createInvoice({
            supplierId: props.supplier.id,
            invoiceType: invoiceType as CreateInvoiceParams['invoiceType'],
            deliveryMethod: deliveryMethod as CreateInvoiceParams['deliveryMethod'],
            records: props.records,
        }).then((response) => {
            toastJsonResults(response);
            if(props.onSuccess) {
                props.onSuccess(response);
            }
        }).catch((err) => {
            toast.error(`Failed to create invoice: ${err?.response?.data?.message || err?.message || 'unknown error'}`, {autoClose: false});
            if(props.onError) {
                props.onError(err);
            }
        }).finally(() => {
            setIsSubmitting(false);
        });
    };

    const invoiceTypes: SelectOption<CreateInvoiceParams['invoiceType']>[] = useMemo(() => [{
            value: 'standard',
            name: 'Standard',
            disabledMessage: !ability.can('Create', 'Invoice')? 'Must have Standard role': undefined

        }, {
            value: 'collections',
            name: 'Collections',
            disabledMessage:
                !props.supplier.isCollections? 'Supplier not eligible for collections':
                !ability.can('Create', 'CollectionsInvoice')? 'Must have Collections Agent role' :
                props.userId !== props.supplier.assignedUser? 'You are not assigned to this supplier': undefined
        }
    ], [ability, props.supplier, props.userId])

    const canCreateAnyInvoice = invoiceTypes.some(type => !type.disabledMessage);

    const disableSubmitMessage =
        isSubmitting ? 'Submitting...' :
            !canCreateAnyInvoice ? 'No invoice type available' :
                props.disableSubmit ||
                (props.supplier.balance === 0 ? 'Cannot invoice supplier with $0.00 balance' :'');

    const deliveryMethods: SelectOption<CreateInvoiceParams['deliveryMethod']>[] = [{
        value: 'preview',
        name: 'Preview'
    }, {
        value: 'email',
        name: 'Email',
        disabledMessage: props.supplier.email.length === 0? 'Supplier is missing email': undefined
    }, {
        value: 'postage',
        name: 'Postage',
        disabledMessage: props.supplier.addresses.length === 0? 'Supplier is missing mailing address': undefined
    }];

    return <form className="createInvoiceForm row" onSubmit={handleSubmit}>
        <div className="col-md-5">
            <label htmlFor="createInvoiceForm-invoiceTypeInput">Type</label>
            <select
                className="form-select"
                id="createInvoiceForm-invoiceTypeInput"
                name="invoiceType">
                {invoiceTypes.map((option) => (
                    <option
                        key={option.value}
                        value={option.value}
                        disabled={!!option.disabledMessage}
                        title={option.disabledMessage}>{option.name}
                    </option>
                ))}
            </select>
        </div>
        <div className="col-md-5">
            <label htmlFor="createInvoiceForm-deliveryMethodInput">Delivery</label>
            <select
                className="form-select"
                id="createInvoiceForm-deliveryMethodInput"
                name="deliveryMethod">
                {deliveryMethods.map((option) => (
                    <option
                        key={option.value}
                        value={option.value}
                        disabled={!!option.disabledMessage}
                        title={option.disabledMessage}>{option.name}
                    </option>
                ))}
            </select>
        </div>
        <div className="col-md-2 d-flex align-items-end justify-content-between">
            <TooltipWrapper
                condition={!!disableSubmitMessage}
                element={
                    <button
                        className="btn btn-primary"
                        disabled={!!disableSubmitMessage}>Create</button>
                }
                message={disableSubmitMessage} />
            {isSubmitting && <div className="mx-2 mb-1"><Loading sm={true} /></div>}
        </div>
    </form>;
};
