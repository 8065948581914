import React, { useState } from 'react';

import {PaymentMethod, PaymentMethods, SupplierStatus} from '../../../models';
import { Loading } from '../../common'

import { TooltipWrapper } from '../../common'
import { Roles, getAuthUser, userHasRole } from '../../../helpers'
import { currencyFormat } from '../../../helpers/currencyCalculation'
import defineAbilityFor from '../defineAbility'

export interface InvoiceInputs {
    checkNumber: string;
    paymentAmount: string;
    paymentMethod: string;
    processDate: string;
}

export interface InvoiceFormProps {
    form: InvoiceInputs;
    paidCtPending: number;
    isSubmitting: boolean;
    unreconciledCount: number;
    modifiedCount: number;

    supplierStatus: SupplierStatus;
    paymentMethods: PaymentMethod[];
    onFormUpdated: (inputs: InvoiceInputs) => void;
    onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

export const InvoiceForm = (props: InvoiceFormProps) => {
    const [showHelp, setShowHelp] = useState(false);

    const isCollectionsAgent = userHasRole(getAuthUser(), Roles.CollectionAgent);
    const collectionStatuses = [SupplierStatus.COLLECTIONS, SupplierStatus.SENT_TO_ATTORNEY];
    const hasRestrictedStatus = collectionStatuses.includes((props.supplierStatus).code);

    const ability = defineAbilityFor(getAuthUser())
    const totalValid = parseFloat(props.form.paymentAmount).toFixed(2) === props.paidCtPending.toFixed(2) || parseInt(props.form.paymentMethod) === PaymentMethods.ForeignCheck;
    const checkNumberValid = (parseInt(props.form.paymentMethod) !== PaymentMethods.Check && parseInt(props.form.paymentMethod) !== PaymentMethods.ForeignCheck)
        || props.form.checkNumber.length > 0;

    const disableSubmitMessage =
        hasRestrictedStatus && !isCollectionsAgent ? 'Only collection agents can reconcile invoices for suppliers in collections.' :
        !ability.can('Update', 'Invoice')? 'Standard permissions required to reconcile invoice':
        !totalValid? `Payment Amount must equal Payment Pending (${currencyFormat(props.paidCtPending)})`:
        !checkNumberValid? 'Check number must be provided for this payment method':
        props.unreconciledCount > 0? 'All records must be marked "reconciled"':
        props.modifiedCount === 0? 'At least one record field must be modified to perform a reconciliation.':
        props.isSubmitting? 'Submitting...': '';

    return <form onSubmit={(e) => props.onSubmit(e)}>
        <div className='row mt-3 d-flex align-items-end'>
            <div className='col-xs-12 col-sm-2 pe-sm-2'>
                <label htmlFor='processDateInput'>Process Date</label>
                <input
                    className='form-control'
                    type='date'
                    id='processDateInput'
                    onChange={(e) => props.onFormUpdated({...props.form, processDate: e.target.value})}
                    value={props.form.processDate}
                    />
            </div>
            <div className='col-xs-12 col-sm-3 pe-sm-2'>
                <label htmlFor='paymentAmountInput'>Payment Amount</label>
                <input
                    className='form-control'
                    id='paymentAmountInput'
                    onChange={(e) => props.onFormUpdated({...props.form, paymentAmount: e.target.value})}
                    onBlur={(e) => props.onFormUpdated({...props.form, paymentAmount: e.target.valueAsNumber.toFixed(2)})}
                    type='number'
                    step={0.01}
                    min={0}
                    value={props.form.paymentAmount}
                    />
            </div>
            <div className='col-xs-12 col-sm-2 pe-sm-2'>
                <label htmlFor='paymentMethodInput'>Payment Method</label>
                <select
                    className='form-select'
                    id='paymentMethodInput'
                    onChange={(e) => props.onFormUpdated({...props.form, paymentMethod: e.target.value})}
                    value={props.form.paymentMethod}>
                    <option value='' disabled={parseFloat(props.form.paymentAmount) !== 0}>N/A</option>
                    {props.paymentMethods.map((method: PaymentMethod) => (
                        <option key={method.id} value={method.id} disabled={parseFloat(props.form.paymentAmount) === 0}>
                            {method.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className='col-xs-12 col-sm-2 col-md-3 pe-sm-2'>
                <label htmlFor='checkNumberInput'>Check Number</label>
                <input
                    className='form-control'
                    disabled={(parseInt(props.form.paymentMethod) !== PaymentMethods.Check
                        && parseInt(props.form.paymentMethod) !== PaymentMethods.ForeignCheck
                    )}
                    id='checkNumberInput'
                    onChange={(e) => props.onFormUpdated({...props.form, checkNumber: e.target.value})}
                    type='text'
                    value={props.form.checkNumber}
                    />
            </div>
            <div className='col-xs-12 col-sm-3 col-md-2 pe-sm-2'>
                <TooltipWrapper
                    condition={disableSubmitMessage !== ''}
                    element={
                        <button
                            className='btn btn-primary'
                            disabled={disableSubmitMessage !== ''}
                            type='submit'>
                            Submit
                            {props.isSubmitting && <span className='ms-2'><Loading sm={true} /></span>}
                        </button>
                    }
                    message={disableSubmitMessage}
                />
                <button type="button" className="btn btn-link p-0 ms-2" onClick={(e) => setShowHelp(!showHelp)}>?</button>
            </div>
        </div>
        {!totalValid &&
            <div className="alert alert-danger mt-3" role="alert">
                Payment Amount must equal Payment Pending ({currencyFormat(props.paidCtPending)})
            </div>
        }
        {hasRestrictedStatus && !isCollectionsAgent &&
            <div className="alert alert-danger mt-3" role="alert">
                Only collection agents can reconcile invoices for suppliers in collections.
            </div>
        }
        {showHelp &&
            <div className="reconciliation-help alert alert-primary mt-3">
                <h3>Invoice Reconicilation Help</h3>
                <p>When reconciliation begins, all Records with "Not Resolved" status are automatically set to "Paid" status.</p>
                <p>Record fields that have been modified since the reconciliation started are highlighted yellow. A record cannot be edited while it is marked "Reconciled".</p>
                <p>Setting <span className="reconciliation-term">Payment Amount</span> to 0 sets the status of all unreconciled records to "Non Commissionable".</p>
                <p>To submit the reconciliation, all records must be marked "reconciled" and <span className="reconciliation-term">Payment Amount</span> must equal <span className="reconciliation-term">Payment Pending</span>. However, <span className="reconciliation-term">Payment Pending</span> does not necessarily need to equal <span className="reconciliation-term">Total Due</span>.
                </p>
                <h4>On submit:</h4>
                <ul>
                    <li>Only modified records are updated.</li>
                    <li><span className="reconciliation-term">Check Number</span> is applied to records with <span className="reconciliation-term">Payment Method</span> modified to "Check" or "Foreign Check".</li>
                    <li>If a record's <span className="reconciliation-term">Payment Method</span> is changed to a non-Check value, the prior <span className="reconciliation-term">Check Number</span> is deleted from the record.</li>
                    <li><span className="reconciliation-term">Process Date</span> is applied to records with a modified <span className="reconciliation-term">Paid CT</span>, <span className="reconciliation-term">Payment Method</span>, or <span className="reconciliation-term">Status</span>.</li>
                    <li>The invoice is marked "Completed" when there are no records with a "billable" status (Not Resolved, Pending Payment, Returned Payment).</li>
                </ul>
                <h4>Terms</h4>
                <ul>
                    <li><span className="reconciliation-term">Original Due</span>: Sum of Commission Due on the original invoice.</li>
                    <li><span className="reconciliation-term">Unbillable</span>: Sum of Commission Due on records with statuses that cannot be billed. When a record is set to an unbillable status its <span className="reconciliation-term">Paid CT</span> is set to 0.00</li>
                    <li><span className="reconciliation-term">Total Due</span>: Sum of Commission Due for all records with billable or paid statuses (Not Resolved, Pending Payment, Returned Payment, Paid, Foreign Payment, Unknown Deposit, Overpayment).</li>
                    <li><span className="reconciliation-term">Payment Processed</span>: Sum of Paid CT for records that were already set to a processed status before reconciliation began and have not been modified.</li>
                    <li><span className="reconciliation-term">Payment Pending</span>: Sum of Paid CT for records with a modified Paid CT since reconciliation began.</li>
                    <li><span className="reconciliation-term">Total Paid</span>: Sum of Paid CT</li>
                </ul>

            </div>
        }

    </form>
};
