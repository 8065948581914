import React, {
    Dispatch,
    FormEvent,
    SetStateAction,
    useEffect,
    useState
} from 'react'
import { toast } from 'react-toastify'
import easternTimezonedDayjs from '../../helpers/dayjsEastern'

import { Roles, getAuthUser, userHasRole } from '../../helpers'
import {
    getChains,
    getPaymentMethods,
    getSupplierStatuses,
    getVendorCodes,
    updateSupplier,
    UpdateSupplierParams
} from '../../api'
import {
    Chain,
    JsonChain,
    JsonPaymentMethod,
    JsonSupplierStatus,
    JsonVendorCode,
    PaymentMethod,
    Supplier,
    SupplierStatus,
    VendorCode
} from '../../models'
import defineAbilityFor from './defineAbility'
import { InsufficientPermissions } from '../common'

interface EditSupplierProps {
    supplier: Supplier
    setIsEditing: Dispatch<SetStateAction<boolean>>
    setSupplier: Dispatch<SetStateAction<Supplier | undefined>>
}

export const EditSupplierForm = (props: EditSupplierProps) => {
    const { supplier, setIsEditing, setSupplier } = props
    const ability = defineAbilityFor(getAuthUser())

    const isCollectionsAgent = userHasRole(getAuthUser(), Roles.CollectionAgent);
    const restrictedStatuses = [SupplierStatus.COLLECTIONS, SupplierStatus.SENT_TO_ATTORNEY];

    const defaultInputs = {
        chain: supplier?.chain?.id ?? '',
        email: supplier?.email ?? '',
        fax: supplier?.fax ?? '',
        paymentMethod: supplier?.preferredPaymentMethod?.id ?? '',
        name: supplier?.name ?? '',
        reopenDate: supplier.reopenDate?.format('YYYY-MM-DD') ?? '',
        status: supplier?.status.code ?? SupplierStatus.BILLABLE,
        substatus: supplier?.substatus?.code ?? undefined,
        vendorId: supplier?.vendorId ?? '',
        dba: supplier?.dba ?? ''
    }

    const [inputs, setInputs] = useState<UpdateSupplierParams>(defaultInputs)
    const [chains, setChains] = useState([])
    const [paymentMethods, setPaymentMethods] = useState([])
    const [supplierStatuses, setSupplierStatuses] = useState<SupplierStatus[]>([]);
    const [vendorCodes, setVendorCodes] = useState([])

    const visibleStatuses = supplierStatuses ? supplierStatuses.filter(status => {
        if (restrictedStatuses.includes(status.code)) {
            return isCollectionsAgent;
        }
        return true;
    }) : [];

    useEffect(() => {
        getChains().then(json => {
            setChains(json.data.map((chain: JsonChain) => new Chain(chain)))
        })

        getPaymentMethods().then(json => {
            setPaymentMethods(json.data.map(
                (paymentMethod: JsonPaymentMethod) => new PaymentMethod(paymentMethod))
            )
        })

        getSupplierStatuses()
            .then(json => setSupplierStatuses(
                json.data.map((status: JsonSupplierStatus) => new SupplierStatus(status))
            ))

        getVendorCodes().then(json => {
            setVendorCodes(json.data.map(
                (vendorCode: JsonVendorCode) => new VendorCode(vendorCode))
            )
        })
    }, [])

    const updateInputs = (field: string, value: number | string | undefined) => {
        let newInputs = {
            ...inputs,
            [field]: value,
        }

        if (field === 'status') {
            const selectedStatus = supplierStatuses.find(s => s.code === Number(value))
            const hasSubstatusesForSelectedStatus = !!selectedStatus?.substatuses?.length

            if (!hasSubstatusesForSelectedStatus) {
                newInputs = { ...newInputs, substatus: undefined }
            }
        }

        setInputs(newInputs)
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        updateSupplier(supplier.id.toString(), inputs)
            .then(json => {
                setSupplier(new Supplier(json.data))
                setIsEditing(false)
                toast.success('Supplier Edited')
            })
            .catch(error => {
                const { response } = error

                if (response.data.message) {
                    toast.error(response.data.message, { autoClose: false })
                }

                if (response.status === 400 || response.status === 403) {
                    toast.error(response.data, { autoClose: false })
                }
            })
    }

    const hasSubstatuses = !!supplierStatuses.find(s => s.code === Number(inputs.status))?.substatuses?.length

    if (!ability.can('Edit', 'Supplier')) {
        return <InsufficientPermissions />
    }

    return (
        <form className='row mb-4' onSubmit={(e) => handleSubmit(e)}>
            <div className='row mb-4'>
                <div className='col-12'>
                    <label htmlFor='statusInput'>Status</label>
                    <select
                        className='form-select'
                        disabled={!ability.can('Edit', 'SupplierStatus')}
                        id='statusInput'
                        onChange={(e) => updateInputs('status', +e.target.value)}
                        value={inputs.status}>
                        {visibleStatuses.map((status: SupplierStatus) => (
                            <option key={status.code} value={status.code}>
                                {status.name}
                            </option>
                        ))}
                    </select>
                    {hasSubstatuses && (
                        <>
                            <label htmlFor='substatusInput'>Substatus</label>
                            <select
                                className='form-select'
                                id='substatusInput'
                                onChange={(e) => updateInputs('substatus', +e.target.value)}
                                value={inputs.substatus}>
                                <option value=''>None</option>
                                {supplierStatuses.find(s => s.code === Number(inputs.status))?.substatuses?.map(substatus => (
                                    <option key={substatus.code} value={substatus.code}>
                                        {substatus.name}
                                    </option>
                                )) || []}
                            </select>
                        </>
                    )}
                    <label htmlFor='nameInput'>Name</label>
                    <input
                        className='form-control'
                        id='nameInput'
                        onChange={(e) => updateInputs(
                            'name',
                            e.target.value
                        )}
                        type='text'
                        required
                        value={inputs.name} />
                    <label htmlFor='dbaInput'>Doing Business As</label>
                    <input
                        className='form-control'
                        id='dbaInput'
                        onChange={(e) => updateInputs('dba', e.target.value)}
                        value={inputs.dba} />
                    <label htmlFor='chainInput'>Chain</label>
                    <select
                        className='form-select'
                        id='chainInput'
                        onChange={(e) => updateInputs('chain', e.target.value)}
                        value={inputs.chain}>
                        <option value=''>
                            No Chain
                        </option>
                        {chains.map((chain: Chain) => (
                            <option
                                key={`${chain.abbreviation}_${chain.code}`}
                                value={chain.id}>
                                {chain.name}
                            </option>
                        ))}
                    </select>
                    <label htmlFor='emailInput'>Email</label>
                    <input
                        className='form-control'
                        id='emailInput'
                        onChange={(e) => updateInputs(
                            'email',
                            e.target.value
                        )}
                        type='text'
                        value={inputs.email} />
                    <label htmlFor='faxInput'>Fax</label>
                    <input
                        className='form-control'
                        id='faxInput'
                        onChange={(e) => updateInputs(
                            'fax',
                            e.target.value
                        )}
                        type='text'
                        value={inputs.fax} />
                    <label htmlFor='vendorCodeInput'>Vendor Code</label>
                    <select
                        className='form-select'
                        id='vendorCodeInput'
                        onChange={(e) => updateInputs('vendorId', e.target.value)}
                        value={inputs.vendorId}>
                        <option
                            key={''}
                            value={''}>
                            No Vendor Code
                        </option>
                        {vendorCodes.map((vendorCode: VendorCode) => (
                            <option
                                key={vendorCode.id}
                                value={vendorCode.id}>
                                {vendorCode.code}
                            </option>
                        ))}
                    </select>
                    <label htmlFor='paymentMethodInput'>
                        Preferred Payment Method
                    </label>
                    <select
                        className='form-select'
                        id='paymentMethodInput'
                        onChange={(e) => updateInputs('paymentMethod', e.target.value)}
                        value={inputs.paymentMethod}>
                        <option value=''>
                            Unknown
                        </option>
                        {paymentMethods.map((paymentMethod: PaymentMethod) => (
                            <option
                                key={`payment_method_${paymentMethod.id}`}
                                value={paymentMethod.id}>
                                {paymentMethod.name}
                            </option>
                        ))}
                    </select>
                    {inputs.status === SupplierStatus.TEMP_CLOSED &&
                    <>
                    <label htmlFor='reopenDateInput'>
                        Reopen Date
                    </label>
                    <input
                        className='form-control'
                        id='reopenDateInput'
                        type='date'
                        min={easternTimezonedDayjs().format('YYYY-MM-DD')}
                        onChange={(e) => updateInputs('reopenDate', e.target.value)}
                        required
                        value={inputs.reopenDate} />
                    </>
                    }
                </div>
            </div>
            <div className='row mb-5'>
                <div className='col-12 d-flex justify-content-center'>
                    <button
                        className='btn btn-primary'
                        type='submit'>
                        Submit
                    </button>
                </div>
            </div>
        </form>
    )
}
