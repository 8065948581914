import { JsonModel, Model } from './model'

export class AgencyArc extends Model {
    agencyId: string
    arc: string

    constructor(json: JsonAgencyArc) {
        super(json)

        this.agencyId = json.agencyId
        this.arc = json.arc
    }
}

export interface JsonAgencyArc extends JsonModel {
    agencyId: string
    arc: string
}
