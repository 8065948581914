import React, { createContext, Fragment, useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { getUser } from '../../api'
import { User } from '../../models'
import {
    AddAgencyForm,
    AddChainForm,
    AddRecordForm,
    AddSupplierForm,
    AddVendorForm,
    AgenciesLanding,
    AgenciesUnknownSupplierLanding,
    AgencyComponent,
    AgencyDataUpload,
    ChainCard,
    ChainsLanding,
    CountriesLanding,
    CountryCard,
    CtsDataUpload,
    VtsArcDataUpload,
    DailyPaymentsReport,
    ForeignConversionReport,
    GenerateInvoicesLanding,
    InvalidSupplier,
    InvoiceComponent,
    InvoiceReconciliationSearch,
    InvoicesLanding,
    PaymentsReport,
    QuickbooksLanding,
    RecordsLanding,
    RecordsAssignedToUser,
    ReportsLanding,
    RecordComponent,
    RegisterUser,
    SupplierComponent,
    SupplierDataUpload,
    SuppliersLanding,
    SupplierBalanceLanding,
    SupplierCollectionsLanding,
    SuppliersPendingPaymentLanding,
    UnknownSupplier,
    UserCard,
    UsersLanding,
    DeleteRecordsLanding,
    VendorsLanding
} from '../'
import { Home } from './home'
import { Navbar } from './navbar'
import { NotFound } from '../common'

interface RouteItem {
    path: string
    component: JSX.Element
}

export const routes: RouteItem[] = [
    {
        path: '/',
        component: <Home />
    },
    {
        path: '/agencies',
        component: <AgenciesLanding />
    },
    {
        path: '/agencies/unknownSupplier',
        component: <AgenciesUnknownSupplierLanding />
    },
    {
        path: '/agencies/add',
        component: <AddAgencyForm />
    },
    {
        path: '/agencies/dataUpload',
        component: <AgencyDataUpload />
    },
    {
        path: '/agencies/ctsUpload',
        component: <CtsDataUpload />
    },
    {
        path: '/agencies/vtsArcUpload',
        component: <VtsArcDataUpload />
    },
    {
        path: '/agencies/:agencyId',
        component: <AgencyComponent />
    },
    {
        path: '/agency-reconciliation-reports',
        component: <ReportsLanding />
    },
    {
        path: '/chains',
        component: <ChainsLanding />
    },
    {
        path: '/chains/:chainId',
        component: <ChainCard />
    },
    {
        path: '/chains/add',
        component: <AddChainForm />
    },
    {
        path: '/countries',
        component: <CountriesLanding />
    },
    {
        path: '/countries/:countryId',
        component: <CountryCard />
    },
    {
        path: '/invoices',
        component: <InvoicesLanding  />
    },
    {
        path: '/invoices/generate',
        component: <GenerateInvoicesLanding />
    },
    {
        path: '/invoices/:invoiceId',
        component: <InvoiceComponent />
    },
    {
        path: '/invoices/reconciliation/:invoiceId',
        component: <InvoiceReconciliationSearch />
    },
    {
        path: '/records',
        component: <RecordsLanding />
    },
    {
        path: '/records/assignedToMe',
        component: <RecordsAssignedToUser />
    },
    {
        path: '/records/foreignConversion',
        component: <ForeignConversionReport />
    },
    {
        path: '/payments',
        component: <PaymentsReport />
    },
    {
        path: '/daily-payments',
        component: <DailyPaymentsReport />
    },
    {
        path: '/quickbooks',
        component: <QuickbooksLanding />
    },
    {
        path: '/records/invalidSupplier',
        component: <InvalidSupplier />
    },
    {
        path: '/records/unknownSupplier',
        component: <UnknownSupplier />
    },
    {
        path: '/records/add',
        component: <AddRecordForm />
    },
    {
        path: '/records/delete',
        component: <DeleteRecordsLanding />
    },
    {
        path: '/records/:recordId',
        component: <RecordComponent />
    },
    {
        path: '/register',
        component: <RegisterUser />
    },
    {
        path: '/suppliers',
        component: <SuppliersLanding />
    },
    {
        path: '/suppliers/add',
        component: <AddSupplierForm />
    },
    {
        path: '/suppliers/balances',
        component: <SupplierBalanceLanding />
    },
    {
        path: '/suppliers/collections',
        component: <SupplierCollectionsLanding />
    },
    {
        path: '/suppliers/dataUpload',
        component: <SupplierDataUpload />
    },
    {
        path: '/suppliers/pendingPayment',
        component: <SuppliersPendingPaymentLanding />
    },
    {
        path: '/suppliers/:supplierId',
        component: <SupplierComponent />
    },
    {
        path: '/users',
        component: <UsersLanding />
    },
    {
        path: '/users/:userId',
        component: <UserCard />
    },
    {
        path: '/vendors',
        component: <VendorsLanding />
    },
    {
        path: '/vendors/add',
        component: <AddVendorForm />
    },
]

export const AuthUserContext = createContext<User>({} as User)

export const Router = () => {
    const [authUser, setAuthUser] = useState<User>()

    const authUserId = document
        .querySelector("meta[name='auth-user-id']")
        ?.getAttribute('content');

    useEffect(() => {
        if (authUserId) {
            getUser(authUserId)
                .then(response => {
                    if (response.status === 200) {
                        setAuthUser(new User(response.data))
                    }
                })
        }
    }, [authUserId])

    if (!(authUser instanceof User)) {
        return <Fragment />
    }

    return (
        <React.StrictMode>
            <AuthUserContext.Provider value={authUser}>
                <BrowserRouter>
                    <Navbar />
                    <ToastContainer />
                    <Routes>
                        {routes.map(route =>
                            <Route key={route.path} path={route.path} element={route.component} />
                        )}
                        <Route path="*" element={<NotFound />} />  {/* This is the catch-all route */}
                    </Routes>
                </BrowserRouter>
            </AuthUserContext.Provider>
        </React.StrictMode>
    )
}
