import { defineAbility } from '@casl/ability'

import { User } from '../../models'
import { Roles, userHasRole } from '../../helpers'

export default (user: User) => defineAbility((can) => {
    if (userHasRole(user, Roles.Executive)) {
        can('Edit', 'Vendor')
        can('Delete', 'Vendor')
    }

    if (userHasRole(user, Roles.SrLead)) {
        can('Create', 'Vendor')
        can('View', 'Vendor')
    }
})
