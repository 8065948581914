import React, { FormEvent, useState } from 'react'
import { toast } from 'react-toastify'

import { getRecordsByPhone } from '../../../api'
import { JsonRecord, Record } from '../../../models'
import { Loading } from '../../common'
import { BatchDeleteRecordForm } from './batch-form'
import { getAuthUser } from '../../../helpers';
import defineAbilityFor from '../defineAbility'
import { InsufficientPermissions } from '../../common';

enum PageState {
    Search,
    Loading,
    Editing,
    Forbidden
}

export const DeleteRecordsLanding = () => {
    const ability = defineAbilityFor(getAuthUser())

    const [phone, setPhone] = useState('')
    const [records, setRecords] = useState<Record[] | undefined>()

    const defaultPageState = ability.can('Delete', 'Record')
        ? PageState.Search
        : PageState.Forbidden

    const [pageState, setPageState] = useState(defaultPageState)

    const findRecords = (phone: string) => {
        setPageState(PageState.Loading)

        getRecordsByPhone(phone)
            .then(response => {
                const { status, data } = response

                if (status === 200) {
                    if (data.length > 0) {
                        setPageState(PageState.Editing)
                        setRecords(response.data.map((record: JsonRecord) => new Record(record)))
                    } else {
                        setPageState(PageState.Search)
                        toast.error(`No records found for phone number ${phone}`, { autoClose: false })
                    }
                }
            })
            .catch(error => {
                setPageState(PageState.Search)

                if (error.response.status === 404) {
                    toast.error('Records Not Found', { autoClose: false })
                }
            })
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        findRecords(phone)
    }

    switch (pageState) {
        case PageState.Forbidden:
            return <InsufficientPermissions />
        case PageState.Loading:
            return (
                <div className='container d-flex justify-content-center'>
                    <Loading />
                </div>
            )
        case PageState.Editing:
            if (records !== undefined) {
                return (
                    <BatchDeleteRecordForm
                        phone={phone}
                        records={records}
                        resetForm={() => {
                            setPageState(PageState.Search)
                            setPhone('')
                        }} />
                )
            }
        case PageState.Search:
        default:
            return (
                <div className='container'>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-xs-12 col-md-3'>
                            <h3>Search for Record</h3>
                            <form onSubmit={(e) => handleSubmit(e)}>
                                <input
                                    className='form-control mb-3'
                                    onChange={(e) => setPhone(e.target.value)}
                                    placeholder='Phone'
                                    type='text'
                                    value={phone} />
                                <button className='btn btn-primary' type='submit'>
                                    Find Records
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            )
    }
}
