import React from 'react'
import { Link } from 'react-router-dom'

import { Supplier } from '../../../models'
import { PaginatedTableContract } from '../../common'

interface SuppliersTableProps extends PaginatedTableContract {
    resources: {
        suppliers: Supplier[]
    }
}

export const InvoiceSuppliersTable = (props: SuppliersTableProps) => {
    const { resources } = props
    const { suppliers } = resources
    return (
        <table className='table'>
            <tbody>
                <tr>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Street</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Zip</th>
                    <th>Country</th>
                    <th>Last Invoice</th>
                    <th>Generated</th>
                </tr>
                {suppliers.map(supplier =>
                    <tr key={supplier.id}>
                        <td>
                            <Link to={`/suppliers/${supplier.id}`}>
                                {supplier.name}
                            </Link>
                        </td>
                        <td>{supplier.primaryPhone}</td>
                        <td>{supplier.physicalAddress.street}</td>
                        <td>{supplier.physicalAddress.city}</td>
                        <td>{supplier.physicalAddress.state?.abbreviation}</td>
                        <td>{supplier.physicalAddress.zip}</td>
                        <td>{supplier.physicalAddress.country?.name}</td>
                        <td>{supplier?.lastInvoiceId ?? 'No Invoices Found'}</td>
                        <td>{supplier?.lastInvoiceDate?.toISOString().substring(0, 10) ?? 'No Invoices Found'}</td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}
