import { JsonCountry, Country } from './country'
import { JsonModel, Model } from './model'
import { JsonState, State } from './state'

export enum ValidationStatuses {
    Unvalidated,
    Valid,
    Invalid
}

export class Address extends Model {
    country: Country | null
    street: string
    street2: string
    city: string
    state: State | null
    zip: string
    isBillable: boolean
    isBilling: boolean
    isInvalid: boolean
    isPayment: boolean
    isPhysical: boolean
    isUnvalidated: boolean
    isValid: boolean
    manualValidationStatus: ValidationStatuses
    uspsValidationStatus: ValidationStatuses

    constructor(json: JsonAddress) {
        super(json)

        this.country = json.country ? new Country(json.country) : null
        this.street = json.street
        this.street2 = json.street2
        this.city = json.city
        this.state = json.state ? new State(json.state) : null
        this.zip = json.zip
        this.isBilling = json.isBilling
        this.isPayment = json.isPayment
        this.isPhysical = json.isPhysical
        this.manualValidationStatus = json.manualValidationStatus
        this.uspsValidationStatus = json.uspsValidationStatus

        /**
         * Manual validation should always take precedence over USPS validation
         */
        this.isValid = this.manualValidationStatus === ValidationStatuses.Valid
            || (this.uspsValidationStatus === ValidationStatuses.Valid
                && this.manualValidationStatus === ValidationStatuses.Unvalidated
            )
        this.isUnvalidated = this.uspsValidationStatus === ValidationStatuses.Unvalidated
            && this.manualValidationStatus === ValidationStatuses.Unvalidated
        this.isInvalid = !this.isValid && !this.isUnvalidated

        this.isBillable = this.isValid
    }
}

export interface JsonAddress extends JsonModel {
    country: JsonCountry | null
    street: string
    street2: string
    city: string
    state: JsonState | null
    zip: string
    isBilling: boolean
    isPayment: boolean
    isPhysical: boolean
    manualValidationStatus: ValidationStatuses
    uspsValidationStatus: ValidationStatuses
}
