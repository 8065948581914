import { JsonModel, Model } from './model'
import { JsonRole, Role } from './role'

export class User extends Model {
    assignedRecordCount: number
    assignedSupplierCount: number
    name: string
    email: string
    is_active: boolean
    roles: Role[]

    constructor(json: JsonUser) {
        super(json)

        this.assignedRecordCount = json.assigned_record_count
        this.assignedSupplierCount = json.assigned_supplier_count
        this.name = json.name
        this.email = json.email
        this.is_active = json.is_active
        this.roles = json.roles.map(role => new Role(role))
    }
}

export interface JsonUser extends JsonModel {
    assigned_supplier_count: number
    assigned_record_count: number
    name: string
    email: string
    is_active: boolean
    roles: JsonRole[]
}
