import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { Record } from '../../../models'
import { isLocalPhone } from '../../../helpers'

interface RowProps {
    onSubmit: (oldValue: string, newValue: string) => void
    record: Record
}

export const InvalidSupplierRow = (props: RowProps) => {
    const { onSubmit, record } = props

    const [input, setInput] = useState(record.phone)
    const [isValid, setIsValid] = useState(true)

    return (
        <tr key={record.id}>
            <td><Link to={`/records/${record.id}`}>{record.id}</Link></td>
            <td>{record.supplier?.name}</td>
            <td>{record.supplier?.physicalAddress.street}</td>
            <td>{record.supplier?.physicalAddress.city}</td>
            <td>{record.supplier?.physicalAddress.state?.name}</td>
            <td>{record.supplier?.physicalAddress.country?.abbreviation}</td>
            <td>
                <input
                    className='form-control'
                    onChange={(e) => {
                        setInput(e.target.value)
                        setIsValid(isLocalPhone(e.target.value))
                    }}
                    type='text'
                    value={input ?? ''} />
                {!isValid &&
                    <div className='invalid-feedback d-block'>
                        Enter local phone number
                    </div>
                }
            </td>
            <td>
                <button
                    className='btn btn-primary'
                    disabled={record.phone.length === 0}
                    onClick={() => onSubmit(record.phone, input)}>
                    Update Phone
                </button>
            </td>
        </tr>
    )
}
