import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'

import { getInvoice } from '../../api'
import { downloadInvoice } from '../../api/routes'
import { Loading } from '../common'
import { Invoice } from '../../models'
import { getAuthUser } from '../../helpers';
import defineAbilityFor from './defineAbility'
import { InsufficientPermissions } from '../common';
import { currencyFormat } from '../../helpers/currencyCalculation'
import { CreateInvoiceForm } from './createInvoiceForm';

enum PageState {
    Default,
    Loading,
    Found,
    NotFound,
    Error
}

export const InvoiceComponent = () => {
    const { invoiceId } = useParams()
    const navigate = useNavigate();

    const [invoice, setInvoice] = useState<Invoice | undefined>()
    const [pageState, setPageState] = useState(PageState.Default)

    // indicates that the user is selecting records to include in a regenerated invoice
    const [isCloningInvoice, setIsCloningInvoice] = useState(false);
    const [selectedRecords, setSelectedRecords] = useState<{[id: string]: boolean}>({});
    // indicates if the createInvoice request is in progress
    const [isCloneSubmitting, setIsCloneSubmitting] = useState(false);

    const user = getAuthUser();

    const ability = defineAbilityFor(user)

    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    const sortedRecords = React.useMemo(() => {
        if (!invoice) return [];
        return [...invoice.records].sort((a, b) => {
            if (a.statusName < b.statusName) return sortDirection === 'asc' ? -1 : 1;
            if (a.statusName > b.statusName) return sortDirection === 'asc' ? 1 : -1;
            return 0;
        });
    }, [invoice?.records, sortDirection]);

    const toggleSort = () => {
        setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    };

    useEffect(() => {
        setIsCloningInvoice(false);
        setSelectedRecords({});

        if (invoiceId && ability.can('View', 'Invoice')) {
            setPageState(PageState.Loading)

            getInvoice(invoiceId)
                .then(response => {
                    setPageState(PageState.Found)
                    setInvoice(new Invoice(response.data.invoice, response.data.records))
                })
                .catch(error => {
                    setPageState(PageState.Error)

                    const { response } = error

                    if (response?.status === 404) {
                        setPageState(PageState.NotFound)
                    }
                })
        }
    }, [invoiceId])

    if (!ability.can('View', 'Invoice')) {
        return <InsufficientPermissions />
    }

    const handleRecordSelected = (id: number | undefined, selected: boolean) => {
        if(!invoice) {
            return;
        }

        if(id == undefined) {
            if(selected) {
                setSelectedRecords(invoice.records.reduce((obj, r) => {
                    obj[r.id] = true;
                    return obj;
                }, {} as {[id: string]: boolean}));
            }
            else {
                setSelectedRecords({});
            }

            return;
        }

        const newSelectedRecords = {
            ...selectedRecords,
        };

        if(selected) {
            newSelectedRecords[id] = true;
        }
        else {
            delete newSelectedRecords[id];
        }

        setSelectedRecords(newSelectedRecords);
    };

    switch (pageState) {
        case PageState.NotFound:
            return (
                <div className='container'>
                    <h3>Invoice Not Found</h3>
                </div>
            )
        case PageState.Found:
            if (invoice instanceof Invoice) {

                return (
                    <div className='container'>
                        <div className='row'>
                            <div className='d-flex col'>
                                <div>
                                    <h3 className='d-inline-block mb-2 me-4'>
                                        Invoice #{invoice.id}
                                        <a href={downloadInvoice.replace('{invoiceId}', invoice.id.toString())}
                                            className="pdf-download"
                                            download>
                                            <sup>PDF</sup>
                                        </a>
                                    </h3>
                                    <Link
                                        to={`/invoices/reconciliation/${invoice.id}`}
                                        className='btn btn-primary me-3'>
                                        Reconcile
                                    </Link>
                                </div>
                                <div className="d-flex">
                                    <div>
                                        <button
                                            className={`btn me-1 ${isCloningInvoice && 'btn-danger' || 'btn-primary'}`}
                                            disabled={isCloneSubmitting}
                                            type='button'
                                            onClick={(e) => setIsCloningInvoice(!isCloningInvoice)}>
                                            {(isCloningInvoice && 'Cancel') || 'Clone Invoice'}
                                        </button>
                                    </div>
                                    <div className="d-flex align-items-end" style={{visibility: isCloningInvoice? 'visible': 'hidden'}}>
                                        <div className="px-3 py-2">Records selected: {Object.keys(selectedRecords).length}</div>
                                        <CreateInvoiceForm
                                            supplier={invoice.supplier}
                                            userId={user.id}
                                            records={Object.keys(selectedRecords).map((id) => parseInt(id))}
                                            disableSubmit={Object.keys(selectedRecords).length === 0? 'Select at least one record to include in the new invoice': undefined}
                                            onSubmit={() => setIsCloneSubmitting(true)}
                                            onSuccess={(response) => {
                                                setIsCloneSubmitting(false)
                                                navigate(`/invoices/${response.data.invoice.id}`)
                                            }}
                                            onError={() => setIsCloneSubmitting(false)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isCloningInvoice &&
                            <div className="row">
                                <div className="col d-flex justify-content-end">
                                    <div>
                                    </div>
                                </div>
                            </div>}
                        <div className='row'>
                            <div className='col'>
                                <table className='table table-sm'>
                                    <thead>
                                        <tr>
                                            {isCloningInvoice &&
                                                <th>
                                                    <input
                                                        className="form-check-input"
                                                        onChange={(e) => handleRecordSelected(undefined, e.target.checked)}
                                                        type="checkbox"
                                                        checked={Object.keys(selectedRecords).length > 0}
                                                    />
                                                </th>}
                                            <th>Record</th>
                                            <th>Agency</th>
                                            <th>ARC</th>
                                            <th>Confirmation Number</th>
                                            <th>Name</th>
                                            <th>Check In</th>
                                            <th>Nights</th>
                                            <th>Nightly Rate</th>
                                            <th>Commission Due</th>
                                            <th>Paid CT</th>
                                            <th>Payment Method</th>
                                            <th onClick={toggleSort} style={{cursor: 'pointer'}}>
                                                Status {sortDirection === 'asc' ? '▲' : '▼'}
                                            </th>
                                            <th>Substatus</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sortedRecords.map(record => (
                                            <tr key={record.id}>
                                                {isCloningInvoice &&
                                                    <td>
                                                        <input
                                                            checked={selectedRecords[record.id] === true}
                                                            className='form-check-input'
                                                            onChange={(e) => handleRecordSelected(record.id, e.target.checked)}
                                                            disabled={isCloneSubmitting}
                                                            type='checkbox'
                                                        />
                                                    </td>}
                                                <td>
                                                    <Link to={`/records/${record.id}`}>
                                                        {record.id}
                                                    </Link>
                                                </td>
                                                <td>{record.agencyName}</td>
                                                <td>{record.arc}</td>
                                                <td>{record.confirmationNumber}</td>
                                                <td>{record.guestLastName}, {record.guestFirstName}</td>
                                                <td>{record.startDate.format('YYYY-MM-DD')}</td>
                                                <td>{record.days}</td>
                                                <td>
                                                    {currencyFormat(record.rate)}
                                                </td>
                                                <td>
                                                    {currencyFormat(record.commission)}
                                                </td>
                                                <td>
                                                    {currencyFormat(record.paidCt)}
                                                </td>
                                                <td>{record.paymentMethodName ?? 'N/A'}</td>
                                                <td>{record.statusName}</td>
                                                <td>{record.substatusName}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <h4 className='mb-5 d-flex justify-content-end align-items-end'>
                                    Total records: {invoice.records.length}
                                </h4>
                            </div>
                        </div>
                    </div>
                )
            }
        case PageState.Default:
        case PageState.Loading:
        default:
            return (
                <div className='container'>
                    <Loading />
                </div>
            )
    }
}
