import React from 'react'

export const InsufficientPermissions = () => (
    <div className='container'>
        <div className='col-xs-12'>
            <div className='row'>
                <h1 className='alert alert-danger text-center'>
                    You do not have permission to access this page
                </h1>
            </div>
        </div>
    </div>
)
