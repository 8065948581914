import React, { useState } from 'react'
import { Invoice, SupplierCollectionsContact } from '../../../models'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { currencyFormat } from '../../../helpers/currencyCalculation'
import {
    SupplierCollectionsContactParams,
    updateSupplierCollectionsContacts
} from '../../../api'
import { HiddenSpan, TooltipWrapper, TrDisplayHiddenSpanOnHover } from 'components/common'
import defineAbilityFor from '../defineAbility'
import { getAuthUser } from '../../../helpers'
import { toast } from 'react-toastify'
import { ContactForm } from './contactForm'

interface CardProps {
    invoices: Invoice[]
    supplierContacts: SupplierCollectionsContact[]
    supplierId: number
    onSupplierContactsChanged: (contacts: SupplierCollectionsContact[]) => void
}

export const SupplierInvoicesCard = ({ invoices, supplierContacts, supplierId, onSupplierContactsChanged }: CardProps) => {
    const [isAdding, setIsAdding] = useState(false)
    const [contactToEdit, setContactToEdit] = useState<SupplierCollectionsContact | null>(null)

    const ability = defineAbilityFor(getAuthUser())

    const handleDelete = (contact: SupplierCollectionsContact) => {
        const updatedContacts = supplierContacts.filter(c => c.id() !== contact.id())
        updateAndHandleContacts(updatedContacts)
    }

    const handleEdit = (contact: SupplierCollectionsContact) => setContactToEdit(contact)

    const handleFormSubmit = (updatedContact: SupplierCollectionsContactParams) => {
        let updatedContacts = [...supplierContacts]

        if (contactToEdit) {
            updatedContacts = updatedContacts.map(contact =>
                contact.id() === contactToEdit.id() ? new SupplierCollectionsContact(updatedContact) : contact
            );
        } else {
            updatedContacts.push(updatedContact as SupplierCollectionsContact)
        }

        updateAndHandleContacts(updatedContacts)
    }

    const updateAndHandleContacts = (updatedContacts: SupplierCollectionsContact[]) => {
        updateSupplierCollectionsContacts(supplierId, updatedContacts)
            .then((response) => {
                const newContacts = response.data.contacts.map((contact) => new SupplierCollectionsContact(contact));
                onSupplierContactsChanged(newContacts);
                setContactToEdit(null)
                setIsAdding(false)
                toast.success(`Contact ${contactToEdit ? 'Updated' : isAdding? 'Created': 'Deleted'}`)
            })
            .catch((error) => {
                toast.error(error.response?.data?.message || `An error occurred while ${contactToEdit ? 'updating' : isAdding? 'creating': 'deleting'} the contact`, {autoClose: false});
            })
    }

    return (
        <div className="container card mb-2">
            <div className="card-body row d-flex flex-row justify-content-between">
                <div className="row flex-row mb-2 pe-0">
                    <h3 className="ps-0 d-inline-flex card-title w-50">Collections</h3>
                    <div className="pe-0 d-inline-flex justify-content-end w-50">
                        {!isAdding && !contactToEdit && (
                            <TooltipWrapper
                                condition={supplierContacts.length >= 3}
                                element={
                                    <button
                                        className="btn btn-primary"
                                        disabled={!ability.can('Create', 'CollectionsContact') || supplierContacts.length >= 3}
                                        onClick={() => setIsAdding(true)}
                                    >
                                        Add New Contact
                                    </button>
                                }
                                message='Maximum number of contacts reached.'
                            />
                        )}
                        {(contactToEdit || isAdding) && (
                            <button
                                className="btn btn-outline-secondary"
                                onClick={() => {
                                    setContactToEdit(null);
                                    setIsAdding(false);
                                }}
                            >
                                Cancel
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <div className="row flex-row p-0">
                <h5>Contacts</h5>
                <table className="table">
                    <tbody>
                        <tr>
                            <th>Title</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th></th>
                        </tr>
                        {!isAdding && !contactToEdit && supplierContacts.length > 0 ? (
                            supplierContacts.map((contact) => (
                                <TrDisplayHiddenSpanOnHover key={contact.id()} primary={false}>
                                    <td>{contact.title}</td>
                                    <td>{contact.name}</td>
                                    <td>{contact.phone}</td>
                                    <td>{contact.email}</td>
                                    <td>
                                        <HiddenSpan className="d-flex mb-2 mt-2 align-items-end">
                                            <TooltipWrapper
                                                condition={!ability.can('Edit', 'CollectionsContact')}
                                                element={
                                                    <button
                                                        className="btn btn-primary btn-sm mt-auto ms-1"
                                                        disabled={!ability.can('Edit', 'CollectionsContact')}
                                                        onClick={() => handleEdit(contact)}
                                                    >
                                                        Edit
                                                    </button>
                                                }
                                                message={'Insufficient permissions'}
                                            />
                                            <TooltipWrapper
                                                condition={!ability.can('Delete', 'CollectionsContact')}
                                                element={
                                                    <button
                                                        className="btn btn-outline-danger btn-sm ms-1"
                                                        disabled={!ability.can('Delete', 'CollectionsContact')}
                                                        onClick={() => handleDelete(contact)}
                                                    >
                                                        Delete
                                                    </button>
                                                }
                                                message={'Insufficient permissions'}
                                            />
                                        </HiddenSpan>
                                    </td>
                                </TrDisplayHiddenSpanOnHover>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={5}>{isAdding || contactToEdit ? '' : 'No contacts found'}</td>
                            </tr>
                        )}
                        {(isAdding || contactToEdit) && (
                            <tr>
                                <td colSpan={5}>
                                    <ContactForm
                                        initialInputs={{
                                            name: contactToEdit ? contactToEdit.name : '',
                                            title: contactToEdit ? contactToEdit.title : '',
                                            phone: contactToEdit ? contactToEdit.phone : '',
                                            email: contactToEdit ? contactToEdit.email : '',
                                        }}
                                        handleSubmit={handleFormSubmit}
                                        title={contactToEdit ? 'Edit Contact' : 'Add Contact'}
                                        supplierContactId={contactToEdit ? contactToEdit.id() : undefined}
                                    />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <h5>Invoices</h5>
                <table className="table">
                    <tbody>
                        {invoices.length === 0 ? (
                            <tr>
                                <td colSpan={4}>No invoice found</td>
                            </tr>
                        ) : (
                            <>
                                <tr>
                                    <th>Completed</th>
                                    <th>Invoice ID</th>
                                    <th>Total</th>
                                    <th>Last Updated</th>
                                </tr>
                                {invoices.map((invoice) => (
                                    <tr key={invoice.id}>
                                        <td>{invoice.completed ? 'Yes' : 'No'}</td>
                                        <td>
                                            <Link to={`/invoices/${invoice.id}`}>
                                                {invoice.id}
                                            </Link>
                                        </td>
                                        <td>{currencyFormat(invoice.total)}</td>
                                        <td>{dayjs(invoice.updated_at).format('MMM DD, YYYY')}</td>
                                    </tr>
                                ))}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
