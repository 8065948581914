import React from 'react'

import { PaginatedTable, PaginatedTableCallbacks } from '../common'
import { SuppliersTable } from './collectionTable'
import { JsonSupplier, Supplier } from '../../models'
import { GetSupplierParams, getSuppliers } from '../../api'

export const SuppliersPendingPaymentLanding = () => (
    <PaginatedTable<Supplier, JsonSupplier>
        getData={(params: GetSupplierParams) => getSuppliers({
            ...params,
            pendingPayment: true
        })}
        modelConstructor={(json: JsonSupplier) => new Supplier(json)}
        searchPlaceholder='Search by Supplier Name or Phone'
        Table={(suppliers: Supplier[], callbacks: PaginatedTableCallbacks) => (
            <SuppliersTable
                callbacks={callbacks}
                resources={{ suppliers }} />
        )}
        title='Suppliers with Records Pending Payment' />
)
