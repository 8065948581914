import { defineAbility } from '@casl/ability'

import { User } from '../../models'
import { Roles, userHasRole } from '../../helpers'

export default (user: User) => defineAbility((can) => {
    if (userHasRole(user, Roles.Executive)) {
        can('edit', 'ExecutiveRole')
        can('unassign', 'ExecutiveRecords')
        can('edit', 'UserStatus')
    }

    if (userHasRole(user, Roles.SrLead)) {
        can('create', 'User')
        can('edit', 'UserRole')
        can('unassign', 'Records')
        can('view', 'User')
    }

    if (userHasRole(user, Roles.CollectionAgent)) {
        can('unassign', 'Suppliers')
    }
})
