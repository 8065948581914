import { JsonModel, Model } from './model'
import { JsonRecordSubstatus, RecordSubstatus } from './recordSubstatus'

export class RecordStatus extends Model {
    public static NOT_RESOLVED = 0
    public static PAID = 1
    public static CANCELLED = 2
    public static PREVIOUSLY_PAID = 3
    public static NO_SHOW = 4
    public static TOO_OLD = 5
    public static NON_COMMISSIONABLE = 6
    public static BANKRUPTCY = 7
    public static FOREIGN_PAYMENT = 8
    public static PENDING_PAYMENT = 10
    public static DUPLICATE_PAYMENT = 12
    public static UNKNOWN = 13
    public static DUPLICATE_RECORD = 18
    public static SYSTEM_GENERATE = 40
    public static RETURNED_PAYMENT = 41
    public static UNKNOWN_DEPOSIT = 42
    public static OVERPAYMENT = 43
    public static NEEDS_REVIEW = 44;
    public static CURRENCY_REVIEW = 45;
    public static INACTIVE = 46;

    public static PAID_STATUSES = [
        RecordStatus.PAID,
        RecordStatus.FOREIGN_PAYMENT,
        RecordStatus.UNKNOWN_DEPOSIT,
        RecordStatus.OVERPAYMENT
    ]

    public static BILLABLE_STATUSES = [
        RecordStatus.NOT_RESOLVED,
        RecordStatus.PENDING_PAYMENT,
        RecordStatus.RETURNED_PAYMENT
    ]

    public static PROCESSED_STATUSES = [
        RecordStatus.PAID,
        RecordStatus.CANCELLED,
        RecordStatus.PREVIOUSLY_PAID,
        RecordStatus.NO_SHOW,
        RecordStatus.TOO_OLD,
        RecordStatus.NON_COMMISSIONABLE,
        RecordStatus.BANKRUPTCY,
        RecordStatus.FOREIGN_PAYMENT,
        RecordStatus.DUPLICATE_PAYMENT,
        RecordStatus.DUPLICATE_RECORD,
        RecordStatus.UNKNOWN_DEPOSIT,
        RecordStatus.OVERPAYMENT
    ]

    public static REVIEW_STATUSES = [
        RecordStatus.NEEDS_REVIEW,
        RecordStatus.CURRENCY_REVIEW
    ]

    code: number
    name: string
    substatuses: RecordSubstatus[];

    constructor(json: JsonRecordStatus) {
        super(json)

        this.code = json.code
        this.name = json.name
        this.substatuses = json.substatuses?.map((substatusJson: JsonRecordSubstatus) => new RecordSubstatus(substatusJson)) ?? [];
    }
}

export interface JsonRecordStatus extends JsonModel {
    code: number
    name: string
    substatuses?: JsonRecordSubstatus[];
}
