import { defineAbility } from '@casl/ability'

import { User } from '../../models'
import { Roles, userHasRole } from '../../helpers'

export default (user: User) => defineAbility((can) => {
    if (userHasRole(user, Roles.CollectionAgent)) {
        can('Create', 'CollectionsInvoice')
    }

    if (userHasRole(user, Roles.Standard)) {
        can('Create', 'Invoice')
        can('Update', 'Invoice')
        can('View', 'Invoice')
    }

    if (userHasRole(user, Roles.Lead)) {
        can('Review', 'Invoice')
    }

    if (userHasRole(user, Roles.SrLead)) {
        can('Create', 'SupplierInvoice')
    }

    if (userHasRole(user, Roles.Executive)) {
        can('Create', 'InvoiceBatch')
    }
})
