import { JsonModel, Model } from './model'

export class AgencyPayment extends Model {
    agencyId: string
    amount: number
    createdBy: string
    paymentMethod: string

    constructor(json: JsonAgencyPayment) {
        super(json)

        this.agencyId = json.agencyId
        this.amount = parseFloat(json.amount)
        this.createdBy = json.createdBy
        this.paymentMethod = json.paymentMethod
    }
}

export interface JsonAgencyPayment extends JsonModel {
    agencyId: string
    amount: string
    createdBy: string
    paymentMethod: string
}
