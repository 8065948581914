import React, { useState } from 'react'

import { TooltipWrapper } from '../../common'
import { getAuthUser } from '../../../helpers'
import { DeleteSupplierPhoneNumber } from './delete'
import { SupplierPhoneNumberForm } from './form'
import { SupplierPhoneNumberTable } from './table'
import defineAbilityFor from './defineAbility'

interface CardProps {
    onPhoneNumbersChanged: (
        phoneNumbers: string[],
        primaryPhoneNumber: string,
        successCallback?: () => void
    ) => void

    phoneNumbers: string[]
    primaryPhoneNumber: string
}

export const SupplierPhoneNumbersCard = (props: CardProps) => {
    const { onPhoneNumbersChanged, phoneNumbers, primaryPhoneNumber } = props

    const [isAdding, setIsAdding] = useState(false)
    const [phoneNumberToDelete, setPhoneNumberToDelete] = useState('')
    const [phoneNumberToEdit, setPhoneNumberToEdit] = useState('')

    const ability = defineAbilityFor(getAuthUser())

    const handleAdd = (newPhoneNumber: string) => {
        const updated = [
            ...phoneNumbers,
            newPhoneNumber
        ]
        onPhoneNumbersChanged(updated, primaryPhoneNumber, () => setIsAdding(false))
    }

    const handleDelete = (deletedPhoneNumber: string) => {
        const updated = phoneNumbers.filter((phoneNumber) => phoneNumber !== deletedPhoneNumber)

        onPhoneNumbersChanged(updated, primaryPhoneNumber, () => setPhoneNumberToDelete(''))
    }

    const handleEdit = (oldPhoneNumber: string, updatedPhoneNumber: string) => {
        const updated = phoneNumbers.map((phoneNumber) => {
            return phoneNumber === oldPhoneNumber ? updatedPhoneNumber : phoneNumber
        })

        onPhoneNumbersChanged(
            updated,
            oldPhoneNumber === primaryPhoneNumber ? updatedPhoneNumber : primaryPhoneNumber,
            () => setPhoneNumberToEdit('')
        )
    }

    return (
        <div className='container card mb-5'>
            <div className='
                card-body
                col-xs-12
                row
                d-flex
                flex-row
                justify-content-between
            '>
                <div className='row flex-row mb-5 pe-0'>
                    <h3 className='ps-0 d-inline-flex card-title w-50'>
                        Phone Numbers
                    </h3>
                    <div className='pe-0 d-inline-flex justify-content-end w-50'>
                        {!isAdding && !phoneNumberToDelete && !phoneNumberToEdit &&
                            <TooltipWrapper
                                condition={!ability.can('Create', 'SupplierPhoneNumber')}
                                element={
                                    <button
                                        className='btn btn-primary'
                                        disabled={!ability.can('Create', 'SupplierPhoneNumber')}
                                        onClick={() => setIsAdding(true)}>
                                        Add New Phone Number
                                    </button>
                                }
                                message='Insufficient permissions' />
                        }
                        {isAdding &&
                            <button
                                className='btn btn-outline-secondary'
                                onClick={() => setIsAdding(false)}>
                                Cancel
                            </button>
                        }
                        {phoneNumberToDelete &&
                            <button
                                className='btn btn-outline-secondary'
                                onClick={() => setPhoneNumberToDelete('')}>
                                Cancel
                            </button>
                        }
                        {phoneNumberToEdit &&
                            <button
                                className='btn btn-outline-secondary'
                                onClick={() => setPhoneNumberToEdit('')}>
                                Cancel
                            </button>
                        }
                    </div>
                </div>
                <div className='row flex-row p-0'>
                    {!isAdding && !phoneNumberToDelete && !phoneNumberToEdit &&
                        <SupplierPhoneNumberTable
                            onPhoneNumbersChanged={onPhoneNumbersChanged}
                            phoneNumbers={phoneNumbers}
                            primaryPhoneNumber={primaryPhoneNumber}
                            setPhoneNumberToDelete={setPhoneNumberToDelete}
                            setPhoneNumberToEdit={setPhoneNumberToEdit} />
                    }
                    {isAdding &&
                        <SupplierPhoneNumberForm
                            key='new_phone_number'
                            handleSubmit={(phoneNumber: string) => handleAdd(phoneNumber)}
                            title='Add Phone Number' />
                    }
                    {phoneNumberToDelete &&
                        <DeleteSupplierPhoneNumber
                            phone={phoneNumberToDelete}
                            onDelete={() => handleDelete(phoneNumberToDelete)} />
                    }
                    {phoneNumberToEdit &&
                        <SupplierPhoneNumberForm
                            key={phoneNumberToEdit}
                            phone={phoneNumberToEdit}
                            handleSubmit={(phone: string) => handleEdit(phoneNumberToEdit, phone)}
                            title='Edit Phone Number' />
                    }
                </div>
            </div>
        </div>
    )
}
