import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify';

import { getAuthUser } from '../../helpers';
import { Loading } from '../common'
import {
    Agency,
    AgencyArc,
    AgencyNote,
    JsonAgencyArc,
    JsonAgencyNote
} from '../../models'
import { getAgency, getAgencyArcs, getAgencyNotes, updateAgencyEmails } from '../../api'
import { AgencyArcs } from './arcs';
import { AgencyCard } from './card';
import { AgencyEmailsCard } from './emails';
import { AgencyNotes } from './notes';
import defineAbilityFor from './defineAbility'
import {
    InsufficientPermissions,
    ScrollableContainer,
    ScrollableCol
} from '../common';
import { AgencyAddressesCard } from './addresses';
import { AgencyPayments } from './payments'

export const AgencyComponent = () => {
    const [agency, setAgency] = useState<Agency>()
    const [agencyArcs, setAgencyArcs] = useState<AgencyArc[]>([])
    const [agencyNotes, setAgencyNotes] = useState<AgencyNote[]>([])

    const params = useParams()
    const ability = defineAbilityFor(getAuthUser())

    if (!ability.can('View', 'Agency')) {
        return <InsufficientPermissions />
    }

    useEffect(() => {
        if (params.agencyId) {
            getAgency(params.agencyId)
                .then(response => {
                    if (response.status === 200) {
                        setAgency(new Agency(response.data))
                    }
                })
            getAgencyArcs(params.agencyId)
                .then(response => {
                    if (response.status === 200) {
                        setAgencyArcs(response.data.map(
                            (arc: JsonAgencyArc) => new AgencyArc(arc))
                        )
                    }
                })
            getAgencyNotes(params.agencyId)
                .then(response => {
                    if (response.status === 200) {
                        setAgencyNotes(response.data.map(
                            (note: JsonAgencyNote) => new AgencyNote(note))
                        )
                    }
                })
        }
    }, [params.agencyId])

    if (!(agency instanceof Agency)) {
        return <Loading />
    }

    const onEmailsChanged = (
        emails: string[],
        primaryEmail: string,
        successCallback?: () => void
    ) => {
        const params = {
            emails,
            primaryEmail
        }
        updateAgencyEmails(agency.id.toString(), params)
            .then((response: any) => {
                setAgency(new Agency(response.data))

                if (successCallback) {
                    successCallback()
                }

                toast.success('Agency Emails Updated')

            })
            .catch((error: any) => {
                const { response } = error

                toast.error(response.data?.message, { autoClose: false })
            })
    }

    return (
        <div className='container mb-5'>
            <ScrollableContainer className='row'>
                <ScrollableCol className='col-xs-12 col-md-6'>
                    <AgencyCard
                        ability={ability}
                        agency={agency}
                        setAgency={setAgency} />
                    <AgencyNotes
                        agencyId={agency.id}
                        agencyNotes={agencyNotes} />
                </ScrollableCol>
                <ScrollableCol className='col-xs-12 col-md-6'>
                    <AgencyAddressesCard
                        addresses={agency.addresses}
                        agencyId={agency.id} />
                    <AgencyEmailsCard
                        key={agency.id}
                        onEmailsChanged={onEmailsChanged}
                        emails={agency.emails}
                        primaryEmail={agency.primaryEmail ?? ''} />
                    {(agency.payments !== undefined) &&
                        <AgencyPayments
                            ability={ability}
                            agencyId={agency.id}
                            agencyPayments={agency.payments}
                            agencyStatus={agency.status.code}
                            preferredPaymentMethod={agency.preferredPaymentMethod?.id} />
                    }
                    <AgencyArcs
                        agencyArcs={agencyArcs}
                        agencyId={agency.id} />
                </ScrollableCol>
            </ScrollableContainer>
        </div>
    )
}
