import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
    getSupplier,
    getSupplierInvoices,
    getSupplierNotes,
    getSupplierCollectionsContact,
    getUsers,
    updateSupplierPhoneNumbers,
} from '../../api'
import { SupplierAddressesCard } from './addresses'
import { SupplierPhoneNumbersCard } from './phoneNumbers'
import { SupplierInvoicesCard } from './supplierCollections'
import { Loading } from '../common'
import { SupplierCard } from './card'
import {
    Invoice,
    JsonSupplierCollectionsContact,
    JsonSupplierNote,
    Supplier,
    SupplierCollectionsContact,
    SupplierNote,
    User,
} from '../../models'
import { SupplierNotes } from './notes'
import { ScrollableContainer, ScrollableCol } from '../common'
import { getAuthUser } from '../../helpers'
import defineAbility from './defineAbility'
import { ScheduleCallbackForm } from './ScheduleCallbackForm'
import { RecordingManager } from './recordingManager'
import { SupplierCreateInvoiceCard } from './createInvoiceCard'

export const SupplierComponent = () => {
    const [supplier, setSupplier] = useState<Supplier>()
    const [supplierNotes, setSupplierNotes] = useState<SupplierNote[]>([])
    const [supplierCollectionsContacts, setSupplierCollectionsContacts] = useState<SupplierCollectionsContact[]>([])
    const [invoices, setInvoices] = useState<Invoice[]>([])
    const [isCollection, setIsCollection] = useState<boolean>(false)
    const [triggerNotesRefresh, setTriggerNotesRefresh] = useState(false)
    const [users, setUsers] = useState<User[]>([])

    const user = getAuthUser()

    const ability = defineAbility(user)
    const params = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        getUsers()
            .then(response => {
                setUsers(response.data)
            })
            .catch(error => {
                if (error.response && error.response.status === 403) {
                    setUsers([])
                } else {
                    console.error('Error fetching users:', error)
                    toast.error(error.message, { autoClose: false })
                }
            })
    }, [])

    useEffect(() => {
        if (params.supplierId) {
            const supplierId = params.supplierId

            Promise.allSettled([
                getSupplier(supplierId).then(response => {
                    const supplierData = new Supplier(response.data)
                    setSupplier(supplierData)
                    setIsCollection(supplierData.isCollections)
                }),
                getSupplierNotes(supplierId).then(response => {
                    setSupplierNotes(response.data.map(
                        (note: JsonSupplierNote) => new SupplierNote(note)
                    ))
                }),
                getSupplierCollectionsContact(supplierId).then(response => {
                    setSupplierCollectionsContacts(response.data.map(
                        (contact: JsonSupplierCollectionsContact) => new SupplierCollectionsContact(contact)
                    ))
                }),
                getSupplierInvoices(supplierId).then(response => {
                    setInvoices(response.data)
                })
            ]).then(results => {
                results.forEach(result => {
                    if (result.status === 'rejected') {
                        toast.error(result.reason.message, { autoClose: false })
                    }
                })
            })
        }
    }, [params.supplierId, triggerNotesRefresh])

    if (!(supplier instanceof Supplier)) {
        return <Loading />
    }

    const toggleNotesRefresh = () => setTriggerNotesRefresh(prevState => !prevState);

    const onPhoneNumbersChanged = (
        phoneNumbers: string[],
        primaryPhoneNumber: string,
        successCallback?: () => void
    ) => {
        const params = {
            phoneNumbers,
            primaryPhoneNumber
        }
        updateSupplierPhoneNumbers(supplier.id.toString(), params)
            .then((response) => {
                setSupplier(new Supplier(response.data))

                if (successCallback) {
                    successCallback()
                }

                toast.success('Supplier Phone Numbers Updated')

            })
            .catch((error) => {
                const { response } = error

                if (response.status === 409) {
                    const { conflicts } = response.data

                    interface SupplierPhoneConflict {
                        phone: string
                        supplier_id: number
                    }

                    toast.error((
                        <div>
                            Supplier Phone Number(s) exist:
                            {conflicts.map((conflict: SupplierPhoneConflict) => {
                                const SupplierLink = () => (
                                    <Link to={`/suppliers/${conflict.supplier_id}`}>
                                        {conflict.supplier_id}
                                    </Link>
                                )

                                return (
                                    <p>
                                        {conflict.phone} - Supplier <SupplierLink />
                                    </p>
                                )
                            })}
                        </div>
                    ), { autoClose: false })
                } else {
                    toast.error(response.data?.message, { autoClose: false })
                }
            })
    }

    const onSupplierContactsChanged = (newContacts: SupplierCollectionsContact[]) => {
        setSupplierCollectionsContacts(newContacts)
    }

    return (
        <div className='container'>
            <ScrollableContainer className='row'>
                <ScrollableCol className='col-xs-12 col-md-6'>
                    <SupplierCard
                        setSupplier={setSupplier}
                        supplier={supplier}
                        onNoteChange={toggleNotesRefresh} />
                    {ability.can('View', 'SupplierCallbacks') && (
                        <ScheduleCallbackForm
                            supplierId={supplier.id}
                            supplierName={supplier.name}
                            users={users}
                        />
                    )}
                    <SupplierNotes
                        supplierId={supplier.id}
                        supplierNotes={supplierNotes} />
                </ScrollableCol>
                <ScrollableCol className='col-xs-12 col-md-6'>
                    {ability.can('Create', 'SupplierInvoice') &&
                        <SupplierCreateInvoiceCard
                            supplier={supplier}
                            onSuccess={(response) => setTimeout(
                                () => navigate(`/invoices/${response.data.invoice.id}`)
                            )}
                            userId={user.id} />
                    }
                    <SupplierAddressesCard
                        addresses={supplier.addresses}
                        supplierId={supplier.id} />
                    <SupplierPhoneNumbersCard
                        key={supplier.id}
                        onPhoneNumbersChanged={onPhoneNumbersChanged}
                        phoneNumbers={supplier.phoneNumbers}
                        primaryPhoneNumber={supplier.primaryPhone} />
                    {ability.can('View', 'SupplierCollections') && isCollection ?
                        <SupplierInvoicesCard
                            invoices={invoices}
                            supplierContacts={supplierCollectionsContacts}
                            supplierId={supplier.id}
                            onSupplierContactsChanged={onSupplierContactsChanged}
                        />
                        : null
                    }
                    <RecordingManager supplierId={supplier.id} users={users} />
                </ScrollableCol>
            </ScrollableContainer>
        </div>
    )
}
