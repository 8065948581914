import React from 'react'
import { Link } from 'react-router-dom'

import { Agency } from '../../models'
import { PaginatedTableContract } from '../common'

interface AgenciesTableProps extends PaginatedTableContract {
    resources: {
        agencies: Agency[]
    }
}

export const AgenciesTable = (props: AgenciesTableProps) => {
    const { resources } = props
    const { agencies } = resources

    return (
        <table className='table'>
            <tbody>
                <tr>
                    <th>Name</th>
                    <th>EIN</th>
                    <th>Street</th>
                    <th>City</th>
                    <th>State</th>
                    <th>Zip</th>
                    <th>Country</th>
                    <th>Records</th>
                </tr>
                {agencies.map(agency =>
                    <tr key={agency.id}>
                        <td>
                            <Link to={`/agencies/${agency.id}`}>
                                {agency.name}
                            </Link>
                        </td>
                        <td>{agency.ein}</td>
                        <td>{agency.physicalAddress.street}</td>
                        <td>{agency.physicalAddress.city}</td>
                        <td>{agency.physicalAddress?.state?.name}</td>
                        <td>{agency.physicalAddress.zip}</td>
                        <td>{agency.physicalAddress.country?.name}</td>
                        <td>{agency.recordCount}</td>
                    </tr>
                )}
            </tbody>
        </table>
    )
}
