import { JsonModel, Model } from './model'
import dayjs, { Dayjs } from 'dayjs'

export class SupplierRecording extends Model {
    supplier_id: number;
    filename: string;
    size: number;
    created_by: number;
    description: string;
    created_at: Dayjs;
    updated_at: Dayjs;

    constructor(data: JsonSupplierRecording) {
        super(data)
        this.supplier_id = data.supplier_id
        this.filename = data.filename
        this.size = data.size
        this.created_by = data.created_by
        this.description = data.description
        this.created_at = dayjs(data.created_at)
        this.updated_at = dayjs(data.updated_at)
    }
}

export interface JsonSupplierRecording extends JsonModel {
    supplier_id: number;
    filename: string;
    size: number;
    created_by: number;
    description: string;
    created_at: string;
    updated_at: string;
}
