import { JsonModel, Model } from './model'

export class Chain extends Model {
    id: number
    abbreviation: string
    code: string
    name: string

    constructor(json: JsonChain) {
        super(json)

        this.id = json.id
        this.abbreviation = json.abbreviation
        this.code = json.code
        this.name = json.name
    }
}

export interface JsonChain extends JsonModel {
    id: number
    abbreviation: string
    code: string
    name: string
}
