import React from 'react'

import {
    InsufficientPermissions,
    PaginatedTable,
    PaginatedTableCallbacks
} from '../../common'
import { ForeignConversionTable } from './table'
import { JsonRecord, Record } from '../../../models'
import { getOpenForeignRecords } from '../../../api'
import { getAuthUser } from '../../../helpers';
import defineAbilityFor from '../defineAbility'

export const ForeignConversionReport = () => {
    const ability = defineAbilityFor(getAuthUser())

    if (!ability.can('View', 'ForeignConversionReport')) {
        return <InsufficientPermissions />
    }

    return (
        <PaginatedTable<Record, JsonRecord>
            getData={getOpenForeignRecords}
            modelConstructor={(json: JsonRecord) => new Record(json)}
            searchPlaceholder='Search by REC, Phone, or Supplier Name'
            Table={(records: Record[], callbacks: PaginatedTableCallbacks) => (
                <ForeignConversionTable
                    callbacks={callbacks}
                    resources={{ records }} />
            )}
            title='Foreign Conversion' />
    )
}
