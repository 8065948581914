import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getAuthUser } from '../../helpers';
import { Loading } from '../common'
import { JsonRecordNote, Record, RecordNote } from '../../models'
import { getRecord, getRecordNotes } from '../../api'
import { RecordCard } from './card';
import defineAbilityFor from './defineAbility'
import { RecordHistory } from './history';
import { RecordNotes } from './notes'
import {
    InsufficientPermissions,
    ScrollableContainer,
    ScrollableCol
} from '../common';

export const RecordComponent = () => {
    const [record, setRecord] = useState<Record>()
    const [recordNotFound, setRecordNotFound] = useState(false)
    const [recordNotes, setRecordNotes] = useState<RecordNote[]>([])
    const params = useParams()
    const ability = defineAbilityFor(getAuthUser())

    if (!ability.can('View', 'Record')) {
        return <InsufficientPermissions />
    }

    useEffect(() => {
        if (params.recordId) {
            getRecord(params.recordId)
                .then(response => {
                    if (response.status === 200) {
                        setRecord(new Record(response.data))
                    }
                })
                .catch(error => {
                    console.log(error)
                    if (error?.response.status === 404) {
                        setRecordNotFound(true)
                    }
                })

            getRecordNotes(params.recordId)
                .then(response => {
                    if (response.status === 200) {
                        setRecordNotes(response.data.map(
                            (note: JsonRecordNote) => new RecordNote(note))
                        )
                    }
                })
        }
    }, [params.recordId])

    if (recordNotFound) {
        return (
            <div className='container'>
                <h3>Record Not Found</h3>
            </div>
        )
    }

    if (!(record instanceof Record)) {
        return <Loading />
    }

    return (
        <div className='container mb-5'>
            <ScrollableContainer className='row'>
                <ScrollableCol className='col-xs-12 col-md-7'>
                    <RecordCard
                        ability={ability}
                        record={record}
                        setRecord={setRecord} />
                    <RecordNotes
                        recordId={record.id}
                        recordStatus={record.status.code}
                        recordNotes={recordNotes} />
                </ScrollableCol>
                <ScrollableCol className='col-xs-12 col-md-5'>
                    <RecordHistory
                        changes={record.changes} />
                </ScrollableCol>
            </ScrollableContainer>
        </div>
    )
}
