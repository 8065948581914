import React, { FormEvent, useState } from 'react'

import { CreateVendorCodeParams, UpdateVendorCodeParams } from '../../api'
import { VendorCode } from '../../models'

interface FormProps {
    handleSubmit: (
        e: FormEvent<HTMLFormElement>,
        inputs: CreateVendorCodeParams | UpdateVendorCodeParams
    ) => void
    isSubmitting?: boolean
    vendorCode?: VendorCode
}

export const VendorForm = (props: FormProps) => {
    const { handleSubmit, isSubmitting, vendorCode } = props

    const defaultInputs = {
        id: vendorCode?.id ?? undefined,
        code: vendorCode?.code ?? '',
    }

    const [inputs, setInputs] = useState(defaultInputs)

    const updateInputs = (field: string, value: number | string) => {
        setInputs({
            ...inputs,
            [field]: value
        })
    }

    return (
        <form className='row mb-4' onSubmit={(e) => handleSubmit(e, inputs)}>
            <div className='row mb-4'>
                <div className='col-12'>
                    <label htmlFor='vendorCodeInput'>Vendor Code</label>
                    <input
                        className='form-control'
                        id='vendorCodeInput'
                        onChange={(e) => updateInputs(
                            'code',
                            e.target.value
                        )}
                        type='text'
                        required
                        pattern='^[a-zA-Z0-9]*$'
                        maxLength={255}
                        value={inputs.code} />
                </div>
            </div>
            <div className='row mb-5'>
                <div className='col-12 d-flex justify-content-center'>
                    <button
                        className='btn btn-primary'
                        disabled={!!isSubmitting}
                        type='submit'>
                        Submit
                    </button>
                </div>
            </div>
        </form>
    )
}
