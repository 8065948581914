import React, { Dispatch, SetStateAction, useState } from 'react'
import { toast } from 'react-toastify'

import {
    updateAgencyAddresses,
    UpdateAgencyAddressesParams,
    syncAgencyAddresses
} from '../../../api'
import { getAuthUser } from '../../../helpers'
import { Address, JsonAddress } from '../../../models'
import { FormattedAddress, TooltipWrapper } from '../../common'
import defineAbilityFor from './defineAbility'

interface TableProps {
    addresses: Address[]
    setAddressToEdit: Dispatch<SetStateAction<Address | undefined>>
    agencyId: number
}

export const AgencyAddressTable = (props: TableProps) => {
    const { setAddressToEdit, agencyId } = props

    const [addresses, setAddresses] = useState(props.addresses)

    const ability = defineAbilityFor(getAuthUser())

    const handleSubmit = (params: UpdateAgencyAddressesParams, message: string) => {
        updateAgencyAddresses(agencyId.toString(), params)
            .then((response) => {
                if (response.status === 200) {
                    setAddresses(response.data.map(
                        (address: JsonAddress) => new Address(address)
                    ))

                    toast.success(message)
                }
            })

    }

    const handleDelete = (addressId: number) => {
        const params = {
            addressIdToDetach: addressId
        }

        syncAgencyAddresses(agencyId.toString(), params)
            .then((response) => {
                setAddresses(response.data.addresses.map(
                    (address: JsonAddress) => new Address(address)
                ))

                toast.success('Address Deleted')
            })
            .catch(error => {
                if (error?.response?.data?.message) {
                    toast.error(error.response.data.message, { autoClose: false })
                }
            })
    }

    return (
        <table className='table'>
            <tbody>
                {addresses
                    .sort((a, b) => +b.isPayment - +a.isPayment)
                    .sort((a, b) => +b.isPhysical - +a.isPhysical)
                    .map(address => (
                        <tr className='d-flex' key={`agency_address_${address.id}`}>
                            <td style={{ width: '43%' }}>
                                <FormattedAddress
                                    city={address.city}
                                    country={address.country?.name ?? ''}
                                    state={address?.state?.name ?? ''}
                                    street={address.street}
                                    street2={address.street2}
                                    zip={address.zip} />
                            </td>
                            <td className='d-flex align-items-end flex-column ps-0 pe-0'
                                style={{ width: '57%' }}>
                                <span className='d-flex'>
                                    {address.isPayment &&
                                        <span className='badge bg-dark rounded-pill ms-2'>
                                            Payment
                                        </span>
                                    }
                                    {address.isPhysical &&
                                        <span className='badge bg-success rounded-pill ms-2'>
                                            Physical
                                        </span>
                                    }
                                </span>
                                <span className='d-flex mb-2 mt-auto align-items-end'>
                                    <form onSubmit={(e) => {
                                        e.preventDefault()

                                        handleSubmit(
                                            { paymentAddressId: address.id },
                                            'Payment Address Updated'
                                        )
                                    }}>
                                        <TooltipWrapper
                                            condition={address.isPayment
                                                || !ability.can('Update', 'AgencyAddress')
                                            }
                                            element={
                                                <button
                                                    className='btn btn-outline-primary btn-sm'
                                                    disabled={address.isPayment
                                                        || !ability.can('Update', 'AgencyAddress')
                                                    }
                                                    type='submit'>
                                                    Set as Payment
                                                </button>
                                            }
                                            message={address.isPayment
                                                ? 'Address is currently payment'
                                                : 'Insufficient permissions'
                                            } />
                                    </form>
                                    <form onSubmit={(e) => {
                                        e.preventDefault()

                                        handleSubmit(
                                            { physicalAddressId: address.id },
                                            'Physical Address Updated'
                                        )
                                    }}>
                                        <TooltipWrapper
                                            condition={address.isPhysical
                                                || !ability.can('Update', 'AgencyAddress')
                                            }
                                            element={
                                                <button
                                                    className='btn btn-outline-primary btn-sm ms-1'
                                                    disabled={address.isPhysical
                                                        || !ability.can('Update', 'AgencyAddress')
                                                    }
                                                    type='submit'>
                                                    Set as Physical
                                                </button>
                                            }
                                            message={address.isPhysical
                                                ? 'Address is currently physical'
                                                : 'Insufficient permissions'
                                            } />
                                    </form>
                                    <form onSubmit={(e) => {
                                        e.preventDefault()

                                        handleDelete(address.id)
                                    }}>
                                        <TooltipWrapper
                                            condition={address.isPhysical
                                                || address.isPayment
                                                || !ability.can('Delete', 'AgencyAddress')
                                            }
                                            element={
                                                <button
                                                    className='btn btn-outline-danger btn-sm ms-1'
                                                    disabled={address.isPhysical
                                                        || address.isPayment
                                                        || !ability.can('Delete', 'AgencyAddress')
                                                    }
                                                    type='submit'>
                                                    Delete
                                                </button>
                                            }
                                            message={(address.isPhysical || address.isPayment)
                                                ? 'Cannot delete payment or physical address'
                                                : 'Insufficient permissions'
                                            } />
                                    </form>
                                    <TooltipWrapper
                                        condition={!ability.can('Edit', 'AgencyAddress')}
                                        element={
                                            <button
                                                className='btn btn-primary btn-sm mt-auto ms-1'
                                                disabled={
                                                    !ability.can('Edit', 'AgencyAddress')
                                                }
                                                onClick={() => setAddressToEdit(address)}>
                                                Edit
                                            </button>
                                        }
                                        message='Insufficient permissions' />
                                </span>
                            </td>
                        </tr>
                    ))}
            </tbody>
        </table>
    )
}
