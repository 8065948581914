import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { AxiosResponse } from 'axios'
import { toast } from 'react-toastify'

import { JsonModel, Model } from '../../models'
import { Loading } from './loading'

export interface PaginatedTableCallbacks {
    setReload: Dispatch<SetStateAction<boolean>>
}

export interface PaginatedTableContract {
    callbacks: PaginatedTableCallbacks
    resources: {
        [resources: string]: Model[]
    }
}

interface Links {
    next: string | null
    prev: string | null
    last: string | null
}

interface PaginationMetaInfo {
    from: number
    to: number
    total: number
}

interface RedirectLink {
    to: string
    text: string
}

type AdvancedSearchCriteria<T> = Partial<T>

export interface QueryParams {
    advancedSearch?: AdvancedSearchCriteria<any>
    search?: string
    page?: string
}

interface PostLink {
    request: (params: any) => Promise<AxiosResponse<any, any>>
    text: string
}

interface FilterButton {
    text: string
}

interface SortOptions {
    sortBy: string
    sortDirection: string
    onSortChange: (field: string) => void
    sortFields: Array<{ field: string, label: string }>
}

interface PaginatedTableProps<T, K> {
    AdvancedSearchComponent?: (props: any) => JSX.Element | null
    defaultAdvancedSearchState?: any
    defaultSearch?: string
    disableAutoFilter?: boolean
    disableLastPageButton?: boolean
    disableSearch?: boolean
    DownloadButton?: (props: any) => JSX.Element
    filterButton?: FilterButton
    Filters?: (props: any) => JSX.Element
    Form?: (props: any) => JSX.Element
    formatHeaderData?: (data: string) => string
    getData: (params: any) => Promise<AxiosResponse<any, any>>
    onSearch?: () => void
    redirectLink?: RedirectLink
    postLink?: PostLink
    modelConstructor: (json: K) => T
    searchPlaceholder?: string
    sortOptions?: SortOptions
    Table: (resources: T[], callbacks: PaginatedTableCallbacks) => JSX.Element
    title: string
}

export const PaginatedTable = <T extends Model, K extends JsonModel>(
    props: PaginatedTableProps<T, K>
) => {
    const {
        AdvancedSearchComponent,
        defaultAdvancedSearchState,
        defaultSearch,
        disableAutoFilter,
        disableLastPageButton,
        disableSearch,
        DownloadButton,
        filterButton,
        Filters,
        Form,
        formatHeaderData,
        getData,
        modelConstructor,
        onSearch,
        postLink,
        redirectLink,
        searchPlaceholder,
        sortOptions,
        Table,
        title
    } = props

    enum PageState {
        Default,
        EntriesFound,
        EntriesNotFound,
        InvalidInput,
        Loading,
    }

    const [advancedSearch, setAdvancedSearch] = useState<AdvancedSearchCriteria<any>>(defaultAdvancedSearchState || {})
    const [pageNumber, setPageNumber] = useState('1')
    const [filters, setFilters] = useState<Record<string, any>>({})
    const [search, setSearch] = useState(defaultSearch || '')
    const [headerData, setHeaderData] = useState(undefined)
    const [pageState, setPageState] = useState<PageState>(PageState.Default)
    const [queryParams, setQueryParams] = useState<QueryParams>({
        page: '1',
        ...((defaultAdvancedSearchState && Object.keys(defaultAdvancedSearchState).length)
            && { advancedSearch: defaultAdvancedSearchState }
        )
    })
    const [resources, setResources] = useState<T[]>([])
    const [reload, setReload] = useState(false)
    const [links, setLinks] = useState<Links>({
        next: null,
        prev: null,
        last: null
    })
    const [meta, setPaginationMeta] = useState<PaginationMetaInfo>({
        from: 0,
        to: 0,
        total: 0,
    })

    type Timer = ReturnType<typeof setTimeout>
    const timeoutRef = useRef<Timer>()

    const updateFilters = (
        filterName: string,
        newValue: any,
        shouldDelete?: boolean,
        dependentFilterToClear?: string
    ) => {
        let updated: Record<string, any> = {
            ...filters,
            [filterName]: newValue,
        }

        if (shouldDelete) {
            delete updated[filterName]
        }

        if (dependentFilterToClear) {
            delete updated[dependentFilterToClear]
        }

        setFilters(updated)
    }

    useEffect(() => {
        for (const key in filters) {
            if (filters[key] == '') {
                delete filters[key]
            }
        }
        const params = {
            page: '1',
            ...(search && { search }),
            ...filters,
            ...(Object.keys(advancedSearch).length && { advancedSearch })
        }

        if (JSON.stringify(params) !== JSON.stringify(queryParams)) {
            setQueryParams(params)
        }

        return () => {
            setPageNumber('1')
        }
    }, [search, JSON.stringify(filters), JSON.stringify(advancedSearch)])

    useEffect(() => {
        const params = {
            ...queryParams,
            ...(pageNumber && { page: pageNumber }),
            ...(sortOptions && { sortBy: sortOptions.sortBy, sortDirection: sortOptions.sortDirection })
        }

        if (JSON.stringify(params) !== JSON.stringify(queryParams)) {
            setQueryParams(params)
        }
    }, [pageNumber, sortOptions?.sortBy, sortOptions?.sortDirection])

    useEffect(() => {
        let canceled = false

        if (!disableAutoFilter) {
            if (reload) {
                setReload(false)
            }

            if (search && !isNaN(+search) && +search < 0) {
                setPageState(PageState.InvalidInput)
            } else {
                setPageState(PageState.Loading)
                getData(queryParams)
                    .then((response) => {
                        if (response.status === 200 && !canceled) {
                            if (response.data.data.length === 0) {
                                setPageState(PageState.EntriesNotFound)
                            } else {
                                setPageState(PageState.EntriesFound)
                                setResources(
                                    response.data.data.map(
                                        (json: K): T => modelConstructor(json)
                                    )
                                )

                                const { links, meta } = response.data

                                setLinks(links)
                                setPaginationMeta(meta)
                            }

                            const { additionalData } = response.data

                            if (typeof additionalData !== undefined) {
                                setHeaderData(additionalData)
                            }
                        }
                    })
                    .catch((error) => {
                        if (error?.response?.status === 404) {
                            setPageState(PageState.EntriesNotFound)
                        }
                    })
            }
        }

        return () => {
            canceled = true
        }
    }, [JSON.stringify(queryParams), reload])

    const sendPostRequest = () => {
        if (postLink?.request) {
            postLink.request(filters).then((response) => {
                if (response.status === 200) {
                    toast.success(
                        'Successful response received from server: ' +
                        response.status +
                        ' ' +
                        response.data
                    )
                } else {
                    toast.error(
                        'Unsuccessful response received from server:' +
                        response.status +
                        ' ' +
                        response.data,
                        { autoClose: false }
                    )
                }
            })
        }
    }

    const queryFromFilters = () => {
        if (reload) {
            setReload(false)
        }

        if (search && !isNaN(+search) && +search < 0) {
            setPageState(PageState.InvalidInput)
        } else {
            setPageState(PageState.Loading)
            getData(queryParams)
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.data.length === 0) {
                            setPageState(PageState.EntriesNotFound)
                        } else {
                            setPageState(PageState.EntriesFound)
                            setResources(
                                response.data.data.map((json: K): T => modelConstructor(json))
                            )

                            const { links, meta } = response.data

                            setLinks(links)
                            setPaginationMeta(meta)
                        }

                        const { additionalData } = response.data

                        if (typeof additionalData !== undefined) {
                            setHeaderData(additionalData)
                        }
                    }
                })
                .catch((error) => {
                    if (error?.response?.status === 404) {
                        setPageState(PageState.EntriesNotFound)
                    }
                })
        }
    }

    return (
        <div className="container card">
            <div className="card-body">
                <div className="row">
                    <div className="col-xs-12 col-md-12 d-flex align-items-center">
                        <h3 className="ps-0 card-title flex-grow-1">{title}</h3>
                        {redirectLink && (
                            <div className="button-wrapper">
                                <Link
                                    to={redirectLink.to}
                                    className="ps-0 d-inline-flex justify-content-end"
                                >
                                    <button className="btn btn-primary">
                                        {redirectLink.text}
                                    </button>
                                </Link>
                            </div>
                        )}
                        {postLink && (
                            <div className="button-wrapper">
                                <button
                                    onClick={() => sendPostRequest()}
                                    className="btn btn-primary"
                                >
                                    {postLink.text}
                                </button>
                            </div>
                        )}
                        {headerData !== undefined && (
                            <div className="ps-0 d-inline-flex justify-content-end w-50">
                                <h3>{formatHeaderData && formatHeaderData(headerData)}</h3>
                            </div>
                        )}
                    </div>
                </div>
                {Filters && (
                    <div className="row">
                        <div className="col-12 col-md-3 mb-3">
                            <Filters updateFilters={updateFilters} />
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-12 col-md-9">
                        {!disableSearch && (
                            <div className="d-grid gap-2 d-md-flex">
                                <input
                                    className="form-control mb-3"
                                    defaultValue={defaultSearch}
                                    onChange={(e) => {
                                        if (timeoutRef.current) {
                                            clearTimeout(timeoutRef.current)
                                        }

                                        timeoutRef.current = setTimeout(
                                            (value: string) => {
                                                setSearch(value)
                                            },
                                            500,
                                            e.target.value
                                        )

                                        if (onSearch) {
                                            onSearch()
                                        }
                                    }}
                                    placeholder={searchPlaceholder ?? 'Search'}
                                    type="text"
                                />
                                {DownloadButton && (
                                    <div className="mb-3 w-100">
                                        <DownloadButton params={filters} />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    {filterButton && !(pageState === PageState.Loading) && (
                        <div className="ps-0 d-inline-flex justify-content-end w-50">
                            <button
                                onClick={() => queryFromFilters()}
                                className="btn btn-primary"
                            >
                                {filterButton.text}
                            </button>
                        </div>
                    )}
                    {AdvancedSearchComponent && (
                        <div className="row">
                            <div className="col-12 mb-3">
                                <div className="row mb-2">
                                    <AdvancedSearchComponent
                                        setAdvancedSearch={setAdvancedSearch}
                                    />
                                    {DownloadButton && (
                                        <div className="col-md-2 d-flex justify-content-end align-items-end">
                                            <DownloadButton
                                                advancedSearch={advancedSearch}
                                                params={filters} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {Form && (
                        <div className="row">
                            <div className="col-12 mb-3">
                                <Form queryParams={queryParams} />
                            </div>
                        </div>
                    )}
                </div>
                {sortOptions && (
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="btn-group" role="group">
                                {sortOptions.sortFields.map(({ field, label }) => (
                                    <button
                                        key={field}
                                        className={`btn btn-med ${sortOptions.sortBy === field ? 'btn-secondary' : 'btn-outline-secondary'} me-2 mb-2`}
                                        onClick={() => sortOptions.onSortChange(field)}
                                    >
                                        {label} {sortOptions.sortBy === field && (sortOptions.sortDirection === 'asc' ? '↑' : '↓')}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
                <div className="row">
                    {pageState === PageState.Loading && <Loading />}
                    {pageState === PageState.EntriesFound && resources.length > 0 && (
                        <div className="col-12">
                            <div className="overflow-auto">
                                {Table(resources, { setReload })}
                            </div>
                            <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                <div className="align-self-center me-2">
                                    From {meta.from} to {meta.to}{' '}
                                    {meta.total && `of ${meta.total}`}
                                </div>
                                <button
                                    className="btn btn-outline-secondary"
                                    disabled={links.prev === null}
                                    onClick={() => {
                                        if (links.prev) {
                                            const prevURL = new URL(links.prev)
                                            const urlParams = new URLSearchParams(
                                                prevURL.search
                                            )
                                            const page = urlParams.get('page')

                                            if (page) {
                                                setPageNumber(page)
                                            }

                                            if (disableAutoFilter) {
                                                queryFromFilters()
                                            }
                                        }
                                    }}
                                >
                                    Previous
                                </button>
                                <button
                                    className="btn btn-primary"
                                    disabled={links.next === null}
                                    onClick={() => {
                                        if (links.next) {
                                            const nextURL = new URL(links.next)
                                            const urlParams = new URLSearchParams(
                                                nextURL.search
                                            )
                                            const page = urlParams.get('page')

                                            if (page) {
                                                setPageNumber(page)
                                            }

                                            if (disableAutoFilter) {
                                                queryFromFilters()
                                            }
                                        }
                                    }}
                                >
                                    Next
                                </button>
                                {!disableLastPageButton && (
                                    <button
                                        className="btn btn-outline-primary"
                                        disabled={links.next === null}
                                        onClick={() => {
                                            if (links.last) {
                                                const nextURL = new URL(links.last)
                                                const urlParams = new URLSearchParams(
                                                    nextURL.search
                                                )
                                                const page = urlParams.get('page')

                                                if (page) {
                                                    setPageNumber(page)
                                                }

                                                if (disableAutoFilter) {
                                                    queryFromFilters()
                                                }
                                            }
                                        }}
                                    >
                                        Last Page
                                    </button>
                                )}
                            </div>
                        </div>
                    )}
                    {pageState === PageState.EntriesNotFound && (
                        <h3 className="ps-0">No entries found</h3>
                    )}
                    {pageState === PageState.InvalidInput && (
                        <h3 className="ps-0">Invalid Input</h3>
                    )}
                </div>
            </div>
        </div>
    )
}
