import React, { FormEvent, useEffect, useState } from 'react'

import {
    Address,
    Country,
    JsonCountry,
    JsonState,
    State,
    USA,
    ValidationStatuses
} from '../../models'
import { CreateAddressParams, getCountries, getStates } from '../../api'

interface FormProps {
    address?: Address
    handleSubmit: (inputs: CreateAddressParams) => void
}

export interface AddressInput extends Address {
    countryId: number
    stateId: number
}

export interface AddressInputUpdates {
    field: keyof AddressInput
    value: string | number
}

export const AddressForm = (props: FormProps) => {
    const { address } = props

    const defaultInputs = {
        city: address?.city ?? '',
        countryId: address?.country?.id ?? USA,
        stateId: address?.state?.id ?? '',
        street: address?.street ?? '',
        street2: address?.street2 ?? '',
        zip: address?.zip ?? '',
        manualValidationStatus: address?.manualValidationStatus
            ?? ValidationStatuses.Unvalidated
    }

    const [countries, setCountries] = useState<Country[]>([])
    const [country, setCountry] = useState(address?.country)
    const [states, setStates] = useState([])
    const [inputs, setInputs] = useState<CreateAddressParams>(defaultInputs)

    useEffect(() => {
        getCountries().then(json => {
            setCountries(json.data.map(
                (country: JsonCountry) => new Country(country))
            )
        })

        getStates().then(json => {
            setStates(json.data.map(
                (state: JsonState) => new State(state))
            )
        })
    }, [])

    useEffect(() => {
        const countryId = inputs?.countryId ?? USA

        if (!country) {
            setCountry(countries.find(country => country.id === countryId))
        }
    }, [countries.length])

    const updateInputs = (addressUpdates: Array<AddressInputUpdates>) => {
        let updated = {
            ...inputs
        }

        addressUpdates.forEach(update => {
            updated = {
                ...updated,
                [update.field]: update.value
            }
        })

        setInputs(updated)
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        props.handleSubmit(inputs)
    }

    return (
        <form className='row' onSubmit={(e) => handleSubmit(e)}>
            <div className='col-12 mb-4'>
                <label htmlFor='streetInput'>Street</label>
                <input
                    className='form-control'
                    id='streetInput'
                    onChange={(e) => updateInputs([{
                        field: 'street',
                        value: e.target.value
                    }])}
                    type='text'
                    value={inputs.street} />
                <label htmlFor='street2Input'>Unit</label>
                <input
                    className='form-control'
                    id='street2Input'
                    onChange={(e) => updateInputs([{
                        field: 'street2',
                        value: e.target.value
                    }])}
                    type='text'
                    value={inputs.street2} />
                <label htmlFor='faxInput'>City</label>
                <input
                    className='form-control'
                    id='cityInput'
                    onChange={(e) => updateInputs([{
                        field: 'city',
                        value: e.target.value
                    }])}
                    type='text'
                    value={inputs.city} />
                <label htmlFor='zipInput'>Zip</label>
                <input
                    className='form-control'
                    id='zipInput'
                    onChange={(e) => updateInputs([{
                        field: 'zip',
                        value: e.target.value
                    }])}
                    type='text'
                    value={inputs.zip} />
                <label htmlFor='countryInput'>Country</label>
                <select
                    className='form-select'
                    id='countryInput'
                    onChange={(e) => {
                        updateInputs([
                            {
                                field: 'countryId',
                                value: e.target.value
                            },
                            {
                                field: 'stateId',
                                value: ''
                            }
                        ])
                        setCountry(countries.find(
                            (country: Country) => country.id === +e.target.value)
                        )
                    }}
                    value={inputs.countryId}>
                    {countries.map((country: Country) => (
                        <option
                            key={`${country.name}_${country.id}`}
                            value={country.id}>
                            {country.name}
                        </option>
                    ))}
                </select>
                <label htmlFor='stateInput'>State</label>
                <select
                    className='form-select'
                    id='stateInput'
                    onChange={(e) => updateInputs([{
                        field: 'stateId',
                        value: e.target.value
                    }])}
                    value={inputs.stateId}>
                    <option value=''>
                        (Empty)
                    </option>
                    {states
                        .filter((state: State) => state.countryId === country?.id)
                        .map((state: State) => (
                            <option
                                key={`${state.name}_${state.id}`}
                                value={state.id}>
                                {state.name}
                            </option>
                        )
                        )}
                </select>
                <label htmlFor='validationInput'>Manual Validation</label>
                <select
                    className='form-select'
                    id='validationInput'
                    onChange={(e) => {
                        updateInputs([{
                            field: 'manualValidationStatus',
                            value: e.target.value
                        }])
                    }}
                    value={inputs.manualValidationStatus}>
                    <option value={ValidationStatuses.Unvalidated}>Unvalidated</option>
                    <option value={ValidationStatuses.Valid}>Valid</option>
                    <option value={ValidationStatuses.Invalid}>Invalid</option>
                </select>
            </div>
            <div className='col-12 d-flex justify-content-center'>
                <button className='btn btn-primary' type='submit'>
                    Submit
                </button>
            </div>
        </form>
    )
}
