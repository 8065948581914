import { JsonModel, Model } from './model'
import { JsonUser, User } from './user'

export class AgencyNote extends Model {
    agencyId: string
    createdBy: string
    note: string
    important: boolean

    constructor(json: JsonAgencyNote) {
        super(json)

        this.agencyId = json.agencyId
        this.createdBy = 'System'

        if (json.createdBy) {
            const user = new User(json.createdBy)
            this.createdBy = user.name
        }

        this.note = json.note
        this.important = json.important
    }
}

export interface JsonAgencyNote extends JsonModel {
    agencyId: string
    createdBy: JsonUser | null
    note: string
    important: boolean
}
