import dayjs from 'dayjs'
import { standardCommission, InvoiceRecord } from '../models'

export const calculateInvoiceAmounts = (records: InvoiceRecord[]): { due: number; paid: number } => {
    const due = records
        .filter(record => record.isBillable && !record.isProcessed)
        .map(record => record.commission)
        .reduce((acc, cur) => acc + cur, 0)

    const paid = records
        .map(record => record.paidCt)
        .reduce((acc, cur) => acc + cur, 0)

    return { due, paid }
};

export const calculateCommissionByDays = (
    rate: number,
    days: number
): number => {
    const commission = Number((days * rate * standardCommission).toPrecision(7))
    return commission
};

export const calculateNights = (startDate: string, endDate: string): number => {
    const start = dayjs(startDate)
    const end = dayjs(endDate)
    const days = end.diff(start, 'day')

    return days < 1 ? 1 : days
}

export const currencyFormat = (amount: number): string => {
    return amount.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    })
}
