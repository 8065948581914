import React, { useState, useReducer } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import easternTimezonedDayjs from '../../../helpers/dayjsEastern'
import { downloadInvoice } from '../../../api/routes'
import {
    calculateCommissionByDays,
    currencyFormat,
  } from '../../../helpers/currencyCalculation'
import { updateInvoiceRecords, ReconcileInvoiceParams, ReconcileInvoiceRecordParams } from '../../../api'

import {Invoice, InvoiceRecord, PaymentMethod, PaymentMethods, RecordStatus} from '../../../models';

import {InvoiceForm, InvoiceInputs} from './form';
import {ReconciliationTable} from './table';

/** fields with non-null values store local edits to the record which will be batch updated on invoice submit */
export interface RecordInput {
    record: InvoiceRecord;
    reconciled: boolean;
    selected: boolean;

    /** modifications to the base record that will be updated on submit */
    edited: Partial<{
        commission: string;
        days: string;
        paidCt: string;
        paymentMethodId: string;
        rate: string;
        statusCode: string;
        substatusCode: string;
    }>;
}

export type RecordInputMap = {[recordId: string]: RecordInput};


export type RecordEvent = RecordEvent.Edit | RecordEvent.Select | RecordEvent.Reconcile;
export namespace RecordEvent {
    // Edit is a change to the record model's data
    export interface Edit {
        type: 'edit',
        recordId: string;
        property: keyof RecordInput['edited'];
        value: string;
    }

    export interface Select {
        type: 'select',
        /** when recordId is undefined, event applies to all records */
        recordId?: string;
        selected: boolean;
    }

    export interface Reconcile {
        type: 'reconcile',
        /** when recordId is undefined, event applies to all records */
        recordId?: string;
        reconciled: boolean;
    }
}

export interface InvoiceReconciliationProps {
    invoice: Invoice
    onInvoiceUpdated: (invoice: Invoice) => void
    paymentMethods: PaymentMethod[],
    recordStatuses: RecordStatus[],
}

/** returns a copy of the record with a recalculated `edited` object */
function editRecord(record: RecordInput, property: keyof RecordInput['edited'], value: string, formInputs: InvoiceInputs): RecordInput {
    // newEdited contains the results of all the calculations we needed to make due to this property update. any values it defines overwrite those of the previous 'edited' object
    const newEdited: RecordInput['edited'] = {[property]: value};

    // we check if newEdited properties are undefined instead of using the 'property' string directly because some edits cascade and cause other fields to update as well
    if(newEdited.days != undefined || newEdited.rate != undefined) {
        // update commission
        const days = newEdited.days != undefined?
                parseInt(newEdited.days) || 0:
            record.edited.days != undefined?
                parseInt(record.edited.days) || 0:
                record.record.days;
        const rate = newEdited.rate != undefined?
                parseFloat(newEdited.rate) || 0:
            record.edited.rate != undefined?
                parseFloat(record.edited.rate) || 0:
                record.record.rate;
        newEdited.commission = calculateCommissionByDays(rate, days).toFixed(2);
    }

    if(newEdited.statusCode != undefined) {
        const statusCode = parseInt(newEdited.statusCode);

        const previousStatus = record.edited.statusCode != undefined? parseInt(record.edited.statusCode): record.record.statusCode;

        if(!RecordStatus.PAID_STATUSES.includes(statusCode)) {
            // if the new status is not paid, set paidCt to 0
            newEdited.paidCt = (0).toFixed(2);
        }
        else if(RecordStatus.PAID_STATUSES.includes(statusCode) && !RecordStatus.PAID_STATUSES.includes(previousStatus)) {
            // if the new status IS paid, and the previous status was unpaid, set the paidCt back to the commission amount
            newEdited.paidCt = record.edited.commission ?? record.record.commission.toFixed(2);
        }

        // if status code set back to initial value, restore the substatus as well
        if(statusCode === record.record.statusCode) {
            newEdited.substatusCode = record.record.substatusCode != undefined? record.record.substatusCode.toString(): '';
        } else {
            newEdited.substatusCode = '';
        }
    }

    // paidCt could have been updated by a change to paidCt or statusCode
    if(newEdited.paidCt != undefined) {
        const paidCt = parseFloat(newEdited.paidCt) || 0;
        const previousPaymentMethod = record.edited.paymentMethodId ?? (record.record.paymentMethodId != undefined? record.record.paymentMethodId.toString(): '');

        // update payment method
        if(paidCt === 0) {
            newEdited.paymentMethodId = '';
        }
        else if(previousPaymentMethod === '') {
            newEdited.paymentMethodId = formInputs.paymentMethod;
        }
    }

    const editedResult = {
        ...record.edited,
        ...newEdited
    };

    // if any of the new edited properties match the original record, delete them from 'edited'
    Object.entries(editedResult).forEach(([k, v]) => {
        const key = k as keyof RecordInput['edited'];
        // NOTE: parseFloat only works here because all the 'edited' strings map to numbers in the underlying InvoiceRecord
        // the exceptions to the case above are that a "N/A" payment method, represented by the empty string, maps to an undefined payment method in the record, and the "None" substatus, represented by the empty string, maps to an undefined substatus
        if(record.record[key] === parseFloat(v)
        || (key === 'paymentMethodId' && record.record[key] == undefined && v === '' )
        || (key === 'substatusCode' && record.record[key] == undefined && v === '')) {
            delete editedResult[key];
        }

    });

    return {
        ...record,
        edited: editedResult
    };
}

export const InvoiceReconciliation = (props: InvoiceReconciliationProps) => {
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [formInputs, setFormInputs] = useState<InvoiceInputs>(() => {
        const paymentAmount = props.invoice.records
            .filter((r) => RecordStatus.BILLABLE_STATUSES.includes(r.statusCode))
            .reduce((acc, r) => acc + r.commission, 0);

        return {
            // set paymentAmount to the value that pendingPaid will be initially calculated as (commission from all billable records)
            checkNumber: '',
            paymentAmount: paymentAmount.toFixed(2),
            paymentMethod: paymentAmount > 0? (PaymentMethods.Check).toString(): '',
            processDate: easternTimezonedDayjs().format('YYYY-MM-DD'),
        };
    });
    const [records, dispatchRecords] = useReducer<(records: RecordInputMap, event: RecordEvent) => RecordInputMap, InvoiceRecord[]>((records: RecordInputMap, event: RecordEvent) => {
        switch(event.type) {
            case 'edit': {
                // multiEdit fields are the fields that can be updated in multiple records at once using the selection checkboxes.
                const multiEditFields: Array<keyof RecordInput['edited']> = ['paymentMethodId', 'statusCode'];
                if(records[event.recordId].selected && multiEditFields.includes(event.property)) {
                    // update all selected records
                    return Object.entries(records).reduce((newRecords, [id, record]) => {
                        if(record.selected) {
                            newRecords[id] = editRecord(record, event.property, event.value, formInputs);
                        }

                        return newRecords;
                    }, {...records});
                }

                return {
                    ...records,
                    [event.recordId]: editRecord(records[event.recordId], event.property, event.value, formInputs)
                };
            }
            case 'select': {
                if(event.recordId == undefined) {
                    // update all unreconciled records
                    return Object.entries(records)
                        .reduce((newRecords, [id, record]) => {
                        newRecords[id] = {
                            ...record,
                            selected: record.reconciled? false: event.selected
                        };
                        return newRecords;
                    }, {} as RecordInputMap);
                }

                const previousRecord = records[event.recordId];
                return {
                    ...records,
                    [event.recordId]: {
                        ...previousRecord,
                        selected: event.selected
                    }
                };
            }
            case 'reconcile': {
                if(event.recordId == undefined) {
                    // update all records
                    return Object.entries(records).reduce((newRecords, [id, record]) => {
                        newRecords[id] = {
                            ...record,
                            reconciled: event.reconciled,
                            selected: event.reconciled? false: record.selected
                        };
                        return newRecords;
                    }, {} as RecordInputMap);
                }

                const previousRecord = records[event.recordId];
                return {
                    ...records,
                    [event.recordId]: {
                        ...previousRecord,
                        reconciled: event.reconciled
                    }
                };
            }
        }
    },
    props.invoice.records,
    (records) => {
        return records.reduce((r, record) => {
            r[record.id] = {
                record: record,
                reconciled: record.isProcessed,
                selected: false,
                edited: (!record.isProcessed)? {
                    statusCode: RecordStatus.PAID.toString(),
                    paidCt: record.commission.toFixed(2),
                    paymentMethodId: formInputs.paymentMethod
                }: {}
            };
            return r;
        }, {} as RecordInputMap)
    });

    // calculate totals
    let totalDue = 0;
    let totalPaid = 0;

    let pendingPaid = 0;

    let remainingPaid = 0;

    Object.values(records).forEach((record) => {
        const commission = record.edited.commission != undefined?
            parseFloat(record.edited.commission):
            record.record.commission;

        const paidCt = record.edited.paidCt != undefined? parseFloat(record.edited.paidCt) || 0: record.record.paidCt;

        const status = record.edited.statusCode != undefined?
            parseInt(record.edited.statusCode):
            record.record.statusCode;

        // totalDue only includes commissions on records that are billable or paid
        if(RecordStatus.BILLABLE_STATUSES.includes(status) || RecordStatus.PAID_STATUSES.includes(status)) {
            totalDue += commission;
        }
        totalPaid += paidCt;

        // "pending" records have a modified paidCt
        if(record.edited.paidCt) {
            pendingPaid += paidCt;
        }

        // "remaining" records are billable after reconciliation
        else if(RecordStatus.BILLABLE_STATUSES.includes(status)) {
            remainingPaid += paidCt;
        }
    });

    const processedPaid = totalPaid - pendingPaid - remainingPaid;

    const unreconciledRecords = Object.values(records).filter((r) => !r.reconciled);
    const editedRecords = Object.values(records).filter((r) => Object.keys(r.edited).length > 0);

    const onFormUpdated = (inputs: InvoiceInputs) => {
        if(parseFloat(inputs.paymentAmount) === 0) {
            inputs.paymentMethod = '';
        }
        else if(inputs.paymentMethod === '') {
            inputs.paymentMethod = PaymentMethods.Check.toString();
        }

        if(inputs.paymentMethod !== PaymentMethods.Check.toString()
            && inputs.paymentMethod !== PaymentMethods.ForeignCheck.toString()) {
            inputs.checkNumber = '';
        }

        setFormInputs(inputs);


        if(inputs.paymentMethod !== formInputs.paymentMethod) {
            // payment method changed, update all payment methods for records with a paid status and no preexisting payment method
            Object.entries(records)
                .filter(([id, record]) => {
                    const status = record.edited.statusCode != undefined? parseInt(record.edited.statusCode): record.record.statusCode;
                    return record.record.paymentMethodId == undefined && RecordStatus.PAID_STATUSES.includes(status);
                })
                .forEach(([id, record]) => {
                dispatchRecords({
                    type: 'edit',
                    recordId: id,
                    property: 'paymentMethodId',
                    value: inputs.paymentMethod
                })
            });
        }

        if(parseFloat(inputs.paymentAmount) === 0) {
            // set all unreconciled statusCode to NON_COMMISSIONABLE
            // also sets paidCt to 0 and paymentMethodId to ''
            // since this indirectly sets the payment method, these events must be dispatched after the payment method ones
            Object.entries(records)
                .filter(([recordId, record]) => !record.reconciled)
                .forEach(([recordId, record]) => {
                dispatchRecords({
                    type: 'edit',
                    recordId,
                    property: 'statusCode',
                    value: RecordStatus.NON_COMMISSIONABLE.toString()
                })
            });
        }
    };

    const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        setIsSubmitting(true);

        // submit all records with at least one field changed
        const editedRecords = Object.values(records).filter((r) => Object.keys(r.edited).length > 0);
        const billableRecords = Object.values(records).filter((r) => {
            const status = r.edited.statusCode? parseInt(r.edited.statusCode): r.record.statusCode;
            return RecordStatus.BILLABLE_STATUSES.includes(status);
        });

        const patchRecords = editedRecords.reduce((recordMap, r) => {
            const recordPatch = {
                id: r.record.id,
                commission: r.edited.commission != undefined? parseFloat(r.edited.commission): undefined,
                days: r.edited.days != undefined? parseInt(r.edited.days): undefined,
                paidCt: r.edited.paidCt != undefined? parseFloat(r.edited.paidCt): undefined,
                paymentMethodId: r.edited.paymentMethodId != undefined? parseInt(r.edited.paymentMethodId) || null: undefined,
                rate: r.edited.rate != undefined? parseFloat(r.edited.rate): undefined,
                statusCode: r.edited.statusCode != undefined? parseFloat(r.edited.statusCode): undefined,
                substatusCode: r.edited.substatusCode != undefined? parseInt(r.edited.substatusCode) || null: undefined,
            };

            // delete undefined fields, but include null fields (null paymentMethodId unsets previous paymentMethod)
            Object.entries(recordPatch).forEach(([key, value]) => {
                if(value === undefined) {
                    delete recordPatch[key as keyof ReconcileInvoiceRecordParams];
                }
            });

            recordMap[recordPatch.id] = recordPatch;
            return recordMap;
        }, {} as {[id: string]: ReconcileInvoiceRecordParams});

        const reconcileInvoiceParams: ReconcileInvoiceParams = {
            checkNumber: formInputs.checkNumber,
            completed: billableRecords.length === 0,
            paymentAmount: parseFloat(formInputs.paymentAmount),
            paymentMethod: formInputs.paymentMethod !== ''? parseInt(formInputs.paymentMethod) : null,
            processDate: formInputs.processDate,
            records: patchRecords,
        };

        updateInvoiceRecords(props.invoice.id.toString(), reconcileInvoiceParams)
        .then((response) => {
            setIsSubmitting(false);
            toast.success(`Invoice Saved. ${Object.keys(reconcileInvoiceParams.records).length} records updated.`);
            props.onInvoiceUpdated(new Invoice(response.data.invoice, response.data.records));
        })
        .catch((error) => {
            setIsSubmitting(false)

            const { response } = error;

            if (response?.status === 403) {
                toast.error('Standard permissions required to reconcile invoice', { autoClose: false });
            }
            else {
                toast.error(response?.data?.message || 'Unknown Error', { autoClose: false })
            }

            console.error(error)
        });
    };

    return <div className="invoice-reconciliation container">
        <div className="invoice-reconciliation-header">
            <div>
                <h4 className="invoice-id">
                    Invoice #<Link to={`/invoices/${props.invoice.id}`}>{props.invoice.id} </Link>
                    <a href={downloadInvoice.replace('{invoiceId}', props.invoice.id.toString())}
                        className="pdf-download"
                        download>
                        <sup>PDF</sup>
                    </a>
                </h4>

                {props.invoice.completed && (
                    <span className='badge bg-success invoice-completed'>Completed</span>
                )}
                <h4>
                    <Link to={`/suppliers/${props.invoice.supplier.id}`}>
                        {props.invoice.supplier.name}
                    </Link>
                </h4>
            </div>
            <div className="invoice-totals">
                <table className="table-due">
                    <tbody>
                        <tr>
                            <th scope="row">Original Due</th>
                            <td className="due-original-total">{currencyFormat(props.invoice.total)}</td>
                        </tr><tr>
                            <th scope="row">Unbillable</th>
                            <td className="due-unbillable">- {currencyFormat(props.invoice.total - totalDue)}</td>
                        </tr>
                        <tr className="total-row">
                            <th scope="row">Total Due</th>
                            <td className="due-total">= {currencyFormat(totalDue)}</td>
                        </tr>
                    </tbody>
                </table>
                <table className="table-paid">
                    <tbody>
                        <tr>
                            <th scope="row">Payment Processed</th>
                            <td className="paid-processed">{currencyFormat(processedPaid)}</td>
                        </tr><tr>
                            <th scope="row">Payment Pending</th>
                            <td className="paid-pending">+ {currencyFormat(pendingPaid)}</td>
                        </tr>
                        <tr className="total-row">
                            <th scope="row">Total Paid</th>
                            <td className="paid-total">= {currencyFormat(totalPaid)}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div className="row mb-3">
            <InvoiceForm
                form={formInputs}
                paidCtPending={pendingPaid}
                unreconciledCount={unreconciledRecords.length}
                modifiedCount={editedRecords.length}
                isSubmitting={isSubmitting}
                paymentMethods={props.paymentMethods}
                onFormUpdated={onFormUpdated}
                onSubmit={onSubmit}
                supplierStatus={props.invoice.supplier.status} />
        </div>
        <div className="row">
            <ReconciliationTable
                records={records}
                onRecordUpdated={dispatchRecords}
                paymentMethods={props.paymentMethods}
                recordStatuses={props.recordStatuses} />
        </div>
    </div>;
};
