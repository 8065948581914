import React, { useState, FormEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { InsufficientPermissions } from '../common'
import { getAuthUser } from '../../helpers'
import { createRecord, CreateRecordParams } from '../../api'
import { RecordForm } from './form'
import defineAbilityFor from './defineAbility'

export const AddRecordForm = () => {
    const navigate = useNavigate()
    const ability = defineAbilityFor(getAuthUser())
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleSubmit = (
        e: FormEvent<HTMLFormElement>,
        inputs: CreateRecordParams
    ) => {
        e.preventDefault()
        setIsSubmitting(true)

        createRecord(inputs)
            .then(json => {
                setIsSubmitting(false)
                toast.success('Record Created')
                navigate(`/records/${json.data.id}`)
            })
            .catch(error => {
                const { response } = error
                setIsSubmitting(false)
                if (response.data.message) {
                    toast.error(response.data.message, { autoClose: false })
                }

                if (response.status === 400) {
                    toast.error(response.data, { autoClose: false })
                }
            })
    }

    if (!ability.can('Create', 'Record')) {
        return <InsufficientPermissions />
    }

    return (
        <div className='container card col-xs-12 col-xl-6'>
            <div className='card-body col-xs-12 row d-flex flex-row justify-content-between mb-5'>
                <div className='row flex-row mb-5'>
                    <h3 className='ps-0 d-inline-flex card-title w-50'>
                        Create New Record
                    </h3>
                </div>
                <RecordForm handleSubmit={handleSubmit} isSubmitting={isSubmitting} />
            </div>
        </div>
    )
}
