import React, { useEffect, useState } from 'react'

import { getRecordStatuses } from '../../api'
import { JsonRecordStatus, RecordStatus, RecordSubstatus } from '../../models'

interface FilterProps {
    updateFilters: (
        filterName: string,
        newValue: string,
        shouldDelete?: boolean,
        dependentFilterToClear?: string
    ) => void
}

export const RecordStatusFilters = (props: FilterProps) => {
    const { updateFilters } = props

    const [recordStatuses, setRecordStatuses] = useState<RecordStatus[]>([])
    const [selectedParentStatus, setSelectedParentStatus] = useState<RecordStatus>()

    useEffect(() => {
        getRecordStatuses()
            .then(json => setRecordStatuses(
                json.data.map((status: JsonRecordStatus) => new RecordStatus(status))
            ))
    }, [])

    return (
        <>
        <label htmlFor='recordStatusFilter'>Record Status</label>
        <select
            className='form-select'
            id='recordStatusFilter'
            onChange={(e) => {
                updateFilters(
                    'record_status_code',
                    e.target.value ?? '',
                    e.target.value === '-1',
                    'record_substatus_code'
                )
                setSelectedParentStatus(
                    recordStatuses.find(status => status.code === +e.target.value)
                )
            }}>
        <option value={-1}>
            All
        </option>
        {recordStatuses.map((status: RecordStatus) => (
            <option key={status.code} value={status.code}>
                {status.name}
            </option>
        ))}
        </select>
        {selectedParentStatus?.substatuses &&
            <>
            <label htmlFor='recordSubstatusFilter'>Record Sub Status</label>
            <select
                className='form-select'
                id='recordSubstatusFilter'
                onChange={(e) => {
                    updateFilters(
                        'record_substatus_code',
                        e.target.value ?? '',
                        e.target.value === '-1'
                    )
                }}>
                <option value={-1}>
                    All
                </option>
                {selectedParentStatus.substatuses.map((substatus: RecordSubstatus) => (
                    <option key={substatus.code} value={substatus.code}>
                        {substatus.name}
                    </option>
                ))}
            </select>
            </>
        }
        </>
    )
}
