import React from 'react'

interface FilterProps {
    updateFilters: (filterName: string, newValue: string, shouldDelete?: boolean) => void
}

export const InvoiceFilters = (props: FilterProps) => {
    const { updateFilters } = props

    return (
        <>
        <label htmlFor='completedFilter'>Completed</label>
        <select
            className='form-select'
            id='completedFilter'
            onChange={(e) => updateFilters(
                'completed',
                e.target.value,
                e.target.value === '-1'
            )}>
            <option value={'-1'}>All</option>
            <option value={'0'}>No</option>
            <option value={'1'}>Yes</option>
        </select>
        </>
    )
}
