import { JsonModel, Model } from './model'

export class Role extends Model {
    name: string

    static EXECUTIVE = 'executive';
    static STANDARD = 'standard';
    static API = 'api';
    static LEAD = 'lead';
    static CONTRIBUTOR = 'contributor';
    static SENIOR = 'senior';
    static ACCOUNTING = 'accounting';
    static SRLEAD = 'srlead';
    static COLLECTIONAGENT = 'collectionagent';

    constructor(json: JsonRole) {
        super(json)

        this.name = json.name
    }
}

export interface JsonRole extends JsonModel {
    name: string
}
