import { JsonModel, Model } from './model'

export class RecordChange extends Model {
    oldValue: string;
    newValue: string;
    oldSubstatus?: string;
    newSubstatus?: string;
    createdBy: string;

    constructor(json: JsonRecordChange) {
        super(json);

        this.oldValue = json.oldValue;
        this.newValue = json.newValue;
        this.oldSubstatus = json.oldSubstatus;
        this.newSubstatus = json.newSubstatus;
        this.createdBy = json.createdBy;
    }
}

export interface JsonRecordChange extends JsonModel {
    oldValue: string;
    newValue: string;
    oldSubstatus?: string;
    newSubstatus?: string;
    createdBy: string;
}
