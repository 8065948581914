import React, { FormEvent, useRef } from 'react'
import { SupplierCollectionsContactParams } from '../../../api'
import { toast } from 'react-toastify'

interface ContactsFormProps {
    handleSubmit: (contact: SupplierCollectionsContactParams) => void
    initialInputs: SupplierCollectionsContactParams
    title: string
    supplierContactId?: string
}

export const ContactForm = (props: ContactsFormProps) => {
    const { initialInputs, handleSubmit, title } = props
    const formRef = useRef<HTMLFormElement>(null)

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        const form = formRef.current
        const inputs = {
            title: form?.titleInput.value,
            name: form?.nameInput.value,
            phone: form?.phoneInput.value,
            email: form?.emailInput.value,
        }

        if (!inputs.email && !inputs.phone) {
            toast.error("Email or phone field is required")
        } else {
            handleSubmit({...inputs})
        }
    }

    return (
        <form ref={formRef} onSubmit={onSubmit} className="d-flex flex-column align-items-start">
            <div className="d-flex justify-content-between w-100">
                <div className="p-2 flex-fill">
                    <input
                        className="form-control"
                        defaultValue={initialInputs.title}
                        id="titleInput"
                        required
                        type="text"
                    />
                </div>
                <div className="p-2 flex-fill">
                    <input
                        className="form-control"
                        defaultValue={initialInputs.name}
                        id="nameInput"
                        required
                        type="text"
                    />
                </div>
                <div className="p-2 flex-fill">
                    <input
                        className="form-control"
                        defaultValue={initialInputs.phone}
                        id="phoneInput"
                        type="text"
                    />
                </div>
                <div className="p-2 flex-fill">
                    <input
                        className="form-control"
                        defaultValue={initialInputs.email}
                        id="emailInput"
                        type="email"
                    />
                </div>
                <div className="p-2 flex-fill align-self-end">
                    <button className="btn btn-outline-success" type="submit">
                        {title === 'Edit Contact' ? 'Save' : 'Add'}
                    </button>
                </div>
            </div>
        </form>
    )
}
