import React, { FormEvent, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { registerUser, RegisterUserParams } from '../../api'

export const RegisterUser = () => {
    const [values, setValues] = useState<RegisterUserParams>({
        name: '',
        email: '',
        password: '',
        password_confirmation: ''
    })
    const [userRegistered, setUserRegistered] = useState(false)

    const getInvalidFormMessage = () => {
        if (values.password !== values.password_confirmation) {
            return 'The password confirmation does not match'
        }

        if (values.password.length < 8) {
            return 'The password must be at least 8 characters'
        }

        return false
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const invalidMessage = getInvalidFormMessage()

        if (invalidMessage) {
            toast.error(invalidMessage, { autoClose: false })
        } else {
            registerUser(values)
                .then(response => {
                    if (response.status === 201) {
                        setUserRegistered(true)
                        toast.success('User Created')
                    }
                })
                .catch(error => {
                    toast.error(error.response.data.message, {
                        autoClose: false
                    })
                })
        }
    }

    if (userRegistered) {
        return <Navigate to='/users' replace={true} />
    }

    return (
        <div className='container'>
            <div className='row justify-content-center'>
                <div className='col-md-8'>
                    <div className='card'>
                        <div className='card-header'>Register</div>
                        <div className='card-body d-flex justify-content-center'>
                            <form
                                className='w-50'
                                method='POST'
                                onSubmit={(e) => handleSubmit(e)}>
                                <div className='row form-floating mb-3'>
                                    <input
                                        className='form-control'
                                        onChange={(e) => setValues({
                                            ...values,
                                            name: e.target.value
                                        })}
                                        id='name'
                                        type='text' />
                                    <label htmlFor='name'>Name</label>
                                </div>
                                <div className='row form-floating mb-3'>
                                    <input
                                        className='form-control'
                                        onChange={(e) => setValues({
                                            ...values,
                                            email: e.target.value
                                        })}
                                        id='email'
                                        type='email' />
                                    <label htmlFor='email'>Email</label>
                                </div>
                                <div className='row form-floating mb-3'>
                                    <input
                                        className='form-control'
                                        onChange={(e) => setValues({
                                            ...values,
                                            password: e.target.value
                                        })}
                                        id='password'
                                        type='password' />
                                    <label htmlFor='password'>Password</label>
                                </div>
                                <div className='row form-floating mb-3'>
                                    <input
                                        className='form-control'
                                        onChange={(e) => setValues({
                                            ...values,
                                            password_confirmation: e.target.value
                                        })}
                                        id='confirmPassword'
                                        type='password' />
                                    <label htmlFor='confirmPassword'>Confirm Password</label>
                                </div>
                                <div className='row mb-0'>
                                    <div className='col-md-6 offset-md-4'>
                                        <button type='submit' className='btn btn-primary'>
                                            Register
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
