import { JsonModel, Model } from './model'

export class VendorCode extends Model {

    id: number
    code: string

    constructor(json: JsonVendorCode) {
        super(json)

        this.id = json.id
        this.code = json.code
    }
}

export interface JsonVendorCode extends JsonModel {
    id: number
    code: string
}
