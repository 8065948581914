import React, { FormEvent } from 'react'
import { useNavigate } from 'react-router-dom'

import { ChainCreateForm } from './createForm'
import { createChain, CreateChainParams } from '../../api'
import { toast } from 'react-toastify'

export const AddChainForm = () => {
    const navigate = useNavigate()

    const handleSubmit = (
        e: FormEvent<HTMLFormElement>,
        inputs: CreateChainParams
    ) => {
        e.preventDefault()
        createChain(inputs)
            .then(json => {
                toast.success('Chain created successfully')
                navigate(`/chains/${String(json.data.id)}`)
            })
            .catch(error => {
                const { response } = error
                if (response.data.message) {
                    toast.error(`Error creating chain: ${response.data.message}`, { autoClose: false })
                }
                if (response.status === 400) {
                    toast.error(JSON.stringify(response.data.message), { autoClose: false })
                }
            })
    }


    return (
        <div className='container card col-xs-12 col-xl-6'>
            <div className='card-body col-xs-12 row d-flex flex-row justify-content-between mb-5'>
                <div className='row flex-row mb-5'>
                    <h3 className='ps-0 d-inline-flex card-title w-50'>
                        Add Chain
                    </h3>
                </div>
                <ChainCreateForm handleSubmit={handleSubmit} />
            </div>
        </div>
    )
}
