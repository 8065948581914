import React, { useEffect, useState } from 'react'

import { InsufficientPermissions, PaginatedTable, PaginatedTableCallbacks } from '../../common'
import { SupplierCollectionsTable } from './collectionTable'
import { AssignSuppliersForm } from './assignSupplierForm'
import defineAbilityFor from './../defineAbility'
import { JsonSupplierBalance, JsonUser, SupplierBalance, User } from '../../../models'
import { getSupplierBalances, getSupplierBalancesParams, getUsers, getUnassignedSupplierCollectionsCount } from '../../../api'
import { Roles, getAuthUser, userHasRole } from '../../../helpers'
import { toast } from 'react-toastify'
import { SupplierFilters } from './filters'
import { SuppliersAdvancedSearch } from '..'

export const SupplierCollectionsLanding = () => {
    const ability = defineAbilityFor(getAuthUser())
    const [collectionAgentUsers, setCollectionAgentUsers] = useState<User[]>([])
    const [selectedSuppliers, setSelectedSuppliers] = useState<number[]>([]);

    const toggleSupplierSelection = (id: number) => {
        if (selectedSuppliers.includes(id)) {
            setSelectedSuppliers(selectedSuppliers.filter(supplierId => supplierId !== id));
        } else {
            setSelectedSuppliers([...selectedSuppliers, id]);
        }
    }

    useEffect(() => {
        getUsers()
            .then(json => {
                const usersData: JsonUser[] = json.data
                const parsedUsers: User[] = usersData.map((user: JsonUser) => new User(user))
                const collectionAgents: User[] = parsedUsers.filter(user => userHasRole(user, Roles.CollectionAgent));
                setCollectionAgentUsers(collectionAgents)
            })
            .catch(error => toast.error(error, { autoClose: false }))
    }, [])

    const getSuppliersWithMinimumBalance = (params?: { page?: string }) => {
        const queryParams: getSupplierBalancesParams = {
            minimumBalance: 25000,
            ...params
        }
        return getSupplierBalances(queryParams)
    }

    if (!ability.can('View', 'SupplierCollections')) {
        return <InsufficientPermissions />
    }

    return (
        <PaginatedTable<SupplierBalance, JsonSupplierBalance>
            getData={(params) => getSuppliersWithMinimumBalance(params)}
            modelConstructor={(json: JsonSupplierBalance) => new SupplierBalance(json)}
            Filters={(props) => (<SupplierFilters {...props} />)}
            Form={
                ability.can('AssignToUser', 'Supplier')
                    ? (props) => (
                        <div style={{ marginTop: '-18px' }}>
                            <AssignSuppliersForm
                                users={collectionAgentUsers}
                                {...props}
                                getSupplierCount={getUnassignedSupplierCollectionsCount}
                                selectedSuppliers={selectedSuppliers}
                                setSelectedSuppliers={setSelectedSuppliers}
                            />
                        </div>
                    )
                    : undefined
            }
            disableSearch={true}
            AdvancedSearchComponent={SuppliersAdvancedSearch}
            Table={(
                supplierBalances: SupplierBalance[],
                callbacks: PaginatedTableCallbacks
            ) => (
                <SupplierCollectionsTable
                    callbacks={callbacks}
                    resources={{ supplierBalances }}
                    selectedSuppliers={selectedSuppliers}
                    toggleSupplierSelection={toggleSupplierSelection}
                    users={collectionAgentUsers} 
                    setSelectedSuppliers={setSelectedSuppliers} />
            )}
            title="Supplier Collections"
        />
    )
}
