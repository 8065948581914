import React, {
    Dispatch,
    FormEvent,
    SetStateAction
} from 'react'
import { toast } from 'react-toastify'

import { getAuthUser } from '../../helpers';
import { updateChain, UpdateChainParams } from '../../api'
import { Chain } from '../../models'
import { ChainForm } from './form'
import defineAbilityFor from './defineAbility'
import { InsufficientPermissions } from '../common';

interface EditChainProps {
    chain: Chain
    setIsEditing: Dispatch<SetStateAction<boolean>>
    setChain: Dispatch<SetStateAction<Chain | undefined>>
}

export const EditChainForm = (props: EditChainProps) => {
    const { chain, setIsEditing, setChain } = props

    const ability = defineAbilityFor(getAuthUser())

    const handleSubmit = (e: FormEvent<HTMLFormElement>, inputs: UpdateChainParams) => {
        e.preventDefault()

        updateChain(chain.id.toString(), inputs)
            .then(json => {
                setChain(new Chain(json.data))
                setIsEditing(false)
                toast.success('Chain Edited')
            })
            .catch(error => {
                const { response } = error

                if (response.data.message) {
                    toast.error(response.data.message, { autoClose: false })
                }

                if (response.status === 400) {
                    toast.error(response.data, { autoClose: false })
                }
            })
    }

    if (!ability.can('Edit', 'Chain')) {
        return <InsufficientPermissions />
    }

    return (
        <ChainForm
            handleSubmit={handleSubmit}
            chain={chain} />
    )
}
