import React, { useState } from 'react'

import { InsufficientPermissions, PaginatedTable, PaginatedTableCallbacks } from '../../common'
import { SupplierBalancesTable } from './collectionTable'
import { JsonSupplierBalance, SupplierBalance } from '../../../models'
import { getSupplierBalances } from '../../../api'
import { downloadSupplierBalancesCsv } from '../../../api/routes'
import defineAbility from '../defineAbility'
import { getAuthUser } from '../../../helpers'

export const SupplierBalanceLanding = () => {

    const ability = defineAbility(getAuthUser())
    const [sortBy, setSortBy] = useState('balance')
    const [sortDirection, setSortDirection] = useState('desc')

    if (!ability.can('View', 'SupplierBalances')) {
        return <InsufficientPermissions />
    }

    const handleSortChange = (field: string) => {
        if (sortBy === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
        } else {
            setSortBy(field)
            setSortDirection('asc')
        }
    }

    return (
        <PaginatedTable<SupplierBalance, JsonSupplierBalance>
            getData={(params) => getSupplierBalances({ ...params, sortBy, sortDirection })}
            modelConstructor={(json: JsonSupplierBalance) => new SupplierBalance(json)}
            searchPlaceholder="Search by Supplier Name or Phone"
            Table={(
                supplierBalances: SupplierBalance[],
                callbacks: PaginatedTableCallbacks
            ) => (
                <SupplierBalancesTable
                    callbacks={callbacks}
                    resources={{ supplierBalances }}
                />
            )}
            title="Supplier Balances"
            DownloadButton={() => (
                <a
                    href={downloadSupplierBalancesCsv}
                    className="btn btn-primary"
                    download="SupplierBalances.csv"
                >
                    Download CSV
                </a>
            )}
            sortOptions={{
                sortBy,
                sortDirection,
                onSortChange: handleSortChange,
                sortFields: [
            { field: 'name', label: 'Name' },
            { field: 'balance', label: 'Balance' }
            ]}}
        />
    )
}
