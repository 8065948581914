import React, { FormEvent, useEffect, useState } from 'react'

import {
    Agency,
    AgencyStatus,
    AgencySoftwareType,
    JsonAgencySoftwareType,
    JsonAgencyStatus,
    JsonPaymentMethod,
    PaymentMethod
} from '../../models'
import {
    getAgencyPaymentMethods,
    getAgencyStatuses,
    UpdateAgencyParams,
    getAgencySoftwareTypes
} from '../../api'
import { getAuthUser } from '../../helpers'
import defineAbilityFor from './defineAbility'

interface EditFormProps {
    handleSubmit: (
        e: FormEvent<HTMLFormElement>,
        inputs: UpdateAgencyParams
    ) => void
    isSubmitting?: boolean
    agency?: Agency
}

export const AgencyEditForm = (props: EditFormProps) => {
    const { handleSubmit, isSubmitting, agency } = props

    const defaultInputs = {
        branch: agency?.branch ?? '',
        firstName: agency?.firstName ?? '',
        lastName: agency?.lastName ?? '',
        ein: agency?.ein ?? '',
        phone_number: agency?.phone ?? '',
        name: agency?.name ?? '',
        status: agency?.status.code ?? AgencyStatus.ACTIVE,
        paymentMethod: agency?.preferredPaymentMethod?.id ?? '',
        softwareType: agency?.softwareType?.id ?? '',
    }

    const [agencyStatuses, setAgencyStatuses] = useState([])
    const [inputs, setInputs] = useState(defaultInputs)
    const [paymentMethods, setPaymentMethods] = useState([])
    const [softwareTypes, setSoftwareTypes] = useState([])

    const ability = defineAbilityFor(getAuthUser())

    useEffect(() => {
        getAgencyStatuses()
            .then(json => setAgencyStatuses(
                json.data.map((status: JsonAgencyStatus) => new AgencyStatus(status))
            ))

        getAgencyPaymentMethods().then(json => {
            setPaymentMethods(json.data.map(
                (paymentMethod: JsonPaymentMethod) => new PaymentMethod(paymentMethod))
            )
        })

        getAgencySoftwareTypes().then(json => {
            setSoftwareTypes(json.data.map(
                (softwareType: JsonAgencySoftwareType) => new AgencySoftwareType(softwareType))
            )
        })
    }, [])

    const updateInputs = (field: string, value: number | string) => {
        setInputs({
            ...inputs,
            [field]: value
        })
    }

    return (
        <form onSubmit={(e) => handleSubmit(e, inputs)}>
            <div className="row mb-4">
                <div className="col-12">
                    <label htmlFor="statusInput">Status</label>
                    <select
                        className="form-select"
                        disabled={!ability.can('Edit', 'AgencyStatus')}
                        id="statusInput"
                        onChange={(e) => updateInputs('status', +e.target.value)}
                        value={inputs.status}
                    >
                        {agencyStatuses.map((status: AgencyStatus) => (
                            <option key={status.code} value={status.code}>
                                {status.name}
                            </option>
                        ))}
                    </select>
                    <label htmlFor="nameInput">Name</label>
                    <input
                        className="form-control"
                        id="nameInput"
                        onChange={(e) => updateInputs('name', e.target.value)}
                        type="text"
                        required
                        value={inputs.name}
                    />
                    <label htmlFor="einInput">EIN</label>
                    <input
                        className="form-control"
                        id="einInput"
                        onChange={(e) => updateInputs('ein', e.target.value)}
                        maxLength={10}
                        type="text"
                        required
                        value={inputs.ein}
                    />
                    <label htmlFor="phoneInput">Phone</label>
                    <input
                        className="form-control"
                        id="phoneInput"
                        onChange={(e) => updateInputs('phone_number', e.target.value)}
                        type="text"
                        required
                        value={inputs.phone_number}
                    />
                    <label htmlFor="branchInput">Branch</label>
                    <input
                        className="form-control"
                        id="branchInput"
                        onChange={(e) => updateInputs('branch', e.target.value)}
                        maxLength={10}
                        type="text"
                        value={inputs.branch}
                    />
                    <label htmlFor="firstNameInput">First Name</label>
                    <input
                        className="form-control"
                        id="firstNameInput"
                        onChange={(e) => updateInputs('firstName', e.target.value)}
                        type="text"
                        value={inputs.firstName}
                    />
                    <label htmlFor="lastNameInput">Last Name</label>
                    <input
                        className="form-control"
                        id="lastNameInput"
                        onChange={(e) => updateInputs('lastName', e.target.value)}
                        type="text"
                        value={inputs.lastName}
                    />
                    <label htmlFor="paymentMethodInput">Preferred Payment Method</label>
                    <select
                        className="form-select"
                        id="paymentMethodInput"
                        onChange={(e) => updateInputs('paymentMethod', e.target.value)}
                        value={inputs.paymentMethod}
                    >
                        <option value="">Unknown</option>
                        {paymentMethods.map((paymentMethod: PaymentMethod) => (
                            <option
                                key={`payment_method_${paymentMethod.id}`}
                                value={paymentMethod.id}
                            >
                                {paymentMethod.name}
                            </option>
                        ))}
                    </select>
                    <label htmlFor="softwareTypeInput">Booking Software</label>
                    <select
                        className="form-select"
                        disabled={!ability.can('Edit', 'AgencyBookingSoftware')}
                        id="softwareTypeInput"
                        onChange={(e) => updateInputs('softwareType', e.target.value)}
                        value={inputs.softwareType}
                    >
                        <option value="">Unknown</option>
                        {softwareTypes.map((softwareType: AgencySoftwareType) => (
                            <option
                                key={`software_type_${softwareType.id}`}
                                value={softwareType.id}>
                                {softwareType.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            <div className="row mb-5">
                <div className="col-12 d-flex justify-content-center">
                    <button
                        className="btn btn-primary"
                        disabled={!!isSubmitting}
                        type="submit"
                    >
                        Submit
                    </button>
                </div>
            </div>
        </form>
    )
}
