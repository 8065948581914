import { defineAbility } from '@casl/ability'

import { User } from '../../../models'
import { Roles, userHasRole } from '../../../helpers'

export default (user: User) => defineAbility((can) => {
    if (userHasRole(user, Roles.Lead)) {
        can('Create', 'Arc')
        can('Edit', 'Arc')
    }
    if (userHasRole(user, Roles.Executive)) {
        can('Delete', 'Arc')
    }
})
