import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('America/New_York')

const easternTimezonedDayjs = (...args: dayjs.ConfigType[]) => dayjs(...args).tz()

export default easternTimezonedDayjs
