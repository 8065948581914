import React, { FormEvent, useState } from 'react'
import { toast } from 'react-toastify'

import { InsufficientPermissions, Loading } from '../common'
import { getAuthUser } from '../../helpers'
import { uploadVtsArcData } from '../../api'
import { DIRECT_TRAVEL, VISION_VOYAGES } from '../../models'

import defineAbilityFor from './defineAbility'

export const VtsArcDataUpload = () => {
    const [file, setFile] = useState<File>()
    const [fileKey, setFileKey] = useState(Date.now())
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [agencyId, setAgencyId] = useState(DIRECT_TRAVEL.toString())

    const ability = defineAbilityFor(getAuthUser())

    if (!ability.can('Upload', 'AgencyData')) {
        return <InsufficientPermissions />
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsSubmitting(true)

        if (file) {
            const form = new FormData()
            form.append('file', file)

            uploadVtsArcData(form, agencyId)
                .then(response => {
                    toast.success(response.data.updateCount + ' record ARCs updated')
                    setIsSubmitting(false)
                    setFile(undefined)
                    setFileKey(Date.now())
                })
                .catch(error => {
                    const { response } = error

                    if (response.data.message) {
                        toast.error(response.data.message, { autoClose: false })
                    }
                    setIsSubmitting(false)
                })
        }
    }

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-6'>
                    <h3>VTS ARC Upload</h3>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <select
                            className='form-select mb-3'
                            value={agencyId}
                            onChange={(e) => setAgencyId(e.target.value)}
                            required>
                            <option value={DIRECT_TRAVEL}>Direct Travel</option>
                            <option value={VISION_VOYAGES}>Vision Voyages</option>
                        </select>
                        <input
                            className='form-control mb-3'
                            key={fileKey}
                            onChange={(e) => {
                                const { files } = e.target

                                if (files) {
                                    setFile(files[0])
                                }
                            }}
                            type='file'
                            required />
                        <button
                            className='btn btn-primary'
                            disabled={isSubmitting}
                            type='submit'>
                            <span className='pe-2'>Upload</span>
                            {isSubmitting && <Loading sm={true} />}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    )
}
