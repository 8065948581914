import React from 'react'

import { PaginatedTable, PaginatedTableCallbacks } from '../common'
import { SuppliersAdvancedSearch } from './'
import { SupplierFilters } from './filters'
import { SuppliersTable } from './collectionTable'
import { JsonSupplier, Supplier } from '../../models'
import { getSuppliers } from '../../api'

export const SuppliersLanding = () => (
    <PaginatedTable<Supplier, JsonSupplier>
        getData={getSuppliers}
        Filters={SupplierFilters}
        redirectLink={{
            to: '/suppliers/add',
            text: 'Create New Supplier'
        }}
        AdvancedSearchComponent={SuppliersAdvancedSearch}
        modelConstructor={(json: JsonSupplier) => new Supplier(json)}
        disableSearch={true}
        Table={(suppliers: Supplier[], callbacks: PaginatedTableCallbacks) => (
            <SuppliersTable
                callbacks={callbacks}
                resources={{suppliers}} />
        )}
        title='Suppliers' />
)
