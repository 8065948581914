import React, {
    Dispatch,
    FormEvent,
    SetStateAction,
    useState
} from 'react'
import { toast } from 'react-toastify'

import defineAbilityFor from './defineAbility'
import { getAuthUser } from '../../helpers'
import { InsufficientPermissions } from '../common'
import { RecordForm } from './form'
import { updateRecord, UpdateRecordParams } from '../../api'
import { AgencyStatus, Record, RecordStatus } from '../../models'

interface EditRecordProps {
    record: Record
    setIsEditing: Dispatch<SetStateAction<boolean>>
    setRecord: Dispatch<SetStateAction<Record | undefined>>
}

export const EditRecordForm = (props: EditRecordProps) => {
    const { record, setIsEditing, setRecord } = props
    const agencyStatus = record.agency.status.code
    const ability = defineAbilityFor(getAuthUser())
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleSubmit = (e: FormEvent<HTMLFormElement>, inputs: UpdateRecordParams) => {
        e.preventDefault()
        setIsSubmitting(true)

        if (inputs.status === 46 && ![AgencyStatus.CLOSED, AgencyStatus.ON_HOLD].includes(agencyStatus)) {
            toast.error('The Inactive status can only be assigned to records whose agencies are Closed or On Hold.')
            return
        }

        updateRecord(record.id.toString(), inputs)
            .then(json => {
                setIsSubmitting(false)
                setRecord(new Record(json.data))
                setIsEditing(false)
                toast.success('Record Edited')
            })
            .catch(error => {
                const { response } = error

                if (response?.data?.message) {
                    toast.error(response.data.message, { autoClose: false })
                }

                if (response.status === 400) {
                    toast.error(response.data, { autoClose: false })
                }
                setIsSubmitting(false)
            })
    }

    const recordType = (() => {
        if (record.status.code === RecordStatus.PAID) {
            return 'PaidRecord'
        } else if (RecordStatus.REVIEW_STATUSES.includes(record.status.code)) {
            return 'NeedsReviewRecord'
        }
        return 'Record'
    })();

    if (!ability.can('Edit', recordType)) {
        return <InsufficientPermissions />
    }

    return (
        <RecordForm
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            record={record} />
    )
}
