import React from 'react'

import { Country } from '../../models'

interface CountryTableProps {
    country: Country
}

export const CountryTable = (props: CountryTableProps) => {
    const { country } = props

    return (
        <table className='table'>
            <tbody>
                <tr>
                    <th>Name</th>
                    <td>{country.name}</td>
                </tr>
                <tr>
                    <th>ISO Code</th>
                    <td>{country.abbreviation}</td>
                </tr>
                <tr>
                    <th>Billable</th>
                    <td>{country.billable ? 'Yes' : 'No'}</td>
                </tr>
                <tr>
                    <th>Currency</th>
                    <td>{country.currency}</td>
                </tr>
                <tr>
                    <th>Exchange Rate</th>
                    <td>{country.exchange}</td>
                </tr>
            </tbody>
        </table>
    )
}
