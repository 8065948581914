import React from 'react'

interface DeleteSupplierPhoneNumberProps {
    onDelete: () => void
    phone: string
}

export const DeleteSupplierPhoneNumber = (props: DeleteSupplierPhoneNumberProps) => {
    const { onDelete, phone } = props

    return (
        <form onSubmit={(e) => {
            e.preventDefault()

            onDelete()
        }}>
            <input
                className='form-control'
                type='text'
                readOnly={true}
                value={phone} />
            <span className='d-flex justify-content-center mt-3'>
                <button
                    className='btn btn-danger'
                    type='submit'>
                    Confirm Delete
                </button>
            </span>
        </form>
    )
}
