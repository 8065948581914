import React from 'react'
import { toast } from 'react-toastify'

import {
    createAddress,
    CreateAddressParams,
    SyncAgencyAddressesParams
} from '../../../api'
import { AddressForm } from '../../common'
import { Address } from '../../../models'

interface EditAddressProps {
    address: Address
    resetForm: () => void
    syncAgencyAddresses: (params: SyncAgencyAddressesParams) => void
}

export const EditAgencyAddress = (props: EditAddressProps) => {
    const { address, resetForm, syncAgencyAddresses } = props

    const handleSubmit = (inputs: CreateAddressParams) => {
        createAddress(inputs)
            .then((response) => {
                if (response.data.id !== address.id) {
                    syncAgencyAddresses({
                        addressToAttach: {
                            addressId: response.data.id,
                            isPayment: address.isPayment,
                            isPhysical: address.isPhysical
                        },
                        addressIdToDetach: address.id
                    })
                } else {
                    resetForm()
                }
            })
            .catch(error => {
                if (error?.response?.data?.message) {
                    toast.error(error.response.data.message, { autoClose: false })
                }
            })
    }

    return (
        <AddressForm
            address={address}
            handleSubmit={handleSubmit} />
    )
}
