import styled from 'styled-components'

export const HiddenSpan = styled.span`
    display: none !important;
`
export const TrDisplayHiddenSpanOnHover = styled.tr<{ primary: boolean }>`
    min-height: ${props => (props.primary ? '66' : '50')}px;
    &:hover ${HiddenSpan} {
        display: flex !important;
    }
    td {
        padding: 0px;
    }
`
