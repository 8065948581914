import React, { Dispatch, SetStateAction } from 'react'

import { getAuthUser } from '../../../helpers'
import {
    HiddenSpan,
    TooltipWrapper,
    TrDisplayHiddenSpanOnHover
} from '../../common'
import defineAbilityFor from './defineAbility'

interface TableProps {
    onEmailsChanged: (
        emails: string[],
        primaryEmail: string,
        successCallback?: () => void
    ) => void

    emails: string[]
    primaryEmail: string
    onEmailToDeleteChanged: Dispatch<SetStateAction<string>>
    onEmailToEditChanged: Dispatch<SetStateAction<string>>
}

export const AgencyEmailTable = (props: TableProps) => {
    const {
        onEmailsChanged,
        emails,
        primaryEmail,
        onEmailToDeleteChanged,
        onEmailToEditChanged
    } = props

    const ability = defineAbilityFor(getAuthUser())

    const updatePrimary = (email: string) => {
        onEmailsChanged(emails, email)
    }

    return (
        <table className='table table-sm'>
            <tbody>
                {[...emails]
                    .sort()
                    .sort((a, b) => {
                        if (a === primaryEmail) {
                            return -1
                        }
                        if (b === primaryEmail) {
                            return 1
                        }
                        return 0
                    })
                    .map(email => (
                        <TrDisplayHiddenSpanOnHover
                            className='d-flex'
                            key={`supplier_email_${email}`}
                            primary={email === primaryEmail}>
                            <td className='d-flex align-items-center' style={{ width: '45%' }}>
                                {email}
                            </td>
                            <td className='d-flex align-items-end flex-column ps-0 pe-0'
                                style={{ width: '55%' }}>
                                <span className='d-flex mb-2'>
                                    {email === primaryEmail &&
                                        <span className='badge bg-success rounded-pill ms-2'>
                                            Primary
                                        </span>
                                    }
                                </span>
                                <HiddenSpan className='d-flex mb-2 mt-auto align-items-end'>
                                    <form onSubmit={(e) => {
                                        e.preventDefault()

                                        updatePrimary(email)
                                    }}>
                                        <TooltipWrapper
                                            condition={email === primaryEmail
                                                || !ability.can('Update', 'AgencyEmail')
                                            }
                                            element={
                                                <button
                                                    className='btn btn-outline-primary btn-sm'
                                                    disabled={email === primaryEmail
                                                        || !ability.can('Update', 'AgencyEmail')
                                                    }
                                                    type='submit'>
                                                    Set as Primary
                                                </button>
                                            }
                                            message={email === primaryEmail
                                                ? 'Email is currently primary'
                                                : 'Insufficient permissions'
                                            } />
                                    </form>
                                    <TooltipWrapper
                                        condition={email === primaryEmail
                                            || !ability.can('Delete', 'AgencyEmail')
                                        }
                                        element={
                                            <button
                                                className='btn btn-outline-danger btn-sm ms-1'
                                                disabled={email === primaryEmail
                                                    || !ability.can('Delete', 'AgencyEmail')
                                                }
                                                onClick={() => onEmailToDeleteChanged(email)}>
                                                Delete
                                            </button>
                                        }
                                        message={(email === primaryEmail)
                                            ? 'Cannot delete primary email'
                                            : 'Insufficient permissions'
                                        } />
                                    <TooltipWrapper
                                        condition={!ability.can('Update', 'AgencyEmail')}
                                        element={
                                            <button
                                                className='btn btn-primary btn-sm mt-auto ms-1'
                                                disabled={
                                                    !ability.can('Update', 'AgencyEmail')
                                                }
                                                onClick={() => onEmailToEditChanged(email)}>
                                                Edit
                                            </button>
                                        }
                                        message='Insufficient permissions' />
                                </HiddenSpan>
                            </td>
                        </TrDisplayHiddenSpanOnHover>
                    ))}
            </tbody>
        </table>
    )
}
