import React from 'react'

import { PaginatedTable, PaginatedTableCallbacks } from '../common'
import { RecordsTable } from '.'
import { JsonRecord, Record } from '../../models'
import { getRecordsAssignedToUser } from '../../api'
import { getAuthUser } from '../../helpers'
import defineAbilityFor from './defineAbility'
import { InsufficientPermissions } from '../common'

export const RecordsAssignedToUser = () => {
    const ability = defineAbilityFor(getAuthUser())

    if (!ability.can('View', 'Record')) {
        return <InsufficientPermissions />
    }

    return (
        <PaginatedTable<Record, JsonRecord>
            getData={getRecordsAssignedToUser}
            modelConstructor={(json: JsonRecord) => new Record(json)}
            Table={(records: Record[], callbacks: PaginatedTableCallbacks) => (
                <RecordsTable
                    callbacks={callbacks}
                    resources={{ records }} />
            )}
            title='Records Assigned to Me' />
    )
}
