import { JsonModel, Model } from './model'

export class SupplierBalance extends Model {
    assignedUser: number
    balance: number
    supplierId: number
    supplierName: string
    supplierPhone: string

    constructor(json: JsonSupplierBalance) {
        super(json)

        this.assignedUser = json.assignedUser
        this.balance = json.balance
        this.supplierId = json.supplierId
        this.supplierName = json.supplierName
        this.supplierPhone = json.supplierPhone
    }
}

export interface JsonSupplierBalance extends JsonModel {
    assignedUser: number
    balance: number
    supplierId: number
    supplierName: string
    supplierPhone: string
}
