import { Dayjs } from 'dayjs'
import easternTimezonedDayjs from '../helpers/dayjsEastern'
import { Address, JsonAddress } from './address'
import { Chain, JsonChain } from './chain'
import { JsonModel, Model } from './model'
import { JsonPaymentMethod, PaymentMethod } from './paymentMethod'
import { JsonSupplierStatus, SupplierStatus } from './supplierStatus'
import { JsonSupplierSubstatus, SupplierSubstatus } from './supplierSubstatus'

export class Supplier extends Model {
    addresses: Address[]
    assignedUser: number
    balance: number
    billingAddress: Address
    chain: Chain | null
    currency: string
    email: string
    fax: string
    isCollections: boolean
    name: string
    dba: string | null
    physicalAddress: Address
    phoneNumbers: string[]
    preferredPaymentMethod: PaymentMethod | null
    primaryPhone: string
    recordCount: number
    reopenDate?: Dayjs
    lastInvoiceId?: number
    lastInvoiceDate?: Dayjs | null
    status: SupplierStatus
    substatus: SupplierSubstatus | null
    vendorId: number

    constructor(json: JsonSupplier) {
        super(json)

        this.addresses = json.addresses.map(address => new Address(address))
        this.assignedUser = json.assignedUser
        this.balance = parseFloat(json.balance)
        this.billingAddress = new Address(json.billingAddress)
        this.chain = json.chain ? new Chain(json.chain) : null
        this.currency = json.currency
        this.email = json.email ?? ''
        this.fax = json.fax
        this.isCollections = json.is_collections
        this.name = json.name
        this.dba = json.dba ?? null
        this.physicalAddress = new Address(json.physicalAddress)
        this.phoneNumbers = json.phoneNumbers
        this.preferredPaymentMethod = json.preferredPaymentMethod
            ? new PaymentMethod(json.preferredPaymentMethod)
            : null
        this.primaryPhone = json.primaryPhone
        this.recordCount = json.recordCount
        this.lastInvoiceId = json.lastInvoiceId ?? null
        this.lastInvoiceDate = json.lastInvoiceDate
            ? easternTimezonedDayjs(json.lastInvoiceDate)
            : null
        this.status = new SupplierStatus(json.status)
        this.substatus = json.substatus ? new SupplierSubstatus(json.substatus) : null;
        this.vendorId = json.vendorId
        this.isCollections = json.isCollections

        if (json.reopenDate) {
            this.reopenDate = easternTimezonedDayjs(json.reopenDate)
        }
    }
}

export interface JsonSupplier extends JsonModel {
    addresses: JsonAddress[]
    assignedUser: number
    balance: string
    billingAddress: JsonAddress
    chain: JsonChain | null
    currency: string
    email: string | null
    fax: string
    is_collections: boolean
    name: string
    dba: string | null
    physicalAddress: JsonAddress
    phoneNumbers: string[]
    preferredPaymentMethod: JsonPaymentMethod | null
    primaryPhone: string
    recordCount: number
    reopenDate: string | null
    lastInvoiceId: number
    lastInvoiceDate: string
    status: JsonSupplierStatus
    substatus: JsonSupplierSubstatus
    vendorId: number
    isCollections: boolean
}
