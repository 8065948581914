import { JsonModel, Model } from './model'

export enum PaymentMethods {
    CreditCard = 1,
    ACH,
    Check,
    Wire,
    ForeignCheck
}

export class PaymentMethod extends Model {
    public static CHECK_METHODS = [
        PaymentMethods.Check,
        PaymentMethods.ForeignCheck
    ]

    name: string

    constructor(json: JsonPaymentMethod) {
        super(json)

        this.name = json.name
    }
}

export interface JsonPaymentMethod extends JsonModel {
    name: string
}
