import React, { useState } from 'react'
import { toast } from 'react-toastify'

import { syncAgencyAddresses, SyncAgencyAddressesParams } from '../../../api'
import { TooltipWrapper } from '../../common'
import { getAuthUser } from '../../../helpers'
import { Address, JsonAddress } from '../../../models'
import { AddAgencyAddress } from './add'
import { EditAgencyAddress } from './edit'
import { AgencyAddressTable } from './table'
import defineAbilityFor from './defineAbility'

interface CardProps {
    addresses: Address[]
    agencyId: number
}

export const AgencyAddressesCard = (props: CardProps) => {
    const { agencyId } = props

    const [isAdding, setIsAdding] = useState(false)
    const [addressToEdit, setAddressToEdit] = useState<Address | undefined>()
    const [addresses, setAddresses] = useState(props.addresses)

    const ability = defineAbilityFor(getAuthUser())

    const resetForm = () => {
        toast.success('Agency Addresses Updated')

        setIsAdding(false)
        setAddressToEdit(undefined)
    }

    const agencyAddressCallback = (params: SyncAgencyAddressesParams) => {
        syncAgencyAddresses(agencyId.toString(), params)
            .then((response) => {
                const { data, status } = response

                if (status === 200 || status === 201) {
                    const { addresses } = data

                    setAddresses(addresses.map(
                        (address: JsonAddress) => new Address(address)
                    ))

                    resetForm()
                }
            })
            .catch((error) => {
                if (error?.response?.data?.message) {
                    toast.error(error.response.data.message, { autoClose: false })
                }
            })
    }

    return (
        <div className='container card mb-3'>
            <div className='
                card-body
                col-xs-12
                row
                d-flex
                flex-row
                justify-content-between
            '>
                <div className='row flex-row mb-5 pe-0'>
                    <h3 className='ps-0 d-inline-flex card-title w-50'>
                        Addresses
                    </h3>
                    <div className='pe-0 d-inline-flex justify-content-end w-50'>
                        {!isAdding && !addressToEdit &&
                            <TooltipWrapper
                                condition={!ability.can('Create', 'AgencyAddress')}
                                element={
                                    <button
                                        className='btn btn-primary'
                                        disabled={!ability.can('Create', 'AgencyAddress')}
                                        onClick={() => setIsAdding(true)}>
                                        Add New Address
                                    </button>
                                }
                                message='Insufficient permissions' />
                        }
                        {isAdding &&
                            <button
                                className='btn btn-outline-secondary'
                                onClick={() => setIsAdding(false)}>
                                Cancel
                            </button>
                        }
                        {addressToEdit &&
                            <button
                                className='btn btn-outline-secondary'
                                onClick={() => setAddressToEdit(undefined)}>
                                Cancel
                            </button>
                        }
                    </div>
                </div>
                <div className='row flex-row p-0'>
                    {!isAdding && !addressToEdit &&
                        <AgencyAddressTable
                            addresses={addresses}
                            setAddressToEdit={setAddressToEdit}
                            agencyId={agencyId} />
                    }
                    {isAdding &&
                        <AddAgencyAddress
                            syncAgencyAddresses={agencyAddressCallback} />
                    }
                    {addressToEdit &&
                        <EditAgencyAddress
                            address={addressToEdit}
                            resetForm={resetForm}
                            syncAgencyAddresses={agencyAddressCallback} />
                    }
                </div>
            </div>
        </div>
    )
}
