import React, { useEffect, useState } from 'react'

import { getVendorCodes } from '../../../api'
import { JsonVendorCode, VendorCode } from '../../../models/vendorCode'

interface FilterProps {
    updateFilters: (filterName: string, newValue: string, shouldDelete?: boolean) => void
}

export const InvoiceBatchFilters = (props: FilterProps) => {
    const { updateFilters } = props

    const [vendorCodes, setVendorCodes] = useState([])
    const [dateFrom, setDateFrom] = useState('')
    const [dateTo, setDateTo] = useState('')
    const [minimumCommission, setMinimumCommission] = useState('10')
    const [maximumCommission, setMaximumCommission] = useState('25000')
    const [locale, setLocale] = useState('All')
    const [minPages, setMinPages] = useState('1')
    const [maxPages, setMaxPages] = useState('50')
    const [vendorId, setVendorId] = useState('-1')

    useEffect(() => {
        getVendorCodes()
            .then(json => setVendorCodes(
                json.data.map((vendor: JsonVendorCode) => new VendorCode(vendor))
            ))
    }, [])

    useEffect(() => {
        if (locale === 'International') {
            setMinimumCommission('100');
            updateFilters(
                'minimum_commission',
                '100'
            )
        } else {
            setMinimumCommission('10');
            updateFilters(
                'minimum_commission',
                '10'
            )
        }
    }, [locale])

    return (
        <>
        <label htmlFor='checkInDateFromFilter'>Guest Check-in Date (From)</label>
        <input
            className='form-control'
            id='checkInDateFromFilter'
            onChange={(e) => {
                updateFilters(
                    'check_in_date_from',
                    e.target.value ?? '',
                    e.target.value === ''
                )

                setDateFrom(e.target.value)
            }}
            type='date'
            max={dateTo} />
        <label htmlFor='checkInDateToFilter'>Guest Check-in Date (To)</label>
        <input
            className='form-control'
            id='checkInDateToFilter'
            onChange={(e) => {
                updateFilters(
                    'check_in_date_to',
                    e.target.value ?? '',
                    e.target.value === ''
                )

                setDateTo(e.target.value)
            }}
            type='date'
            min={dateFrom} />
        <label htmlFor='localeFilter'>Locale</label>
        <select
            className='form-select'
            id='localeFilter'
            onChange={(e) => {
                updateFilters(
                    'locale',
                    e.target.value ?? '',
                    e.target.value === ''
                )
                setLocale(e.target.value)
            }}
            defaultValue={'domestic_locales'}>
            <option value={'all_locales'}>
                All
            </option>
            <option value={'domestic_locales'}>
                Domestic
            </option>
            <option value={'international_locales'}>
                International
            </option>
        </select>
        <label htmlFor='vendorFilter'>Vendor ID</label>
        <select
            className='form-select'
            id='vendorFilter'
            onChange={(e) => {
                updateFilters(
                    'vendor_code',
                    e.target.value ?? '',
                    (e.target.value === '-1' || e.target.value === '')
                )
                setVendorId(e.target.value)
            }}>
            <option value={-1}>
                All Vendor Codes
            </option>
            {vendorCodes.map((vendor: VendorCode) => (
                <option key={vendor.id} value={vendor.id}>
                    {vendor.code}
                </option>
            ))}
        </select>
        {(vendorId == '-1') && <>
        <label htmlFor='billingTypeFilter'>Billing Type</label>
        <select
            className='form-select'
            id='billingTypeFilter'
            onChange={(e) => updateFilters(
                'billing_type',
                e.target.value ?? '',
                (e.target.value === '-1' || e.target.value === '')
            )}>
            <option value={-1}>
                All Billing Types
            </option>
            <option value={'e_billing_suppliers'}>
                E-billing Suppliers
            </option>
            <option value={'docupost_suppliers'}>
                DocuPost Suppliers
            </option>
        </select>
        </>}
        <label htmlFor='minimumPagesFilter'>Minimum Pages</label>
        <input
            className='form-control'
            id='minimumPagesFilter'
            onChange={(e) => {
                updateFilters(
                    'minimum_pages',
                    e.target.value ?? '',
                    e.target.value === ''
                )
                setMinPages(e.target.value)
            }}
            type='number'
            value={minPages}
            max={maxPages}
            min={0} />
        <label htmlFor='maximumPagesFilter'>Maximum Pages</label>
        <input
            className='form-control'
            id='maximumPagesFilter'
            onChange={(e) => {
                updateFilters(
                    'maximum_pages',
                    e.target.value ?? '',
                    e.target.value === ''
                )
                setMaxPages(e.target.value)
            }}
            type='number'
            value={maxPages}
            min={(Number(minPages) >= 0) ? minPages : 0} />
        <label htmlFor='minimumCommissionFilter'>Minimum Commission</label>
        <input
            className='form-control'
            id='minimumCommissionFilter'
            onChange={(e) => {
                updateFilters(
                    'minimum_commission',
                    e.target.value ?? '',
                    e.target.value === ''
                )
                setMinimumCommission(e.target.value)
            }}
            value={minimumCommission}
            type='number'
            min={0} />
        <label htmlFor='maxCommission'>Maximum Commission</label>
        <input
            className='form-control'
            id='maxCommission'
            onChange={(e) => {
                updateFilters(
                    'maximum_commission',
                    e.target.value ?? '',
                    e.target.value === ''
                )
                setMaximumCommission(e.target.value)
            }}
            value={maximumCommission}
            type='number'
            min={(Number(minimumCommission) >= 0) ? minimumCommission : 0} />
        <label htmlFor='excludeInvoicedAfterFilter'>Exclude Records Invoiced After</label>
        <input
            className='form-control'
            id='excludeInvoicedAfterFilter'
            onChange={(e) => {
                updateFilters(
                    'exclude_invoiced_after',
                    e.target.value ?? '',
                    e.target.value === ''
                )
            }}
            type='date' />
        </>
    )
}
