import { defineAbility } from '@casl/ability'

import { User } from '../../models'
import { Roles, userHasRole } from '../../helpers'

export default (user: User) => defineAbility((can) => {
    if (userHasRole(user, Roles.Contributor)) {
        can('View', 'Record')
        can('View', 'UnknownSupplier')
        can('BatchUpdate', 'UnknownSupplier')
    }

    if (userHasRole(user, Roles.Standard)) {
        can('Create', 'Record')
        can('Edit', 'Record')
        can('View', 'PaymentReport')
        can('View', 'ForeignConversionReport')
        can('Delete', 'Record')
    }

    if (userHasRole(user, Roles.Standard)) {
        can('Edit', 'NeedsReviewRecord')
    }

    if (userHasRole(user, Roles.Accounting)) {
        can('Edit', 'PaidRecord')
    }

    if (userHasRole(user, Roles.SrLead)) {
        can('AssignToUser', 'Record')
        can('DownloadCsv', 'UnknownSupplierRecord')
        can('Delete', 'RecordNote')
    }
})
