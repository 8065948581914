import React from 'react'

interface DeleteAgencyEmailProps {
    onDelete: () => void
    email: string
}

export const DeleteAgencyEmail = (props: DeleteAgencyEmailProps) => {
    const { onDelete, email} = props

    return (
        <form onSubmit={(e) => {
            e.preventDefault()

            onDelete()
        }}>
            <input
                className='form-control'
                type='text'
                readOnly={true}
                value={email} />
            <span className='d-flex justify-content-center mt-3'>
                <button
                    className='btn btn-danger'
                    type='submit'>
                    Confirm Delete
                </button>
            </span>
        </form>
    )
}
