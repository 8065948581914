import React, {
    FormEvent,
    useEffect,
    useState
} from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getAuthUser, userHasRole, Roles, isLocalPhone } from '../../helpers'
import {
    createSupplier,
    CreateSupplierParams,
    getChains,
    getCountries,
    getPaymentMethods,
    getStates,
    getSupplierStatuses,
    getVendorCodes
} from '../../api'
import {
    Chain,
    Country,
    JsonChain,
    JsonCountry,
    JsonPaymentMethod,
    JsonSupplierStatus,
    JsonState,
    JsonVendorCode,
    PaymentMethod,
    State,
    SupplierStatus,
    USA,
    VendorCode
} from '../../models'

import { AddressInputUpdates, InsufficientPermissions } from '../common';
import defineAbilityFor from './defineAbility';

export const AddSupplierForm = () => {
    const navigate = useNavigate()
    const ability = defineAbilityFor(getAuthUser())

    const defaultInputs = {
        address: {
            city: '',
            countryId: USA,
            stateId: '',
            street: '',
            street2: '',
            zip: ''
        },
        chain: '',
        email: '',
        fax: '',
        notes: '',
        paymentMethod: '',
        phone_number: '',
        name: '',
        status: SupplierStatus.BILLABLE,
        substatus: '',
        vendorId: ''
    }

    const [inputs, setInputs] = useState<CreateSupplierParams>(defaultInputs)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [chains, setChains] = useState([])
    const [paymentMethods, setPaymentMethods] = useState([])
    const [supplierStatuses, setSupplierStatuses] = useState<SupplierStatus[]>([]);
    const [isValidPhone, setIsValidPhone] = useState(true)
    const [countries, setCountries] = useState<Country[]>([])
    const [country, setCountry] = useState<Country | undefined>()
    const [states, setStates] = useState([])
    const [vendorCodes, setVendorCodes] = useState([])

    const isCollectionsAgent = userHasRole(getAuthUser(), Roles.CollectionAgent);
    const restrictedStatuses = [SupplierStatus.COLLECTIONS, SupplierStatus.SENT_TO_ATTORNEY];

    const visibleStatuses = supplierStatuses ? supplierStatuses.filter(status => {
        if (restrictedStatuses.includes(status.code)) {
            return isCollectionsAgent;
        }
        return true;
    }) : [];

    useEffect(() => {
        getChains().then(json => {
            setChains(json.data.map((chain: JsonChain) => new Chain(chain)))
        })

        getPaymentMethods().then(json => {
            setPaymentMethods(json.data.map(
                (paymentMethod: JsonPaymentMethod) => new PaymentMethod(paymentMethod))
            )
        })

        getSupplierStatuses()
            .then(json => setSupplierStatuses(
                json.data.map((status: JsonSupplierStatus) => new SupplierStatus(status))
            ))

        getCountries().then(json => {
            setCountries(json.data.map(
                (country: JsonCountry) => new Country(country))
            )
        })

        getVendorCodes().then(json => {
            setVendorCodes(json.data.map(
                (vendorCode: JsonVendorCode) => new VendorCode(vendorCode))
            )
        })

        getStates().then(json => {
            setStates(json.data.map(
                (state: JsonState) => new State(state))
            )
        })
    }, [])

    useEffect(() => {
        const countryId = inputs.address.countryId ?? USA

        if (!country) {
            setCountry(countries.find(country => country.id === countryId))
        }
    }, [countries.length])

    const updateInputs = (field: string, value: number | string) => {
        let newInputs = {
            ...inputs,
            [field]: value !== '' ? value : undefined,
        }

        if (field === 'status') {
            const selectedStatus = supplierStatuses.find(s => s.code === Number(value))
            const hasSubstatusesForSelectedStatus = !!selectedStatus?.substatuses?.length

            if (!hasSubstatusesForSelectedStatus) {
                newInputs = { ...newInputs, substatus: undefined }
            }
        }

        setInputs(newInputs)
    }

    const updateAddressInputs = (addressUpdates: Array<AddressInputUpdates>) => {
        let updated = {
            ...inputs.address
        }

        addressUpdates.forEach(update => {
            updated = {
                ...updated,
                [update.field]: update.value
            }
        })

        setInputs({
            ...inputs,
            address: {
                ...updated
            }
        })
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsSubmitting(true)

        createSupplier({...inputs, substatus: Number(inputs.substatus)})
            .then(json => {
                setIsSubmitting(false)
                toast.success('Supplier Created')
                navigate(`/suppliers/${json.data.id}`)
            })
            .catch(error => {
                setIsSubmitting(false)

                const { response } = error

                if (response.data.message) {
                    toast.error(response.data.message, { autoClose: false })
                }

                if (response.status === 400) {
                    toast.error(response.data, { autoClose: false })
                }
            })
    }

    const hasSubstatuses = !!supplierStatuses.find(s => s.code === Number(inputs.status))?.substatuses?.length

    if (!ability.can('Create', 'Supplier')) {
        return <InsufficientPermissions />
    }

    return (
        <div className='container card col-xs-12 col-xl-6'>
            <div className='card-body col-xs-12 row d-flex flex-row justify-content-between mb-5'>
                <div className='row flex-row mb-5'>
                    <h3 className='ps-0 d-inline-flex card-title w-50'>
                        Create New Supplier
                    </h3>
                </div>
                <form className='row mb-4' onSubmit={(e) => handleSubmit(e)}>
                    <div className='row mb-4'>
                        <div className='col-12'>
                            <label htmlFor='statusInput'>Status</label>
                            <select
                                className='form-select'
                                disabled={!ability.can('Edit', 'SupplierStatus')}
                                id='statusInput'
                                onChange={(e) => updateInputs('status', +e.target.value)}
                                value={inputs.status}>
                                {visibleStatuses.map((status: SupplierStatus) => (
                                    <option key={status.code} value={status.code}>
                                        {status.name}
                                    </option>
                                ))}
                            </select>
                            {hasSubstatuses && (
                                <>
                                    <label htmlFor='substatusInput'>Substatus</label>
                                    <select
                                        className='form-select'
                                        id='substatusInput'
                                        onChange={(e) => updateInputs('substatus', +e.target.value)}
                                        value={inputs.substatus}>
                                        <option value=''>None</option>
                                        {supplierStatuses.find(s => s.code === Number(inputs.status))?.substatuses?.map(substatus => (
                                            <option key={substatus.code} value={substatus.code}>
                                                {substatus.name}
                                            </option>
                                        )) || []}
                                    </select>
                                </>
                            )}
                            <label htmlFor='nameInput'>Name</label>
                            <input
                                className='form-control'
                                id='nameInput'
                                onChange={(e) => updateInputs(
                                    'name',
                                    e.target.value
                                )}
                                type='text'
                                required
                                value={inputs.name} />
                            <label htmlFor='dbaInput'>Doing Business As</label>
                            <input
                                className='form-control'
                                id='dbaInput'
                                onChange={(e) => updateInputs('dba', e.target.value)}
                                value={inputs.dba} />
                            <label htmlFor='phoneInput'>Phone</label>
                            <input
                                className='form-control'
                                id='phoneInput'
                                onChange={(e) => {
                                    setIsValidPhone(isLocalPhone(e.target.value))
                                    updateInputs('phone_number', e.target.value)
                                }}
                                type='text'
                                required
                                value={inputs.phone_number} />
                            {!isValidPhone &&
                                <div className='invalid-feedback d-block'>
                                    Enter local phone number
                                </div>
                            }
                            <label htmlFor='streetInput'>Street</label>
                            <input
                                className='form-control'
                                id='streetInput'
                                onChange={(e) => updateAddressInputs([{
                                    field: 'street',
                                    value: e.target.value
                                }])}
                                type='text'
                                value={inputs.address.street} />
                            <label htmlFor='street2Input'>Street 2</label>
                            <input
                                className='form-control'
                                id='street2Input'
                                onChange={(e) => updateAddressInputs([{
                                    field: 'street2',
                                    value: e.target.value
                                }])}
                                type='text'
                                value={inputs.address.street2} />
                            <label htmlFor='faxInput'>City</label>
                            <input
                                className='form-control'
                                id='cityInput'
                                onChange={(e) => updateAddressInputs([{
                                    field: 'city',
                                    value: e.target.value
                                }])}
                                type='text'
                                value={inputs.address.city} />
                            <label htmlFor='zipInput'>Zip</label>
                            <input
                                className='form-control'
                                id='zipInput'
                                onChange={(e) => updateAddressInputs([{
                                    field: 'zip',
                                    value: e.target.value
                                }])}
                                type='text'
                                value={inputs.address.zip} />
                            <label htmlFor='countryInput'>Country</label>
                            <select
                                className='form-select'
                                id='countryInput'
                                onChange={(e) => {
                                    updateAddressInputs([
                                        {
                                            field: 'countryId',
                                            value: e.target.value
                                        },
                                        {
                                            field: 'stateId',
                                            value: ''
                                        }
                                    ])
                                    setCountry(countries.find(
                                        (country: Country) => country.id === +e.target.value)
                                    )
                                }}
                                value={inputs.address.countryId}>
                                {countries.map((country: Country) => (
                                    <option
                                        key={`${country.name}_${country.id}`}
                                        value={country.id}>
                                        {country.name}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor='stateInput'>State</label>
                            <select
                                className='form-select'
                                id='stateInput'
                                onChange={(e) => updateAddressInputs([{
                                    field: 'stateId',
                                    value: e.target.value
                                }])}
                                value={inputs.address.stateId}>
                                <option value=''>
                                    (Empty)
                                </option>
                                {states
                                    .filter((state: State) => state.countryId === country?.id)
                                    .map((state: State) => (
                                        <option
                                            key={`${state.name}_${state.id}`}
                                            value={state.id}>
                                            {state.name}
                                        </option>
                                    )
                                    )}
                            </select>
                            <label htmlFor='chainInput'>Chain</label>
                            <select
                                className='form-select'
                                id='chainInput'
                                onChange={(e) => updateInputs('chain', e.target.value)}
                                value={inputs.chain}>
                                <option value=''>
                                    No Chain
                                </option>
                                {chains.map((chain: Chain) => (
                                    <option
                                        key={`${chain.abbreviation}_${chain.code}`}
                                        value={chain.id}>
                                        {chain.name}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor='emailInput'>Email</label>
                            <input
                                className='form-control'
                                id='emailInput'
                                onChange={(e) => updateInputs(
                                    'email',
                                    e.target.value
                                )}
                                type='text'
                                value={inputs.email} />
                            <label htmlFor='faxInput'>Fax</label>
                            <input
                                className='form-control'
                                id='faxInput'
                                onChange={(e) => updateInputs(
                                    'fax',
                                    e.target.value
                                )}
                                type='text'
                                value={inputs.fax} />
                            <label htmlFor='vendorCodeInput'>Vendor Code</label>
                            <select
                                className='form-select'
                                id='vendorCodeInput'
                                onChange={(e) => updateInputs('vendorId', e.target.value)}
                                value={inputs.vendorId}>
                                <option
                                    key={''}
                                    value={''}>
                                    No Vendor Code
                                </option>
                                {vendorCodes.map((vendorCode: VendorCode) => (
                                    <option
                                        key={vendorCode.id}
                                        value={vendorCode.id}>
                                        {vendorCode.code}
                                    </option>
                                ))}
                            </select>
                            <label htmlFor='paymentMethodInput'>
                                Preferred Payment Method
                            </label>
                            <select
                                className='form-select'
                                id='paymentMethodInput'
                                onChange={(e) => updateInputs('paymentMethod', e.target.value)}
                                value={inputs.paymentMethod}>
                                <option value=''>
                                    Unknown
                                </option>
                                {paymentMethods.map((paymentMethod: PaymentMethod) => (
                                    <option
                                        key={`payment_method_${paymentMethod.id}`}
                                        value={paymentMethod.id}>
                                        {paymentMethod.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='row mb-5'>
                        <div className='col-12 d-flex justify-content-center'>
                            <button
                                className='btn btn-primary'
                                disabled={isSubmitting}
                                type='submit'>
                                Submit
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
