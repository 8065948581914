import React from 'react'
import { Link } from 'react-router-dom'

import { Supplier, SupplierStatus, VendorCode } from '../../models'

interface SupplierTableProps {
    supplier: Supplier
    vendorCode?: VendorCode
}

export const SupplierTable = (props: SupplierTableProps) => {
    const { supplier, vendorCode } = props

    return (
        <table className='table'>
            <tbody>
                <tr>
                    <th>Status</th>
                    <td>{supplier.status.name}</td>
                </tr>
                {supplier.substatus &&
                <tr>
                    <th>Substatus</th>
                    <td>{supplier.substatus.name}</td>
                </tr>
                }
                <tr>
                    <th>Email</th>
                    <td>{supplier.email}</td>
                </tr>
                <tr>
                    <th>Chain</th>
                    <td>
                        {supplier.chain &&
                            <Link to={`/chains/${supplier.chain.id}`}>
                                {supplier.chain.name}
                            </Link>
                        }
                        {!supplier.chain && 'No Chain'}
                    </td>
                </tr>
                <tr>
                    <th>Fax</th>
                    <td>{supplier.fax}</td>
                </tr>
                <tr>
                    <th>Currency</th>
                    <td>{supplier.physicalAddress.country?.currency}</td>
                </tr>
                <tr>
                    <th>Vendor Id</th>
                    <td>{supplier.vendorId}</td>
                </tr>
                <tr>
                    <th>Vendor Code</th>
                    <td>{vendorCode?.code}</td>
                </tr>
                <tr>
                    <th>Preferred Payment Method</th>
                    <td>{supplier?.preferredPaymentMethod?.name ?? 'Unknown'}</td>
                </tr>
                {supplier.status.code === SupplierStatus.TEMP_CLOSED &&
                <tr>
                    <th>Reopen Date</th>
                    <td>{supplier.reopenDate?.format('MM/DD/YYYY')}</td>
                </tr>
                }
            </tbody>
        </table>
    )
}
