import React from 'react'

import { PaginatedTable, PaginatedTableCallbacks, InsufficientPermissions } from '../../common'
import { UnknownSupplierTable } from './table'
import { JsonRecord, Record } from '../../../models'
import { getRecordsWithUnknownSupplier } from '../../../api'
import { getAuthUser } from '../../../helpers';
import defineAbilityFor from '../defineAbility'
import { RecordStatusFilters } from '../filters'
import { AdvancedSearch } from '../advancedSearch'
import { downloadUnknownSupplierCsv } from '../../../api/routes'

const defaultSearchState = {
    rec: '',
    supplierName: '',
    recPhone: '',
    agencyId: ''
}

export const UnknownSupplier = () => {
    const ability = defineAbilityFor(getAuthUser())
    const searchInputs = [
        { id: 'rec', name: 'rec', placeholder: 'Record ID', type: "text"},
        { id: 'supplierName', name: 'supplierName', placeholder: 'Supplier Name', type: "text"},
        { id: 'recPhone', name: 'recPhone', placeholder: 'Supplier Phone', type: "text"},
        { id: 'agencyId', name: 'agencyId', placeholder: 'Agency ID', type: 'text'}
    ]

    if (!ability.can('View', 'UnknownSupplier')) {
        return <InsufficientPermissions />
    }

    return (
        <PaginatedTable<Record, JsonRecord>
            getData={getRecordsWithUnknownSupplier}
            Filters={(props) => (
                <RecordStatusFilters {...props} />
            )}
            DownloadButton={ability.can('DownloadCsv', 'UnknownSupplierRecord')
                ? (() => (
                    <a
                        href={downloadUnknownSupplierCsv}
                        className="btn btn-primary mt-4"
                        download="unknown_supplier_records.csv"
                    >
                        Download CSV
                    </a>
                ))
                : undefined
            }
            AdvancedSearchComponent={(props) => (
                <AdvancedSearch {...props}
                    inputs={searchInputs}
                    defaultState={defaultSearchState} />
            )}
            defaultAdvancedSearchState={defaultSearchState}
            modelConstructor={(json: JsonRecord) => new Record(json)}
            disableSearch={true}
            Table={(records: Record[], callbacks: PaginatedTableCallbacks) => (
                <UnknownSupplierTable
                    callbacks={callbacks}
                    resources={{records}} />
            )}
            title='Records with Unknown Supplier' />
    )
}
