import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import { getVendorCodes } from '../../api'
import { JsonVendorCode, VendorCode } from '../../models'
import { VendorsTable } from './table'
import { InsufficientPermissions } from '../common'
import defineAbility from './defineAbility'
import { getAuthUser } from '../../helpers'

export const VendorsLanding = () => {
    const [vendors, setVendors] = useState<VendorCode[]>([])
    const ability = defineAbility(getAuthUser())

    useEffect(() => {
        getVendorCodes()
            .then(response => {
                if (response.status === 200) {
                    setVendors(response.data.map((vendor: JsonVendorCode) => new VendorCode(vendor)))
                }
            })
    }, [])

    if (!ability.can('View', 'Vendor')) {
        return <InsufficientPermissions />
    }

    return (
        <div className='container card'>
            <div className='card-body'>
                <div className='row mb-5'>
                    <div className="col-xs-12 col-md-12 d-flex align-items-center">
                        <h3 className='ps-0 card-title flex-grow-1'>Vendors</h3>
                        <div className='button-wrapper'>
                            <Link to='/vendors/add' className='ps-0 d-inline-flex justify-content-end'>
                                <button className='btn btn-primary'>
                                    Create Vendor
                                </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <VendorsTable vendors={vendors} />
                </div>
            </div>
        </div>
    )
}
