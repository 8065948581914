import React from 'react'
import { toast } from 'react-toastify'

import {
    createAddress,
    CreateAddressParams,
    SyncAgencyAddressesParams
} from '../../../api'
import { AddressForm } from '../../common'

interface AddAddressProps {
    syncAgencyAddresses: (params: SyncAgencyAddressesParams) => void
}

export const AddAgencyAddress = (props: AddAddressProps) => {
    const { syncAgencyAddresses } = props

    const handleSubmit = (inputs: CreateAddressParams) => {
        createAddress(inputs)
            .then((response) => {
                syncAgencyAddresses({
                    addressToAttach: {
                        addressId: response.data.id
                    }
                })
            })
            .catch(error => {
                if (error?.response?.data?.message) {
                    toast.error(error.response.data.message, { autoClose: false })
                }
            })
    }

    return (
        <AddressForm
            handleSubmit={(inputs) => handleSubmit(inputs)} />
    )
}
