export const isLocalPhone = (phone: string) => {
    const codes = ['800', '877', '888', '866', '1800', '1877', '1888', '1866']

    if (codes.includes(phone.substring(0, 4))
        || codes.includes(phone.substring(0, 3))
    ) {
        return false
    }

    return true
}
