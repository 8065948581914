import React from 'react'
import { toast } from 'react-toastify'

import {
    createAddress,
    CreateAddressParams,
    SyncSupplierAddressesParams
} from '../../../api'
import { AddressForm } from '../../common'

interface AddAddressProps {
    syncSupplierAddresses: (params: SyncSupplierAddressesParams) => void
}

export const AddSupplierAddress = (props: AddAddressProps) => {
    const { syncSupplierAddresses } = props

    const handleSubmit = (inputs: CreateAddressParams) => {
        createAddress(inputs)
            .then((response) => {
                syncSupplierAddresses({
                    addressToAttach: {
                        addressId: response.data.id
                    }
                })
            })
            .catch(error => {
                if (error?.response?.data?.message) {
                    toast.error(error.response.data.message, { autoClose: false })
                }
            })
    }

    return (
        <AddressForm
            handleSubmit={(inputs) => handleSubmit(inputs)} />
    )
}
