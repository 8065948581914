import React from 'react'

import { PaginatedTable, PaginatedTableCallbacks } from '../common'
import { CountriesTable } from './collectionTable'
import { JsonCountry, Country } from '../../models'
import { getCountries } from '../../api'
import { getAuthUser } from '../../helpers';
import defineAbilityFor from './defineAbility'
import { InsufficientPermissions } from '../common';

export const CountriesLanding = () => {
    const ability = defineAbilityFor(getAuthUser())

    if (!ability.can('View', 'Country')) {
        return <InsufficientPermissions />
    }

    return (
        <PaginatedTable<Country, JsonCountry>
            getData={getCountries}
            modelConstructor={(json: JsonCountry) => new Country(json)}
            searchPlaceholder='Search by Name or ISO Code'
            Table={(countries: Country[], callbacks: PaginatedTableCallbacks) => (
                <CountriesTable
                    callbacks={callbacks}
                    resources={{ countries }} />
            )}
            title='Countries' />
    )
}
