import React, { FormEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { getAgencies, uploadAgencyData } from '../../api'
import { InsufficientPermissions, Loading } from '../common'
import { getAuthUser } from '../../helpers'
import { Agency } from '../../models'

import defineAbilityFor from './defineAbility'

export interface Summary {
    agency: string
    duplicateCount: number
    missingGuestNameCount: number
    newRecords: number
    rateTooLowCount: number
    tooOldCount: number
    totalRecords: number
}

export const AgencyDataUpload = () => {
    const [agencies, setAgencies] = useState<Pick<Agency, 'id' | 'name'>[]>([])
    const [agencyId, setAgencyId] = useState<string>('')
    const [file, setFile] = useState<File>()
    const [fileKey, setFileKey] = useState(Date.now())
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [summary, setSummary] = useState<Summary>()

    const ability = defineAbilityFor(getAuthUser())

    if (!ability.can('Upload', 'AgencyData')) {
        return <InsufficientPermissions />
    }

    useEffect(() => {
        const params = {
            active: true,
            fields: [
                'id',
                'AGENCY as name'
            ]
        }
        getAgencies(params).then((response) => setAgencies(response.data))
    }, [])

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsSubmitting(true)

        if (file) {
            const form = new FormData()
            form.append('file', file)

            uploadAgencyData(agencyId, form)
                .then(response => {
                    toast.success('Upload Successful')
                    setIsSubmitting(false)
                    setAgencyId('')
                    setFile(undefined)
                    setFileKey(Date.now())
                    setSummary(response.data.summary)
                })
                .catch(error => {
                    const { response } = error

                    if (response.data.message) {
                        toast.error(response.data.message, { autoClose: false })
                    }
                    setIsSubmitting(false)
                })
        }
    }

    if (!agencies.length) {
        return (
            <div className='container'>
                <Loading />
            </div>
        )
    }

    return (
        <div className='container'>
            <div className='row'>
                <div className='col-6'>
                    <h3>Agency Data Upload</h3>
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <select
                            className='form-select mb-3'
                            onChange={(e) => setAgencyId(e.target.value)}
                            required
                            value={agencyId}>
                        <option value=''>
                            Choose Agency
                        </option>
                        {agencies.map(agency => (
                            <option key={`agency_${agency.id}`} value={agency.id}>
                                {agency.name}
                            </option>
                        ))}
                        </select>
                        <input
                            className='form-control mb-3'
                            key={fileKey}
                            onChange={(e) => {
                                const { files } = e.target

                                if (files) {
                                    setFile(files[0])
                                }
                            }}
                            type='file'
                            required />
                        <button
                            className='btn btn-primary'
                            disabled={isSubmitting}
                            type='submit'>
                            <span className='pe-2'>Upload</span>
                            {isSubmitting && <Loading sm={true} />}
                        </button>
                    </form>
                </div>
            {summary &&
                <div className='col-6'>
                    <table className='table'>
                        <thead>
                            <tr>
                                <th>Upload Summary</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th>Agency</th>
                                <td>{summary.agency}</td>
                            </tr>
                            <tr>
                                <th>Total Records in File</th>
                                <td>{summary.totalRecords}</td>
                            </tr>
                            <tr>
                                <th>New Records Created</th>
                                <td>{summary.newRecords}</td>
                            </tr>
                            <tr>
                                <th>Duplicates</th>
                                <td>{summary.duplicateCount}</td>
                            </tr>
                            <tr>
                                <th>Too Old</th>
                                <td>{summary.tooOldCount}</td>
                            </tr>
                            <tr>
                                <th>Missing Guest Name</th>
                                <td>{summary.missingGuestNameCount}</td>
                            </tr>
                            <tr>
                                <th>Rate Too Low</th>
                                <td>{summary.rateTooLowCount}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
            </div>
        </div>
    )
}
