import React from 'react'
import { useSearchParams } from 'react-router-dom'

import { PaginatedTable, PaginatedTableCallbacks } from '../common'
import { InvoicesTable } from './collectionTable'
import { InvoiceFilters } from './filters'
import { Invoice, JsonInvoice } from '../../models'
import { getInvoices } from '../../api'
import { getAuthUser } from '../../helpers';
import defineAbilityFor from './defineAbility'
import { InsufficientPermissions } from '../common';

export const InvoicesLanding = () => {
    const ability = defineAbilityFor(getAuthUser())
    const [searchParams, setSearchParams] = useSearchParams()

    if (!ability.can('View', 'Invoice')) {
        return <InsufficientPermissions />
    }

    return (
        <PaginatedTable<Invoice, JsonInvoice>
            defaultSearch={searchParams.get('supplierId') || undefined}
            disableLastPageButton={true}
            Filters={InvoiceFilters}
            key={`invoice_table_${searchParams.get('supplierId')}`}
            getData={getInvoices}
            modelConstructor={(json: JsonInvoice) => new Invoice(json)}
            onSearch={() => {
                if (searchParams.has('supplierId')) {
                    const params = new URLSearchParams()

                    searchParams.forEach((value, key) => {
                        if (key !== 'supplierId') {
                            params.set(key, value)
                        }
                    })
                    setSearchParams(params)
                }
            }}
            searchPlaceholder='Search by Invoice ID, Supplier Phone, Record ID, or Total'
            Table={(invoices: Invoice[], callbacks: PaginatedTableCallbacks) => (
                <InvoicesTable
                    callbacks={callbacks}
                    resources={{ invoices }} />
            )}
            title='Invoices' />
    )
}
