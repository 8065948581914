import React, { FormEvent, useState } from 'react'
import { toast } from 'react-toastify'

import { updateAgencyArc } from '../../../api'
import { AgencyArc } from '../../../models'

interface EditArcProps {
    agencyArc: AgencyArc
    existingArcs: AgencyArc[]
    setArcs: (arcs: AgencyArc[]) => void
    setIsEditing: (isEditing: boolean) => void
}

export const EditArc = (props: EditArcProps) => {
    const { agencyArc, existingArcs, setArcs, setIsEditing } = props

    const [arc, setArc] = useState(agencyArc.arc)

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const params = {
            arc
        }

        updateAgencyArc(agencyArc.id, params)
            .then(json => {
                const arc = new AgencyArc(json.data)

                setArcs([
                    ...existingArcs.filter(agencyArc => agencyArc.id !== arc.id),
                    arc
                ])
                setIsEditing(false)
                toast.success('Agency ARC Edited')
            })
            .catch(error => {
                const { response } = error

                if (response.data.message) {
                    toast.error(response.data.message, { autoClose: false })
                }
            })
    }

    return (
        <form className='input-group' onSubmit={(e) => handleSubmit(e)}>
            <input
                className='form-control'
                minLength={8}
                maxLength={8}
                type='text'
                onChange={(e) => setArc(e.target.value)}
                required
                value={arc} />
            <button className='btn btn-primary' type='submit'>
                Submit
            </button>
            <button
                className='btn btn-outline-secondary ms-4'
                onClick={() => setIsEditing(false)}>
                Cancel
            </button>
        </form>
    )
}
