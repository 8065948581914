import { JsonModel, Model } from './model'

export class AgencyStatus extends Model {
    public static ACTIVE = 0
    public static CANCELLED = 2
    public static ACQUIRED = 3
    public static CLOSED = 7
    public static ON_HOLD = 10
    public static LEAD = 12

    code: number
    name: string

    constructor(json: JsonAgencyStatus) {
        super(json)

        this.code = json.code
        this.name = json.name
    }
}

export interface JsonAgencyStatus extends JsonModel {
    code: number
    name: string
}
